@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .cart-checkout-btn {
    /* font-size: 10px; */
  }

  .customer-cart-pagetable-inner-container {
    max-height: 700px;
  }

  .subtotal-section {
    width: 100%;
  }

  .quantity_input_add_mobile  > .ant-select-selector {
    border: 1px solid #529d4b !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    height:  30px !important;
    width:100px !important;
  }
}
