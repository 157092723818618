@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .trending-products-header {
    width: 98%;
    /* margin-top: 20px; */
    padding-left: 15px;
  }

  .trending-products-list {
    grid-template-columns: repeat(auto-fill, minmax(16vw, 1fr)) !important;
  }

  .shop-now-img {
    width: 350px !important;
    height: 160px !important;
  }
  .home-category-main-container,
  .shop-now-card {
    justify-content: space-around !important;
    padding-left: 9px;
    padding-right: 9px;
    margin: 20px 0;
  }
  .shop-by-category-carousal .slick-active {
    width: 230px !important;
    padding-left: 10px;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% +10px) !important;
  }
}
