@media only screen and (min-width: 2560px) {
  .carousel-subtext {
    font-size: 32px;
  }
  .carousel-button {
    font-size: 34px;
    padding: 16px 20px;
    top: 83%;
    border-radius: 31px;
  }

  .carousel-sub-field-header-container {
    padding: 21px;
    padding-left: 85px;
  }

  .shop-now-icon {
    font-size: 25px;
  }

  .carousel-sub-field {
    width: 400px;
  }
  .sub-field-logo {
    height: 76px;
  }

  .sub-field-title {
    font-size: 27px;
  }

  .sub-field-subtitle {
    font-size: 20px;
  }

  .shop-now-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .shop-now-img {
    width: 700px !important;
    height: 300px !important;
  }

  .trending-products-container {
    background-color: white;
  }

  .trending-products-header {
    padding: 10px 190px;
  }

  .trending-products-list {
    padding: 0px 90px;
  }
}
