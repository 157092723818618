.warehouse-stock {
  color: white;
  background-color: brown;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
}
.customer-demand-value {
  color: white;
  background-color: #194e08;
  border-radius: 50%;
  min-width: 20px;
  width: auto;
  height: 20px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  padding-top: 1px;
  font-weight: bold;
}

.product-demand-placed-qty {
  color: white;
  background-color: #7ab2d3;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}
.product-demand-estimate-qty {
  color: white;
  background-color: #686d76;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.product-request-transfer-icon {
  color: white;
  background-color: teal;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.product-demand-remaining-qty {
  color: white;
  background-color: #f95454;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}

.product-demand-unusable-qty {
  color: white;
  background-color: #3e5879;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}
.product-demand-opening-stock {
  color: white;
  background-color: green;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}
