@media only screen and (min-width: 0px) and (max-width: 767px) {
  .search-bar,
  .icon-user,
  .icon-item-first {
    display: none !important;
  }
  .icon-users {
    display: "none";
  }
  .logo-container > img {
    width: 110px;
    object-fit: contain;
  }
  .icon-item {
    width: 25px;
    margin-right: 0px;
    /* height: 15px; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: flex-end;
  }
  .icon-wishlist {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    font-size: 27px !important;
  }

  .mobile-view-search-box-container {
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
  }

  .mobile-view-search-input-container {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 98% !important;
    padding-left: 4px !important;
  }

  .mobile-view-search-results-value-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    top: 20;
    left: 0;
    transition: transform 0.3s ease-in-out;
  }
  .mobile-view-search-input-box {
    width: 100%;
  }

  .mobile-view-search-btn {
    background-color: #194e08;
    color: white;
  }

  /* .ant-btn
    .css-dev-only-do-not-override-1pg9a38
    .ant-btn-default
    .mobile-view-search-btn:active {
    background-color: #194e08 !important;
    color: white !important;
  } */

  .avatar-header {
    display: none;
  }
  .search-menu-icon {
    display: block;
  }
  .search-icon {
    display: block;
    cursor: pointer;
  }
  .search-icon,
  .wishlist-icon,
  .cart-icon {
    font-size: 27px;
  }
  .badge {
    font-size: 12px;
    /* padding: 2px 2px; */
    top: -3px;
    right: 0px;
  }
  .mobile-search-mani-container .search-bar {
    display: block;
  }
  .logo_with_description_container {
    padding: 5px;
    width: 80% !important;
  }
  .learn_more_container {
    flex: 1;
    padding: 5px;
  }
  .footer_container {
    display: flex !important;
    flex-direction: column !important;
  }
  .links_container {
    flex: 1;
    padding: 5px;
  }
  .get_in_touch_container {
    width: 90%;
    padding: 5px;
  }
  .sub_header_container {
    display: none !important;
  }

  .user-icon {
    width: 24px;
    height: 24px;
    background-color: #194e08;
    display: none;
  }
  .modal-header {
    font-size: 14px !important;
  }
  .paragraph_terms {
    font-size: 12px;
  }
  .links_list,
  .learn_more_list {
    padding: 5px;
    margin: 0px;
  }
  .link_and_learn_more_conatiner {
    display: flex;
    width: 100%;
  }
  .copy-right-container {
    flex-direction: column;
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-bottom: 80px;
  }

  .mobile-view-search-results-value-container.hide {
    transform: translateY(-200px) !important;
  }

  .mobile-view-search-results-value-container.show {
    transform: translateY(0);
  }
  .home-search-box {
    border-radius: 10px !important;
  }
  .home-search-box > .ant-select-selector {
    border-radius: 10px !important;
  }
  .dropdown-list {
    width: 100% !important;
    z-index: 100000 !important;
  }

  .dropdown-header {
    border-radius: 50px !important;
    font-size: 12px;
  }
  .shop-now-button-desktop {
    border-radius: 50px;
  }
}
