@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .sidebar {
    /* top: 180px;
    margin-top: 18px; */
    /* position: sticky !important; */
  }

  .custom-due-amount-box-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 22px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 10px;
    /* margin-right: 45px; */
    display: none;
  }
  .sidebar-due-amount-container {
    display: none;
  }
}
