@media (min-width: 1440px) and (max-width: 1999px) {
  .heading {
    font-size: 32px;
    margin-bottom: 16px;
  }
  
  .description {
    font-size: 20px;
    margin-bottom: 22px;
  }
  
  .read-button {
    font-size: 18px;
    padding: 14px 28px;
  }
}