@media only screen and (min-width: 2560px) {
    .login-form-container{
        padding: 25px !important;
        max-width: 700px !important;
        height: 40vh;
    }
    .login-form-container{
        font-size: 24px !important;
        height: 50vh !important;
    }
    .login-message{
        font-size: 24px !important;
    }
    .form-group label{
        font-size: 24px !important;
    }
    .forgot-password a{
        font-size: 24px !important;
    }
    .form-group .ant-input, .form-group .ant-input-password{
        height: 4.5vh;
        font-size: 20px !important;
    }
    .login-button{
        font-size: 24px !important;
    }
    .toggle-button{
        font-size: 25px !important;
        line-height: 55px !important;
    }
    .toggle-container{
        width: 330px !important;
        height: 55px !important;
    }
    .register-button{
        font-size: 24px !important;
    }
    .register-form-container{
        max-width: 700px !important;
        width: 650px !important;
    }
    .already-have-account{
        font-size: 20px !important
    }
}
