@media only screen and (min-width: 2560px) {
  .app-routes-heading {
    color: gray;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 30px;
    font-size: 33px;
  }

  .order-list-heading {
    font-weight: bolder;
    font-family: "Montserrat-semibold";
    font-size: 38px;
  }

  .sidebar-logo {
    position: relative;
    font-size: 36px;
    font-weight: bold;
    /* margin-bottom: 0px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    font-family: "Montserrat-semibold";
  }

  .sidebar-logo .upload-icon-wrapper {
    width: 46px;
    height: 42px;
  }

  .sidebar-logo .upload-icon {
    color: white;
    font-size: 26px;
    font-family: "Montserrat-semibold";
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  .sidebar-item {
    padding: 15px 20px;
    cursor: pointer;
    text-align: left;
    transition: background 0.3s;
    display: flex;
    align-items: center;
  }

  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 16px;
    width: auto;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ant-menu-vertical {
    border-inline-end: 0px solid rgba(0, 0, 0, 0) !important;
    /* Remove right border */
  }

  .sidebar-item:hover {
    background-color: #e0f7fa;
  }

  .sidebar-item.selected {
    color: #4caf50 !important;
  }

  .sidebar-item .anticon {
    margin-right: 10px;
  }

  .ant-menu-item-selected {
    color: #4caf50 !important;
  }

  .product-list-routes {
    color: #4caf50 !important;
  }

  .app-routes-heading {
    color: gray;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 30px;
    font-family: "Montserrat-semibold";
  }

  .sidebar-menu-icon {
    font-size: 29px !important;
  }

  .custom-due-amount-box-text {
    color: red;
    font-size: 25px;
    font-weight: bolder;
    font-family: "Montserrat-semibold";
  }
  .custom-due-amount-box-text {
    color: red;
    font-size: 25px;
    font-weight: bolder;
  }

  .ant-menu-item {
    font-family: "Montserrat-semibold";
    font-size: 28px;
  }
  .custom-due-amount-box-container {
    display: flex;
    justify-content: end;
    width: auto;
    margin-bottom: 40px;
  }

  .custom-due-amount-box-sm {
    display: none;
  }

  .order-panel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Montserrat-semibold";
    font-size: 28px;
  }

  .order-list-container {
    padding: 20px;
    /* max-width: 1200px;
         */
    width: auto;
    margin: auto;
    background-color: white;
    font-family: "Montserrat-semibold";
  }

  .order-list-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 30px;
  }

  .order-list-header h2 {
    margin: 0;
  }

  .order-list-header .ant-badge {
    padding: 10px 20px;
    font-size: 16px;
    font-family: "Montserrat-semibold";
  }

  .order-panel-header div {
    flex: 1;
    padding: 0 10px;
  }

  .order-list-item {
    padding: 10px 0;
  }

  .order-list-item-meta-title {
    font-weight: 600;
    font-family: "Montserrat-semibold";
  }

  /* Custom Styles for the Search Bar */

  .search-bar-container {
    display: flex;
    justify-content: end;
  }
  .custom-search-bar {
    width: 400px;
    display: flex;
    justify-content: flex-end;
  }

  .order-list-heading {
    font-weight: bolder;
    font-family: "Montserrat-semibold";
  }

  .ant-collapse-header {
    background-color: rgb(233, 233, 233) !important;
  }

  /* My Order List Collapse */
  .order-panel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Montserrat-semibold";

    font-size: 29px;
  }

  .shipping-address {
    margin-bottom: 20px;
    font-family: "Montserrat-semibold";

    font-size: 26px;

    width: 80%;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .shipping-address p {
    margin: 0;
  }

  .product-details {
    margin-bottom: 20px;
  }

  .product-details strong {
    display: block;
    margin-bottom: 10px;
  }

  .order-list-item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-list-item-meta-title {
    font-weight: bold;
    font-size: 25px;
  }

  .item-price {
    font-weight: bold;
    font-size: 25px;
    font-family: "Montserrat-semibold";
  }

  .order-summary {
    margin-top: 20px;
    font-size: 25px;
    font-weight: bold;
  }

  .order-summary div {
    margin-bottom: 5px;
  }
  .order-listing-heading {
    color: #4caf50;
    font-size: 30px;
    /* font-weight: bold; */
    font-family: "Montserrat-semibold";
  }
  .order-paid-heading {
    font-size: 30px;
  }
  .order-unpaid-heading {
    font-size: 30px;
  }

  .order-shipped-name {
    margin-top: 30px;
  }

  .order-summary-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .order-list-search-bar {
    width: 1000px;
    height: 300px;
  }

  .custom-due-amount-box-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 22px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 10px;
    /* margin-right: 45px; */
    display: none;
  }
  .sidebar-due-amount-container {
    display: none;
  }

  .order-return-btn {
    background-color: #4caf50;
    /* font-weight: bold; */
    color: white;
    font-family: "Montserrat-semibold";
    font-size: 22px;
    width: 118px;
    height: 59px;
  }
  .order-cancel-btn {
    font-family: "Montserrat-semibold";
    font-size: 22px;
    width: 118px;
    height: 59px;
  }

  .order-shipping-container {
    display: flex;
    flex-direction: row !important;
  }
  .mobile_check_box {
    display: none !important;
  }
  .return-order-product-mobile {
    display: none !important;
  }
}
