@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .product-info-img-text-container {
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .customer-cart-pagetable-inner-container {
    width: 93%;
    max-height: none;
  }

  .subtotal-section {
    width: 98%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* padding: 8px; */
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: auto;
    min-height: 350px;
    margin-bottom: 5px;
  }
  .footer_container {
    margin-top: 5px !important;
  }
  .coupon-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 220px;
    margin-top: 50px;
    margin-left: 430px;
  }

  .cart-checkout-btn {
    font-size: 15px;
    width: 45% !important;
  }

  .coupon-section .ant-input {
    font-size: 12px;
  }

  .coupon-section > .apply-btn {
    font-size: 13px;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 29% !important;
  }

  .customer-cart-page-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96%;
  }

  .customer-cart-page-sub-section-container {
    width: 89%;
    align-items: center;
    margin-top: 40px;
    flex-direction: column-reverse;
    gap: 50px;
  }

  .quantity-controls {
    padding: 0px;
  }
  .quantity_input_add_mobile > .ant-select-selector {
    border: 1px solid #529d4b !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    height: 30px !important;
    width: 70px !important;
  }
}
