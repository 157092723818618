@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .carousel-subtext {
    display: none;
  }

  .carousel-button {
    font-size: 0.5rem;
    padding: 5px 7px;
    left: 13%;
    top: 68%;
  }

  .shop-now-icon {
    font-size: 0.3rem;
  }

  .carousel-sub-field-header-container {
    gap: 11px;
  }

  .carousel-sub-field-card {
    /* width: 50%; */
    align-items: center;
    justify-content: center;
  }

  .carousel-sub-field {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sub-field-desc-container {
    text-align: center;
  }

  .sub-field-logo {
    height: 35px;
  }

  .sub-field-title {
    font-size: 13px;
  }

  .sub-field-subtitle {
    color: rgb(5, 133, 5);
  }

  .shop-now-card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .shop-now-img {
    width: 240px !important  ;
    height: 150px !important;
  }

  .category-buttons {
    display: none;
  }
  .category-dropdown {
    display: block;
    color: white;
  }

  .category-dropdown select {
    background-color: rgb(5, 133, 5);
    color: white;
    border: none;
  }

  .trending-products-header {
    padding: 0px 15px;
    margin-top: 20px;
  }

  /* .trending-products-list {
    padding: 0px 15px;
  } */

  .why-myharvest-title {
    font-size: 14px;
  }

  .why-myharvest-desc {
    font-size: 13px;
  }

  .why-myharvest-container {
    gap: 30px;
    padding-bottom: 25px;
  }

  .why-myharvest-img {
    height: 60% !important;
    width: 60% !important;
  }
  .category-skeleton-item-loading {
    width: 25vh !important;
    height: 15vh !important;
  }
}
