.sub_category_filter_container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}
.icon_style {
  width: "20px";
  height: "20px";
  cursor: "pointer";
  margin-top: "10px";
}
