@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .category-list-mobile-container {
    display: none;
  }
  .product-list-body-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    justify-items: center;
    width: 100% !important;
  }

  .product-card {
    width: 90% !important;
    padding: 5px;
  }

  .product-card img {
    width: 100%;
    /* height: 100px; */
    object-fit: cover;
  }

  .product-card .category {
    font-size: 0.6em;
  }

  .product-card .title {
    font-size: 0.8em;
    margin: 8px 0;
    height: 30px;
  }

  .product-card .price {
    font-size: 0.5em;
    font-weight: bold;
  }

  .product-card .delivery {
    margin-top: 8px;
    font-size: 0.9em;
  }

  .product-card .quantity-control,
  .quantity-control {
    gap: 10px;
  }

  .product-card .quantity-control button,
  .quantity-control button {
    padding: 4px 8px;
    /* margin: 0 5px; */
  }

  .product-card .add-to-cart {
    margin-top: 3px;
    font-size: 10px;
  }

  .product-card .add-to-cart button {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .quantity-control-container {
    /* margin-top: 12px;
    min-height: 22px; */
  }

  .product-card-action-button {
    width: 25px;
  }
  .product-card-cart-action-button {
    font-size: 1em;
  }

  /* .product-skeleton-card {
    width: 150px !important;
  } */
  .product-skeleton-card .ant-card-body {
    padding: 10px !important;
  }
  /* .product-skeleton-card-button {
    width: 120px !important;
    height: 32px !important;
  } */

  /* .product-skeleton-card-image {
    width: 120px !important;
    height: 190px !important;
    margin-bottom: 10px;
  } */

  .add-to-cart > text {
    font-size: 8px;
  }
  .cart-button {
    /* font-size: 10px;
    height: 20px;
    padding: 0px; */
  }
  .products-title > h2 {
    padding-top: 5px;
    font-size: 15px;
  }
  .products-title {
    width: 50%;
  }
  .product-list-container {
    flex-direction: column;
  }
  .quantity-control-button {
    width: 100px !important;
    height: 20px !important;
  }
  .category-list-container {
    display: none !important;
  }
  .category-list-container-product {
    display: none !important;
  }
  .product-list-category-main-container {
    width: 100% !important;
  }
  .category-title-txt {
    display: none !important;
  }
  .testimonial-content {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    height: 400px !important;
  }
  .testimonial-left {
    width: 100%;
  }
  .testimonial-right {
    width: 100%;
  }
  .testimonial-card {
    padding: 20px;
    min-height: 270px !important;
  }
  .share_social_media_icons {
    width: 25px !important;
  }
}
