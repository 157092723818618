.customer-category-container {
  padding: 16px;
}
@media only screen and (min-width: 0px) and (max-width: 320px) {
  .customer-category-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
.customer-category-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

.customer-category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.skeleton-customer-category-card {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 3s infinite linear;
  border-radius: 8px;
  width: 100% !important;
  height: 150px;
}

.customer-category-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.customer-category-name {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}
