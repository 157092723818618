.cluster-details-card {
    position: fixed;
  }
  .cluster-card{
    padding: 0 16px 16px 16px;
    border-radius: 8px;
    width: 120%;
    margin-right: 10px;
    right: 72px;
  }
  .cluster-card :where(.css-dev-only-do-not-override-1pg9a38).ant-skeleton .ant-skeleton-content {
    width: 400px !important;
  }
  .cluster-info-container{
    margin-top: 10px;
  }
  .cluster-details-item {
    margin-bottom: 10px;
    font-size: 13.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .delivered_status{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .out_of_delivery_status{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 45px;
  }
  .default_status{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .cluster-details-label {
    font-weight: bold;
    margin-right: 5px;
    width: 20%;
    display: flex;
  }
  .cluster-details-tracking {
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: flex-start;
    margin-top: 25px;
   
    
  }
 .cluster-card-content{
    width: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
 }
 .ant-card .ant-card-body{
padding: 3px;
 }

 .timeline-orders-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 60%; /* Ensure full width */
    background-color: yellow !important;
  }
  
  .timeline-orders {
    width: 100%;
    min-height: 400px;
    max-height: auto;
  }
  .timeline-orders-out {
    width: 100%;
  }
  
  .timeline-orders-label-card {
    margin-bottom: 24px; /* Gap between each label card */
    padding: 6px;
    width: 115%;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    height: 160px;
    background-color: white; 
}

  
  .timeline-orders-children {
    font-weight: bold;
    margin-left: 4px;
    font-size: 16px;
    color: black;
  }
  
  .timeline-orders-dot {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeline-orders-dot-green {
    color: green;
  }
  
  .timeline-orders-dot-orange {
    color: orange;
  }
  
  .timeline-orders-dot-red {
    color: red;
  }
  
  .timeline-refresh-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .timeline-refresh-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .timeline-refresh-button:hover {
    color: #1890ff; /* Ant Design primary color */
  }
  
  .ant-timeline-item-content{
    text-align: start !important;
  }

  :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-label .ant-timeline-item-label{
    inset-block-start: -6px !important;
    width: calc(50% - 12px) !important;
    text-align: end !important;
    position: relative;
  }
  .timeline-orders>.ant-timeline-item>.ant-timeline-item-head{
    margin-left:40px !important;
    background: transparent !important;
  }
  .timeline-orders-out>.ant-timeline-item>.ant-timeline-item-head{
    margin-left:64px !important;
    background: transparent !important;

  }

  .cluster-card-driver-details {
    display: flex;
    align-items: center;
    margin-bottom: 16px; 
    justify-content: space-between;
  }
  
  .cluster-card-driver-avatar {
    margin-right: 5px;
  }
  
  .cluster-card-driver-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 18px;
  }
  
  .cluster-card-driver-details-name,
  .cluster-card-driver-details-vehicle,
  .cluster-card-driver-details-mobile {
    margin-bottom: 8px; /* Space between each detail */
  }

  .cluster-statistic-container {
    margin-top: 16px;
  }
  
  .cluster-statistic-card {
    box-shadow: none;
    background: #f0f0f0;
    padding: 12px;
  }
  
  .cluster-statistic-container .ant-statistic-title {
    font-weight: bold;
  }
  
  .cluster-statistic-container .ant-statistic-content {
    font-size: 24px;
  }
  
  .tracking-title-container{
    padding: 0px !important;
  }

  .back-button-cluster {
    margin-top: 20px;
    background-color: #ff4d4f; /* Red color */
    border: none;
    color: white;
    display: flex;
    justify-content: end !important;
  }
  .back_button_cluster{
    display: flex;
    justify-content: end;
  }
  .back-button-cluster:hover{
    background-color: #ff4d4f;
    color: white;
  }
  .cluster-card-container {
    /* margin: 20px auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-alternate .ant-timeline-item-tail, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-right .ant-timeline-item-tail, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-label .ant-timeline-item-tail, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-alternate .ant-timeline-item-head, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-right .ant-timeline-item-head, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-label .ant-timeline-item-head, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-right .ant-timeline-item-head-custom, :where(.css-dev-only-do-not-override-1pg9a38).ant-timeline.ant-timeline-label .ant-timeline-item-head-custom{
    inset-inline-start: 58% ;
  }