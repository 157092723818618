@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .my-profile .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .my-profile-edit-btn-container {
    text-align: center;
  }

  .my-profile .ant-form-item {
    margin-bottom: 20px;
  }

  .my-profile .ant-row {
    margin-bottom: 0;
  }
  .my-profile {
    width: 94%;
  }
  .profile-breadcrumb {
    margin-bottom: 15px;
    /* padding-left: 15px; */
  }
}
