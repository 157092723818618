.custom-form .custom-form-item {
    display: flex;
    align-items: center;
  }
  
  .ant-form-item-label {
    text-align: left !important;
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item-required::after {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*" !important;
    visibility: visible !important;
}
  


  
  .custom-form .custom-form-item .ant-form-item-control {
    flex: 2;
  }
  .search-box{
    display: flex;
  }