@media (min-width: 1024px) and (max-width: 1439px) {
  .heading {
    font-size: 28px;
    margin-bottom: 12px;
  }
  
  .description {
    font-size: 18px;
    margin-bottom: 18px;
  }
  
  .read-button {
    font-size: 16px;
    padding: 12px 24px;
  }
}