@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .return-product-name {
    font-size: 18px !important;
  }
  .mobile_check_box{
    display: none !important;
}
.return-order-product-mobile{
  display: none !important;

}
}
