/* Custom Styles for the Order List */
.order-list-container {
  padding: 20px;
  /* max-width: 1200px;
     */
  width: auto;
  margin: auto;
  /* background-color: white; */
  font-family: "Montserrat-semibold";
}

.order-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: row;
  gap: 30px;
}

.order-list-header h2 {
  margin: 0;
  font-family: "Montserrat-semibold";
  font-size: 16px;
  margin-bottom: 30px !important;
}

.order-list-header .ant-badge {
  padding: 10px 20px;
  font-size: 16px;
  font-family: "Montserrat-semibold";
}

.order-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background-color: grey; */
}

.order-panel-header div {
  flex: 1;
  padding: 0 10px;
}

.order-list-item {
  padding: 10px 0;
}

.order-list-item-meta-title {
  font-weight: 600;
  font-family: "Montserrat-semibold";
}

/* Custom Styles for the Search Bar */

.search-bar-container {
  display: flex;
  justify-content: end;
}
.custom-search-bar {
  width: 400px;
  display: flex;
  justify-content: flex-end;
}

.order-list-heading {
  font-weight: bolder;
  font-family: "Montserrat-semibold";
}

.ant-collapse-header {
  background-color: rgb(233, 233, 233) !important;
}

/* My Order List Collapse */
.order-panel-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Montserrat-semibold";

  font-size: 16px;
}

.shipping-address {
  margin-bottom: 20px;
  font-family: "Montserrat-semibold";
  font-size: 17px;
  width: 60%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.shipping-address p {
  margin: 0;
}

.product-details {
  margin-bottom: 20px;
}

.product-details strong {
  display: block;
  margin-bottom: 10px;
}

.order-list-item {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-list-item-meta-title {
  font-weight: bold;
}

.item-price {
  font-weight: bold;
  font-size: 16px;
  font-family: "Montserrat-semibold";
}

.order-summary {
  margin-top: 20px;
  font-weight: bold;
}

.order-summary div {
  margin-bottom: 5px;
}
.order-listing-heading {
  color: #4caf50;
  font-size: 17px;
  /* font-weight: bold; */
  font-family: "Montserrat-semibold";
}

.order-paid-heading {
  color: #4caf50;
  font-size: 17px;
  /* font-weight: bold; */
  font-family: "Montserrat-semibold";
}
.order-unpaid-heading {
  color: #cb0707;
  font-size: 17px;
  /* font-weight: bold; */
  font-family: "Montserrat-semibold";
}

.order-shipped-name {
  margin-top: 30px;
}

.order-summary-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-list-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
}
.order-return-btn {
  background-color: #4caf50;
  /* font-weight: bold; */
  color: white;
  font-family: Montserrat-semibold;
}
.order-cancel-btn {
  background-color: #d70909c3 !important;
  /* font-weight: bold; */
  color: white;
  font-family: Montserrat-semibold;
}
.order_close_button {
  color: rgb(0, 0, 0);
  font-family: Montserrat-semibold;
}
.reason_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px;
  justify-content: center;
}
.order-cancel-btn:hover {
  color: rgb(0, 0, 0);
  font-family: Montserrat-semibold;
}
.cancel_reason_text {
  font-family: Montserrat-semibold;
}
.order-return-btn:hover {
  background-color: #4caf50 !important;
  /* font-weight: bold; */
  color: white !important;
  border: none !important;
}

.order-cancel-btn :hover {
  /* font-weight: bold; */
  color: white !important;
  font-family: Montserrat-semibold;
}
.sidebar-user-name {
  font-family: Montserrat-semibold;
  font-weight: bold;
}
.sidebar-user-label {
  color: rgb(150, 150, 150);
}

.order_list_loading_container {
  display: flex;
  justify-content: space-between;
  background-color: rgb(233 233 233);
  padding: 30px;
  width: auto;
}

.no-orders-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  /* gap: 20px; */
}

.no-orders-img {
  height: 300px;
  width: 300px;
  object-fit: contain;
}
.customer-order-list > .ant-collapse-item-active > .ant-collapse-header {
  background-color: green !important;
  color: white !important;
}
.order-variant-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.order-shipping-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.custom-modal .ant-modal-header {
  background-color: #28b463;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-modal .ant-modal-title {
  color: white;
}
.cancel-order-container-remarks {
  padding: 15px;
}
.customer_order_ordered_on_container,
.customer_delivery_on_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: Montserrat;
  font-size: 13px;
}
.customer_delivery_on_container {
  padding-bottom: 10px;
}

.customer_order_date_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.customer_return_order_date_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(214, 214, 214);
}
.customer_return_delivery_on_container {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
}
.return-order-list-item {
  border: none !important;
}
.customer_order_ordered_on_container {
  visibility: hidden;
}
.custom-collapse .ant-collapse-header {
  background-color: green !important;
  color: white !important;
  font-weight: bold;
}

.custom-collapse .ant-collapse-header:hover {
  color: white !important;
}
.order-popup {
  background: white;
  padding: 8px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.map-container {
  width: 100%;
  height: calc(70vh - 150px);
  /* height: 90vh !important; */
  /* height: 100%; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
