.faq-container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
  font-family:"Mulish", sans-serif;
  line-height: 30px !important;
}

.faq-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.faq-section {
  margin-bottom: 30px;
}

.faq-heading {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
}

.faq-answer {
  color: #555;
  margin-top: 10px;

}

.faq-list {
  padding-left: 20px;
  list-style-type: disc;
  color: #555;

}
.faq-list > li {
  margin-bottom: 20px;
  line-height: 30px !important;
}

.faq-link {
  color: blue;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .faq-title {
    font-size: 1.8rem;
  }

  .faq-heading {
    font-size: 1.3rem;
  }

  .faq-question {
    font-size: 1.1rem;
  }
}
.email-link {
  color: #8bc86f;
  text-decoration: none;
  font-weight: bold;
}

.email-link:hover {
  color: #8bc86f;
  text-decoration: underline;
}
