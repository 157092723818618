@media (min-width: 2560px) {
  .heading {
    font-size: 46px;
    margin-bottom: 24px;
  }
  
  .description {
    font-size: 28px;
    margin-bottom: 32px;
  }
  
  .read-button {
    font-size: 22px;
    padding: 18px 36px;
  }
}