@media only screen and (min-width: 0px) and (max-width: 767px) {
  .carousel-container {
    width: 100%;
  }

  .carousel-subtext {
    display: none;
  }

  .carousel-button {
    top: 68%;
    left: 16.4%;
    font-size: 7px;
    padding: 3px 5px;
  }
  .product_image_main_container {
    margin-top: 15px !important;
    overflow: hidden;
  }
  .header-top-menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
  }
  .thumbnail_container {
    justify-content: flex-start;
    gap: 20px;
    margin-left: 15px !important;
  }

  .mobile-menu-container {
    display: flex;
    align-items: center;
  }

  .logo-container img {
    height: 40px;
    cursor: pointer;
  }

  .mobile-view-delivery-address-header {
    background-color: #f4f2f2;
    padding: 5px;
    border: 1px solid #d9d9d9;
    z-index: 100 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: sticky !important;
    top: 0;
  }

  .mobile-delivery-address-desc {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    gap: 5px;
  }

  .mobile-delivery-address-desc .text {
    margin-left: 5px;
  }

  .mobile-delivery-address-desc .arrow {
    margin-left: 5px;
    font-size: 12px;
    color: #888;
  }

  .shop-now-icon {
    font-size: 4px;
  }

  .carousel-sub-field-header-container {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    padding: 0px;
    padding-bottom: 5px;
    /* margin-bottom: 9px; */
  }

  .carousel-sub-field {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .carousel-sub-field-card {
    flex-direction: row;
    flex-wrap: wrap;
    width: 40%;
  }

  .sub-field-desc-container {
    text-align: center;
    text-wrap: wrap;
  }

  .sub-field-logo {
    height: 24px;
  }

  .sub-field-title {
    font-size: 10px;
  }

  .sub-field-subtitle {
    /* width: 120%; */
    font-size: 7px;
    color: rgb(5, 133, 5);
  }

  .show-by-category-item {
    width: 100px !important;
    margin-bottom: 5px !important;
  }

  .category-image-container {
    width: 50px;
    height: 50px;
    object-fit: cover;
    padding: 10px;
  }

  .category-skeleton-item-loading {
    width: 100px !important;
    height: 70px !important;
    margin: 10px !important;
  }
  .category-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .show-by-category-name {
    font-size: 10px;
    margin-top: 10px;
  }

  .shop-now-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;
  }
  .shop-now-card > div {
    width: 45%;
  }
  .shop-now-card > .shop-now-container {
    width: 45% !important;
  }
  .shop-now-img {
    width: 100% !important;
    height: 95px !important;
  }
  .category-filters {
    z-index: 100;
  }

  .category-buttons {
    display: none;
  }
  .category-dropdown {
    display: block;
    background-color: rgb(218 247 218);
    color: white;
    border-radius: 20px;
    z-index: 100001;
  }

  .category-dropdown select {
    background-color: rgb(218 247 218);
    color: white;
    border: none;
  }
  .trending-products-heading {
    font-size: 15px;
    margin: 20px;
  }

  .trending-products-header {
    padding: 0px 10px;
  }

  .trending-products-list {
    padding: 0px 5px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
  }
  .why-myharvest-img {
    width: 90% !important;
    height: 90% !important;
  }

  .why-myharvest-title {
    font-size: 15px;
  }

  .why-myharvest-desc {
    font-size: 12px;
    width: 80%;
  }

  .why-myharvest-desc {
    font-size: 10px;
    width: 60%;
  }

  .why-myharvest-container {
    gap: 20px;
  }

  .why-myharvest-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .icon-item-second {
    display: block;
  }
  .trending-products-container {
    padding: 5px !important;
  }
  .customer-layout-profile-footer-container {
    display: flex;
  }

  /* .category-subcategory-image-container {
    margin-left: 10px;
  } */
  .custom-arrow {
    display: none !important;
  }
  .category-subcategory-container {
    top: 160px !important;
  }

  .skeleton-subcategory-loader-category {
    width: 130px !important;
    height: 40px !important;
  }
  .breadcrumb_content > .breadcrumb_link,
  .bread-crumb > ol > li > span {
    font-size: 13px !important;
  }
  .ant-breadcrumb-separator {
    font-size: 14px !important;
  }
  .ownchat-bcon-container,
  .ownchat-bcon-container-body {
    bottom: 10px !important;
  }
  .ownchat-bcon-container-body {
    bottom: 80px !important;
  }
  .ownchat-bcon-container button {
    padding: 10px !important;
  }
  /* .ownchat-bcon-container > span > svg {
    font-size: 20px !important;
  } */
  .notification_text {
    width: 20px !important;
    height: 20px !important;
    top: -20px !important;
  }
}
