.product-name {
  padding-left: 10px;
  margin-bottom: 10px !important;
}
.product-demand-footer,
.stock-update-container {
  width: 100%;
  background-color: whitesmoke;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  gap: 10px;
}

.stock-update-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  position: sticky !important;
  top: 0px !important;
}

.label-name {
  font-size: 12px;
  font-weight: bold;
}
.value-name {
  min-width: 40px;
  max-width: 150px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.value-date-name {
  min-width: 51px;
  max-width: 40px;
  font-size: 10px;
}
.label-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  min-width: 200px;
  width: 200px;
}
.product-demand-category-column {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 300px !important;
  /* width: fit-content !important; */
}
.farmer-details-header {
  display: flex;
  flex-direction: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.farmer-name {
  text-align: start;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-demand-table-container {
  padding: 20px;
  z-index: 999;
}

.product-switch {
  transform: rotate(90deg) !important;
}
.product-images {
  width: 40px;
}
.product-image {
  width: 60px;
}
.category-container {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 10px;
}
.category-container > .category-name {
  font-weight: bold;
  font-size: 16px;
  text-wrap: initial;
}
.order-container {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  gap: 60px;
}
.order-container > .category-name {
  font-weight: bold;
  font-size: 16px;
}
.category-names {
  font-weight: bold;
  font-size: 14px;
}
.product-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.product-container > .product-name {
  font-weight: bold;
  font-size: 15px;
  text-wrap: balance;
}

.farmer-label-name {
  font-size: 15px;
  font-weight: bold;
  width: 150px;
  text-align: start;
}
.farmer-value-name {
  width: 200px;
  font-size: 15px;
}

.farmer-label-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  width: 400px;
}
.farmer-label-containers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  width: 400px;
  margin-top: -18px;
}

.farmer-details-modal-container {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.date-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}

.group-header {
  position: sticky;
  top: 0;
  z-index: 10000;
}

.table-striped-rows .children-row:nth-child(odd) td {
  background-color: orange !important;
}

.table-striped-rows .children-row:nth-child(even) td {
  background-color: #cdb74a !important;
}
.category-even-row td {
  /* background-color: #8eaccd !important; */
  background-color: #99bc85 !important;
}

.category-odd-row td {
  /* background-color: #d7e5ca !important; */
  background-color: #bfd8af !important;
}

.product-even-row td {
  background-color: #d4e7c5 !important;
}

.product-odd-row td {
  background-color: #e1f0da !important;
}
.pending-icon {
  width: 25px;
}
.pending-status-container {
  /* width: 25px; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: end;
}
.date-picker-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: auto;
  align-items: center;
}
.farmer-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.product-demand-order-confirmation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.product-demand-order-confirmation-container-product-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.product-demand-order-confirmation-container-product-details-container > img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}
.total-product-details-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}
.total-product-details-container > .product-name {
  width: 300px;
  /* font-weight: bold; */
}
.product-order-quantity {
  width: 120px;
  text-align: center;
}
.product-stock-confirmation-details {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}
.farmer-summary-header {
  margin-bottom: 10px;
}
.farmer-summary-header > b {
  text-decoration: underline;
}
.footer-stock-count {
  font-weight: 500 !important;
  font-size: 12px;
  /* background-color: yellow; */
}
.display-product-info-container {
  display: flex;
  flex-direction: row;
  width: 130px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.display-product-info-container > b {
  width: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.product-footer {
  /* background-color: pink !important; */
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.product_demand_input {
  padding: 1px;
  color: rgb(144, 144, 144);
  font-weight: bold;
}
.warehouse-transfer-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
  min-height: 85px;
}
.warehouse-transfer-received-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
}
.ware_house_transfer_input_container {
  display: flex;
  flex-direction: row;
}
.farmer_summary_action > .ant-collapse-header {
  background-color: #194e08 !important;
}
.farmer_summary_action > .ant-collapse-header-text,
.ant-collapse-expand-icon {
  color: white !important;
}

.farmer_summary_action > .ant-collapse-header,
.ant-collapse-header-text {
  color: white !important;
}

.product-label-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.product-label-containers {
  margin-top: 5px;
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product-filter-checkbox > .ant-form-item-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}
.product-filter-checkbox > .ant-form-item-row > .ant-form-item-control {
  width: 50px !important;
  flex-grow: 0 !important;
}
.warehouse-transfer-customer-demand-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  /* gap: ; */
}

.toa-demand-value {
  color: white;
  background-color: #194e08;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.warehouse-transfer-input-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.product-label-containers {
  margin-top: 5px;
  width: 100% !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-demand-estimate-qty-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.product-demand-details-status-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.ant-table-column-sorter-full > .ant-table-column-sorter-inner {
  color: white !important;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: black !important;
}
.stock-transfer-warehouse-variant-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  gap: 5px;
}
.stock-transfer-warehouse-variant-name {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.product-demand-history-delivery-date-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  padding-right: 10px;
}
.farmer_order_history_product_container {
  display: flex;
  flex-direction: column;
}
.farmer_order_history_product_item_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.farmer_order_history_product_details {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
}

.farmer_order_history_product_details > img {
  width: 80px;
  object-fit: cover;
  border-radius: 50px;
}
.product-demand-history-main-container {
  padding: 0px 10px 0px 10px;
}
.product-demand-history-loading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.product-demand-expand-row {
  display: none;
}
.ant-table-expanded-row-level-1 > .ant-table-cell {
  display: none;
}
