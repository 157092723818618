@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .share_desktop {
    /* display: block !important; */
  }
  .share_mobile {
    display: none !important;
  }
  .ant-radio-button-wrapper {
    width: clamp(70px, 100%, 120px);
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0 !important;
  }
  .add_to_cart_button {
    width: clamp(80px, 100%, 200px) !important;
  }
  .add_to_cart {
    gap: 10px;
  }
  .product_detail_count_button {
    width: clamp(15px, 100%, 30px);
    padding: 0;
    text-align: center;
  }
  .cart_count {
    padding: 0;
    width: clamp(15px, 100%, 15px) !important;
    text-align: center !important;
  }
  .quantity_input {
    width: clamp(45px, 100%, 100px);
  }
  .title_text {
    font-size: clamp(1.7rem, 2.5vw, 2.2rem);
  }
  .category_text {
    font-size: clamp(1rem, 1.7vw, 1.4rem);
  }
  .ant-radio-button-wrapper span {
    font-size: clamp(0.6rem, 1.5vw, 1rem);
    padding: 0;
  }
  .sticky_column {
    position: sticky;
    top: 120px;
    z-index: 1; /* Ensure it stays above other elements if needed */
    align-self: flex-start; /* Helps maintain its position in a flex container */
  }
  .product_quantity_control {
    flex-direction: row;
    gap: 10px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .quantity-select {
    margin-right: 5px !important;
    width: 80px;
  }
  .product_dynamic_card-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .product_image_mobile_container {
    width: 30vw;
    height: 50vh;
  }

  .why_choose_myh_point {
    font-size: 15px !important;
  }
  .left_arrow {
    left: 3%;
    color: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 3px;
    background-color: #ebebeb;
    opacity: 0.7;
  }

  .right_arrow {
    right: 3%;
    color: black;
    border-radius: 50%;
    padding: 3px;
    background-color: #ebebeb;
    opacity: 0.7;
  }
  .hide_screen {
    display: none !important;
  }
  .rounded-product-detail-image {
    width: 60px !important;
    /* height: 60px !important; */
    /* border-radius: 50% !important; */
    object-fit: cover;
  }
  .thumbnail_container {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-left: -10px !important;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  .thumbnail_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .thumbnail_wrapper > .thumbnail_image {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 1px solid gray;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
  .product_image_container {
    max-width: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-writing-mode: vertical-rl;
    position: relative;
  }
  .product_image {
    object-fit: contain;
    aspect-ratio: 1 / 1;
    background-color: #ebfce9;
    border-radius: 25px;
    position: sticky;
    top: 10px;
    z-index: 1;
  }
  .product_description_title_container {
    width: 100% !important;
  }
}
