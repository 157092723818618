.packing-team-container {
  display: flex;
  flex-direction: column;
}

.packing-team-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 99%;
  position: -webkit-sticky;
  position: sticky;
  height: 50px;
  bottom: 0;
}
.date-picker-container-packing-team {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.packing-team-select-all-date-picker-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  gap: 18px;
}

.packing-team-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  margin-top: 50px;
  column-gap: 28px;
  height: 80vh;
}
.packing-team-container {
  padding: 10px;
}

.driver-card-container {
  display: flex;
  flex-direction: column;
  height: 115px;
}

.packing-team-driver-card {
  padding: 1px;
  width: 280px;
  margin-top: 5px;
  background-color: rgb(249, 249, 242);
  /* box-shadow: 0px 5px 9px 0px gray; */
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 6px 20px rgba(0, 0, 0, 0.1);
}

.driver-card-checkbox-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.packing-team-card-content {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.packing-team-driver-img {
  height: 70px;
  width: 70px;
  object-fit: contain;
  border-radius: 40px;
}

.packing-team-assigned-container {
  display: none;
}

.packing-team-driver-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  gap: 10px;
  font-family: "Montserrat-semibold";
}

.packing-team-driver-icon {
  margin-right: 6px;
  color: green;
}

.driver-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green;
  border-color: green;
  color: white;
}

.packing-team-header .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green;
  border-color: green;
  color: white;
}

.packing-team-table-view-container {
  margin-top: 30px;
}

.packing-team-title-add-button-container {
  display: flex;
  flex-direction: row;
  width: 99%;
  justify-content: space-between;
  margin-bottom: 25px;
  font-family: "Montserrat-semibold";
}

/* For the checked state - changing the background and border color to green */
.packing-team-container .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}

/* For the hover state - changing the border color to green */
.packing-team-container .ant-checkbox:hover .ant-checkbox-inner,
.packing-team-container .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #52c41a !important;
}

/* Optional: Change the color of the checkbox outline when focused */
.packing-team-container .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #52c41a !important;
}

/* Change the checkmark color to white when checked */
.packing-team-container .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #fff !important;
}
.product-variant-reassign-icon {
  cursor: pointer;
}
.packing-team-reassign-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}
.customer-order-assign-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  gap: 10px;
}
.customer-order-assign-staff-list-container,
.customer-order-assign-search-container {
  width: 200px;
}
