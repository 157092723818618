@media only screen and (min-width: 0px) and (max-width: 767px) {
  .customer-category-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .customer-category-image {
    width: 100px;
    height: 100px;
  }
  .skeleton-customer-category-card {
    height: 100px;
  }
}
