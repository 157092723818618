@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .order-placed-illustration img {
    width: 50%;
  }

  .order-cancelled-illustration img {
    width: 45%;
  }
  .order-placed-text h1 {
    font-size: 18px;
    color: #2e7d32;
    font-family: "Montserrat-semibold";
  }
  .order-placed-text p {
    font-size: 14px;
    color: #555;
    font-family: "Montserrat-semibold";
  }

  .order-cancelled-text h1 {
    font-size: 18px;
    color: #f81128 !important;
    font-family: "Montserrat-semibold";
  }
  .order-cancelled-text p {
    font-size: 14px;
    color: #555;
    font-family: "Montserrat-semibold";
  }
  .order-placed-actions .view-order-button,
  .order-placed-actions .continue-shopping-button {
    padding: 10px 20px;
    font-size: 13px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-family: "Montserrat-semibold";
    border: #2e7d32 1px solid;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .order-placed-content {
    text-align: center;
    /*background-color: white;
        */
    border-radius: 10px;
    padding: 20px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        */
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
    gap: 10px;
  }
  .vehicle-number {
    font-size: 12px;
    left: 190px;
    top: 100px;
  }

  /* ..refund */

  .product-return-address-container {
    width: 100% !important;
    padding: 5px;
  }
  .return-order-container {
    flex-direction: column;
    font-family: "Montserrat-semibold";
  }
  .return-order-details {
    width: 95%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .return-order-image-container {
    display: flex;
    justify-content: space-between !important;
    width: 100% !important;
    font-family: "Montserrat-semibold";

  }
  .return-order-quantity {
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }
  .product-return-container {
    flex-direction: column !important;
    width: 100% !important;
  }
  .product-return-product-container {
    width: 100% !important;
  }
  .return-order-checkbox {
    display: none !important;
  }
  .return-order-product-name {
    display: none !important;
  }
  .mobile_check_box {
    height: 100px;
  }
}
