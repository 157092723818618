.loader {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.truckWrapper {
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  overflow-x: hidden;
}
/* truck upper body */
.truckBody {
  width: 130px;
  height: fit-content;
  margin-bottom: 6px;
  animation: motion 1s linear infinite;
}
/* truck suspension animation*/
@keyframes motion {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* truck's tires */
.truckTires {
  width: 130px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 15px;
  position: absolute;
  bottom: 0;
}
.truckTires svg {
  width: 24px;
}

.road {
  width: 100%;
  height: 1.5px;
  background-color: #282828;
  position: relative;
  bottom: 0;
  align-self: flex-end;
  border-radius: 3px;
}
.road::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100%;
  background-color: #282828;
  right: -50%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 10px solid white;
}
.road::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #282828;
  right: -65%;
  border-radius: 3px;
  animation: roadAnimation 1.4s linear infinite;
  border-left: 4px solid white;
}

.lampPost {
  position: absolute;
  bottom: 0;
  right: -90%;
  height: 90px;
  animation: roadAnimation 1.4s linear infinite;
}

@keyframes roadAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-350px);
  }
}

.loading-container {
  width: 100%;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 10px;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
}
/* ..... */
/* From Uiverse.io by fthisilak */
.order-pay-btn {
  position: relative;
  padding: 12px 24px;
  font-size: 16px;
  background: #1a1a1a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.order-pay-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.order-icon-container {
  position: relative;
  width: 24px;
  height: 24px;
}

.order-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  color: #22c55e;
  opacity: 0;
  visibility: hidden;
}

.default-order-icon {
  opacity: 1;
  visibility: visible;
}

/* Hover animations */
.order-pay-btn:hover .order-icon {
  animation: none;
}

.order-pay-btn:hover .order-wallet-icon {
  opacity: 0;
  visibility: hidden;
}

.order-pay-btn:hover .order-card-icon {
  animation: orderIconRotate 2.5s infinite;
  animation-delay: 0s;
}

.order-pay-btn:hover .order-payment-icon {
  animation: orderIconRotate 2.5s infinite;
  animation-delay: 0.5s;
}

.order-pay-btn:hover .order-dollar-icon {
  animation: orderIconRotate 2.5s infinite;
  animation-delay: 1s;
}

.order-pay-btn:hover .order-check-icon {
  animation: orderIconRotate 2.5s infinite;
  animation-delay: 1.5s;
}

/* Active state - show only checkmark */
.order-pay-btn:active .order-icon {
  animation: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.order-pay-btn:active .order-check-icon {
  animation: orderCheckmarkAppear 0.6s ease forwards;
  visibility: visible;
}

.order-btn-text {
  font-weight: 600;
  font-family:
    system-ui,
    -apple-system,
    sans-serif;
}

@keyframes orderIconRotate {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px) scale(0.5);
  }
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
  }
  15% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
  }
  20% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px) scale(0.5);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px) scale(0.5);
  }
}

@keyframes orderCheckmarkAppear {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(-45deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}
/* map location loading */
.earth-loader {
  --watercolor: #3344c1;
  --landcolor: #7cc133;
  width: 12em; /* Increased size */
  height: 12em; /* Increased size */
  background-color: var(--watercolor);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  box-shadow:
    inset 0em 0.5em rgb(255, 255, 255, 0.25),
    inset 0em -0.5em rgb(0, 0, 0, 0.25);
  border: solid 0.2em white; /* Slightly thicker border */
  animation: startround 0.5s; /* Faster startup animation */
}

.earth p {
  color: #2d6c20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.5em;
  font-size: 1.75em; /* Larger text */
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.earth-loader svg:nth-child(1) {
  position: absolute;
  bottom: -3em; /* Adjusted for bigger size */
  width: 12em;
  height: auto;
  animation: round1 2.5s infinite linear 0.5s; /* Faster animation */
}

.earth-loader svg:nth-child(2) {
  position: absolute;
  top: -4.5em;
  width: 12em;
  height: auto;
  animation: round1 2.5s infinite linear;
}
.earth-loader svg:nth-child(3) {
  position: absolute;
  top: -3.5em;
  width: 12em;
  height: auto;
  animation: round2 2.5s infinite linear;
}
.earth-loader svg:nth-child(4) {
  position: absolute;
  bottom: -3.5em;
  width: 12em;
  height: auto;
  animation: round2 2.5s infinite linear 0.5s;
}

@keyframes startround {
  0% {
    filter: brightness(500%);
    box-shadow: none;
  }
  75% {
    filter: brightness(500%);
    box-shadow: none;
  }
  100% {
    filter: brightness(100%);
    box-shadow:
      inset 0em 0.5em rgb(255, 255, 255, 0.25),
      inset 0em -0.5em rgb(0, 0, 0, 0.25);
  }
}

@keyframes round1 {
  0% {
    left: -3em;
    opacity: 100%;
    transform: skewX(0deg) rotate(0deg);
  }
  30% {
    left: -9em;
    opacity: 100%;
    transform: skewX(-25deg) rotate(25deg);
  }
  31% {
    left: -9em;
    opacity: 0%;
    transform: skewX(-25deg) rotate(25deg);
  }
  35% {
    left: 10em;
    opacity: 0%;
    transform: skewX(25deg) rotate(-25deg);
  }
  45% {
    left: 10em;
    opacity: 100%;
    transform: skewX(25deg) rotate(-25deg);
  }
  100% {
    left: -3em;
    opacity: 100%;
    transform: skewX(0deg) rotate(0deg);
  }
}

@keyframes round2 {
  0% {
    left: 6em;
    opacity: 100%;
    transform: skewX(0deg) rotate(0deg);
  }
  75% {
    left: -10em;
    opacity: 100%;
    transform: skewX(-25deg) rotate(25deg);
  }
  76% {
    left: -10em;
    opacity: 0%;
    transform: skewX(-25deg) rotate(25deg);
  }
  77% {
    left: 12em;
    opacity: 0%;
    transform: skewX(25deg) rotate(-25deg);
  }
  80% {
    left: 12em;
    opacity: 100%;
    transform: skewX(25deg) rotate(-25deg);
  }
  100% {
    left: 6em;
    opacity: 100%;
    transform: skewX(0deg) rotate(0deg);
  }
}
