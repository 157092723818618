.due_amount_box {
  display: none !important;
  display: flex;
  justify-content: flex-end !important;
}

.due_amount_text {
  display: none !important;

  border: 1px solid #d45145;
  font-family: "Montserrat";
  font-weight: 500;
  color: #d45145;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
}
.address_title {
  font-family: "Montserrat-semibold";
  font-size: 16px;
  /* margin-bottom: 30px !important; */
}
.address-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_address_container {
  display: flex !important;
  justify-content: flex-end !important;
  margin-right: 12px;
}

.add_address_button {
  font-family: "Montserrat", sans-serif;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #4caf50;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-left: 11px;
  margin-bottom: 10px;
}

.add_address_button:hover {
  background-color: #f9f9f9;
}

.plus_icon {
  font-size: 20px;
  margin-right: 8px;
  cursor: pointer;
  color: #4caf50;
}

.plus_icon:hover {
  color: #45a049;
}
/* add address */
.form_container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;

  width: 75%;
  margin: 0 auto;
}

.form_title {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

.address_form {
  display: flex;
  flex-direction: column;
}

.form_group_half {
  gap: 20px;
}

.form_item_half {
  flex: 1;
}

.form_buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn_save {
  background-color: #4caf50 !important;
  color: #ffffff !important;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 10px;
}

.btn_save:hover {
  background-color: #45a049;
}

.btn_cancel {
  background-color: #f44336 !important;
  color: #ffffff !important;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.btn_cancel:hover {
  background-color: #e53935;
}

/* customer Addresses  */
.customer_addresses {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 40px;
}

.customer_addresses_skeleton {
  display: flex;
  gap: 10px !important;
  width: 100% !important;
}

.address_card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* cursor: pointer; */
  transition: border-color 0.3s ease;
  width: auto;
  row-gap: 10px;
}

.address_card.selected {
  border: 2px solid #4caf50;
}

.address_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.address_name {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.default_tag {
  margin-left: 5px;
  background-color: #ffffff;
  color: #4caf50;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 10px;
  border: 1px solid #4caf50;
}

.address_details {
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 0, 0, 0.88) !important;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}

.address_phone {
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 0, 0, 0.88) !important;
  font-weight: 500;
  font-size: 12px;
}

.address_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action_icon {
  margin-right: 10px;
  font-size: 15px;
  color: #4caf50;
  cursor: pointer;
}

.action_icon:hover {
  color: #45a049;
}

.delete_icon {
  color: #f44336;
}

.delete_icon:hover {
  color: #e53935;
}

.radio_button {
  /* margin-right: 10px; */
  cursor: pointer;
}

.delete_button_container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.no-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
}
.no-address-container,
.no-address-found-text {
  margin-top: -60px;
}
