@media only screen and (min-width: 0px) and (max-width: 767px) {
  .register-form-item {
    height: 100% !important;
  }

  .login-form-container {
    width: auto;
    min-width: auto !important;
  }
}
