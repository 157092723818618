@media only screen and (min-width: 0px) and (max-width: 767px) {
  .my-profile {
    padding: 20px;
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin: 10px;
  }

  .my-profile .ant-form-item-label > label {
    font-weight: bold;
    color: grey;
    font-family: "Montserrat-semibold";
  }

  .my-profile .ant-btn-primary {
    background-color: #4caf50;
    border-color: #4caf50;
    width: 100px !important;
    font-family: "Montserrat-semibold";
  }

  .my-profile-edit-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  .my-profile-form-fields {
    height: 200px !important;
  }

  .my-profile .ant-input {
    height: 45px;
  }
  .my-profile-title {
    font-family: "Montserrat-semibold";
    font-size: 15px;
    margin-bottom: 30px !important;
    padding-left: 15px;
  }

  .my-profile-form-container {
    margin-top: 20px;
  }
  .my-profile .ant-select-selector {
    height: 45px !important;
    font-family: "Montserrat-semibold";
  }

  .my-profile .ant-input {
    font-family: "Montserrat-semibold";
  }
  .my-profile .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .my-profile-edit-btn-container {
    text-align: center;
  }

  .my-profile .ant-form-item {
    margin-bottom: 20px;
  }

  .my-profile .ant-row {
    margin-bottom: 0;
  }
  .lat_lng_btn {
    margin-bottom: 10px !important;
    margin-right: 0 !important;
  }
  .address_form_buttons {
    display: flex !important;
    justify-content: flex-end !important;
    flex-wrap: wrap !important;
  }
  .cancel-tag {
    font-size: 12px !important;
    padding: 3px 6px !important;
    cursor: pointer !important;
  }
}
