@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .sidebar {
    /* margin-top: 10px;
     position: sticky !important; 
    top: 140px !important; */
  }

  .custom-due-amount-box-text {
    color: red;
    font-size: 12px;
    font-weight: bolder;
    font-family: "Montserrat-semibold";
  }
  .custom-due-amount-box-text {
    color: red;
    font-size: 12px;
    font-weight: bolder;
  }

  .order-list-heading {
    font-weight: bolder;
    font-family: "Montserrat-semibold";
    font-size: 18px;
  }
  .order-panel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: "Montserrat-semibold";
    font-size: 12px;
  }
  .order-listing-heading {
    font-size: 15px;
    font-family: "Montserrat-semibold";
  }

  .order-paid-heading {
    font-size: 15px;
  }
  .order-unpaid-heading {
    font-size: 15px;
  }
  .shipping-address {
    margin-bottom: 20px;
    font-family: "Montserrat-semibold";
    font-size: 13px;
    width: 80%;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .order-list-item-meta-title {
    font-weight: bold;
    font-size: 12px;
  }

  .item-price {
    font-weight: bold;
    font-size: 13px;
    font-family: "Montserrat-semibold";
  }

  .custom-due-amount-box-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 22px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 10px;
    display: none;
  }
  .sidebar-due-amount-container {
    display: none;
  }
  .order-shipping-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
}
