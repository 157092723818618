@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .share_desktop {
    display: block !important;
  }
  .share_mobile {
    display: none !important;
  }
  .quantity_input {
    width: clamp(45px, 100%, 100px);
  }
  .add_to_cart_button {
    width: clamp(80px, 100%, 200px) !important;
  }
  .add_to_cart {
    gap: 10px;
  }
  .product_detail_count_button {
    width: clamp(15px, 100%, 30px);
    padding: 0;
    text-align: center;
  }
  .product_quantity_control {
    display: flex;
    flex-direction: row;
    gap: 10px !important;
  }
  .cart_count {
    padding: 0;
    width: clamp(15px, 100%, 15px) !important;
    text-align: center !important;
  }
  .quantity_input {
    width: clamp(45px, 100%, 100px);
  }
  .social_icons {
    margin-top: 20px;
    position: absolute;
    display: flex;
    gap: 10px;
    top: 10px;
    right: 20px;
  }
  .radio_button_wrapper .value_button {
    display: flex;
    justify-content: center;
    width: 90px !important;
    margin: 5px 10px 0 0;
  }
  .sticky_column {
    position: sticky;
    top: 120px;
    z-index: 1; /* Ensure it stays above other elements if needed */
    align-self: flex-start; /* Helps maintain its position in a flex container */
  }
  .thumbnail_image {
    width: 40px;
    height: 40px;
  }
  .thumbnail_container {
    margin-left: -10px !important;
  }
  .hide_screen_desktop {
    display: none !important;
  }

  .heart_icon,
  .share_icon,
  .whatsapp_icon,
  .instagram_icon,
  .facebook_icon {
    font-size: 20px !important;
    cursor: pointer;
  }
  .share_social_media_icons {
    width: 25px;
  }
}
