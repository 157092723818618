@media only screen and (min-width: 0px) and (max-width: 767px) {
  .order-refund-list-container {
    display: flex;
    flex-direction: column;

    padding: 30px;
  }

  .order-refund-heading {
    font-weight: bolder;
    font-family: "Montserrat-semibold";
    margin-bottom: 34px !important;
    font-size: 15px;
    margin-top: 30px !important;
  }

  .order-refund-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    /* padding-left: 30px!important; */
    /* padding-right: 30px!important; */
  }
  .order-refund-status-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: end;
  }

  .order-refund-item-price {
    margin-left: 16px;
  }

  .item-status {
    font-family: "Montserrat-semibold";
    font-size: 12px;
  }

  .order-refund-collapse-container {
    display: flex;
    flex-direction: column;
    gap: 30px !important;
  }
  .return-product-prize-container {
    flex-direction: column !important;
    width: 100%;
  }
  .return-product-qty-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
  }
  .return-order-remark-wrapper {
    width: 98% !important;
  }
  .return-order-upload-wrapper {
    width: 100% !important;
  }
  .return-order-remark-container {
    flex-direction: column !important;
  }
  .return-product-second-col {
    padding: 10px;
  }
  .return-product-prize-wrapper {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .return-product-qty-wrapper {
    width: 100%;
  }
  .product-return-container {
    flex-direction: column-reverse !important;
    padding: 0px !important;
    background-color:#fff;
    width: 100% !important;
  }
  .product-return-product-container {
    width: auto !important;
    padding: 5px !important;
  }
  .product-return-address-container {
    width: fit-content;
    padding: 5px !important;
  }
  .return-order-container {
    flex-direction: column;
  }
  .return-order-details {
    width: 95%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
  }
  .return-order-quantity {
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    padding: 0px;
  }
  .return-product-card {
    width: auto;
  }
  .return-product-card-skeleton {
    width: 300px !important;
  }
  .return-product-address-card-skeleton {
    width: 300px !important;
  }
  .return-order-image-container {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }
  .return-order-checkbox {
    display: none !important;
  }
  .return-order-product-name {
    display: none !important;
  }
  .quantity-control-container-change {
    width: 260px !important;
    padding: 0px;
  }
  .mobile_check_box {
    height: 70px;
  }
  .return-order-product-mobile {
    margin-left: 5px;
  }
  .back_btn {
    width: 49% !important;
  }
  .return_btn {
    width: 49% !important;
  }
}
