@media only screen and (min-width: 0px) and (max-width: 767px) {
  .checkout_detail_container {
    padding: 20px 20px !important;
    width: auto;
  }
  .checklist_row {
    display: "flex" !important;
    flex-direction: "row-reverse" !important;
  }
  .checklist_col_one {
    order: 2 !important;
  }
  .checklist_col_two {
    order: 1 !important;
  }
  .delivery-to-text {
    font-size: 12px !important;
  }
  .payment-type-text {
    font-size: 12px !important;
  }
}
