@media only screen and (min-width: 0px) and (max-width: 767px) {
  .product-list-container {
    flex-direction: column;
  }
  .category-list-container {
    display: none !important;
  }
  .category-list-container-product {
    display: none !important;
  }
  .products-title-container {
    flex-direction: column;
  }
  .products-title-container .all-category-title {
    display: none;
  }
  .products-title-container .products-title {
    display: none;
  }
  .product-card {
    width: 100%;
    padding: 5px;
  }

  .product-card img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .product-card .category {
    font-size: 0.8em;
    height: 15px;
  }

  .product-card .title {
    font-size: 0.9em;
    margin: 3px 0;
    width: clamp(130px, 180px, 200px);
    height: 30px;
  }

  .product-card .price {
    font-size: 10px;
    font-weight: bold;
  }
  .product-card .sub_title {
    font-size: 0.7em;
  }

  .product-card .delivery {
    margin-top: 8px;
    font-size: 0.9em;
  }

  .product-card .quantity-control,
  .quantity-control {
    gap: 5px;
    font-size: 10px;
    width: 50px;
    height: 15px;
  }

  .product-card .quantity-control button,
  .quantity-control button {
    font-size: 13px;
    padding: 0px 3px;
  }

  .product-card .add-to-cart {
    margin-top: 2px !important;
    font-size: 9px;
    height: auto;
  }

  .product-card .add-to-cart button {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .quantity-control-container {
    height: 30px !important;
  }
  .CartBtn {
    height: 35px !important;
  }

  .product-card-action-button {
    width: 14px !important;
    font-size: 10px !important;
  }

  .product-card .quantity-control,
  .quantity-control {
    font-weight: 600;
  }

  .product-card-cart-action-button {
    font-size: 1em;
  }

  .product-skeleton-card .ant-card-body {
    padding: 10px !important;
  }

  .product-list-body-container {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    justify-items: center;
    width: 98% !important;
  }
  .add-to-cart > text {
    font-size: 10px !important;
  }

  .products-title > h2 {
    padding-top: 5px;
    font-size: 15px;
  }
  .products-title {
    text-align: end;
    width: 50%;
  }

  .product-list-main-container {
    padding: 10px !important;
    gap: 0px;
  }
  .category-subcategory-image-container {
    /* width: 125px !important; */
    width: 90% !important;
  }
  .category-item {
    padding: 10px 3px !important;
  }
  .category-subcategory-name {
    width: 60% !important;
  }
  .price-whole-part {
    font-size: 15px;
  }

  .discount-price {
    font-size: 15px;
  }

  .discount_txt {
    font-size: 15px;
  }
  .discount_off {
    font-size: 13px;
  }

  .original-price {
    font-size: 12px !important;
  }
  .product-list-category-main-container {
    width: 100% !important;
  }
  .category-title-txt {
    display: none !important;
  }
  .heart_icon,
  .share_icon,
  .whatsapp_icon,
  .instagram_icon,
  .facebook_icon {
    font-size: 20px !important;
    cursor: pointer;
  }
  .testimonial-content {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .testimonial-left {
    width: 100%;
  }
  .testimonial-right {
    width: 100%;
  }
  .testimonial-card {
    padding: 20px;
    min-height: 270px !important;
  }
  .share_social_media_icons {
    width: 20px !important;
  }
  .product-list-content-container {
    display: flex;
    flex-direction: column;
  }
  .empty-product-list-container img {
    width: 300px !important;
    height: 300px !important;
  }
  .empty-product-list-container p {
    font-family: Montserrat;
    font-size: 1.3rem !important;
    font-weight: 600;
    text-align: center;
    color: #777676;
  }
}
