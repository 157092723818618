.order-refund-list-container {
  display: flex;
  flex-direction: column;

  padding: 30px;
}

.order-refund-heading {
  font-weight: bolder;
  font-family: "Montserrat-semibold";
  margin-bottom: 40px !important;
}
.order-refund-panel-heading {
  font-family: "Montserrat-semibold" !important;
}
.order-refund-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  /* padding-left: 30px!important; */
  /* padding-right: 30px!important; */
}
.order-refund-status-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: end;
}

.order-refund-item-price {
  margin-left: 16px;
}

.item-status {
  font-family: "Montserrat-semibold";
  font-size: 12px;
}

.order-refund-collapse-container {
  display: flex;
  flex-direction: column;
  gap: 30px !important;
}
/* Card container */
.return-product-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 5px;
  background-color: #fff;
}
.return-product-container {
  padding: 10px;
  margin-bottom: 5px;
}

.return-product-address-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  position: sticky !important;
  top: 10px;
  padding: 10px;
}
/* Image column */
.return-product-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

/* Product image */
.return-product-image {
  width: 100%;
  max-width: 80px;
  height: auto;
  border-radius: 4px;
  object-fit: cover;
}

/* Details column */
.return-product-details-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Product name */
.return-product-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
}

/* Price and quantity container */
.return-product-price-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Product price */
.return-product-price {
  font-size: 16px;
  font-weight: 600;
  color: #ff5722;
}

/* Quantity selector container */
.return-quantity-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Quantity button */
.return-quantity-btn {
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  color: #333;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
}

.return-quantity-btn:disabled {
  background-color: #eaeaea;
  color: #b3b3b3;
  cursor: not-allowed;
}

/* Quantity text */
.return-quantity-text {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

/* Checkbox for return */
.return-checkbox {
  font-size: 14px;
  color: #333;
}
.return-product-name-container {
  display: flex;
  gap: 20px;
  width: 100% !important;
}
.return-product-prize-container {
  display: flex;
  justify-content: space-between;
}
.return-product-prize-wrapper {
  display: flex;
  flex-direction: column;
  width: 49%;
  gap: 5px;
}
.return-product-qty-wrapper {
  display: flex;
  width: 49%;
  gap: 5px;
  justify-content: space-around;
}

.return-product-qty-change-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.return-order-remark-container {
  padding: 10px;
  justify-content: space-between;
  display: flex;
}
.return-order-remark-wrapper {
  width: 76%;
}
.return-order-upload-wrapper {
  width: 18%;
}
.product-return-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* background-color: #fff; */
  gap: 20px;
  align-items: flex-start;
  padding: 10px;
}
.product-return-product-container {
  width: 60%;
  flex: 2 !important;
}
.product-return-address-container {
  width: 37%;
  flex: 1 !important;
  position: relative !important;
}
/* ... */

/* Container */
.return-order-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Image Section */
.return-order-image-container {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.return-order-product-image {
  max-width: 80px;
  height: 80px;
  object-fit: cover;
}

/* Details Section */
.return-order-details {
  width: 40%;
  /* background-color: green; */
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.return-order-product-name {
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat-semibold";
  margin-bottom: 8px;
}

.return-order-product-mobile {
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat-semibold" !important;
  margin-bottom: 8px;
}

.return-order-checkbox {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
  margin-bottom: 8px;
  width: 100%;
}

/* Quantity Section */
.return-order-quantity {
  width: 40%;
  /* background-color: yellow; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.return-order-quantity-text {
  margin-bottom: 8px !important;
}
.return-ordered-quantity {
  font-family: "Montserrat";
}
.return-order-qty-change-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.return-order-qty-change-container button {
  margin: 0 5px;
}

/* .//// */
.qty-change-container {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.qty-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #28a745;
  padding: 12px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  width: 100%;
}

.qty-btn {
  background-color: #fff;
  border: none;
  font-size: 18px;
  transition:
    transform 0.2s ease,
    background-color 0.2s ease;
}

.qty-btn:hover {
  transform: scale(1.1);
}

.decrement {
  color: #dc3545;
}

.increment {
  color: #007bff;
}

.qty-text {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  min-width: 40px;
  text-align: center;
}

.qty-change-text {
  font-size: 15px;
  font-weight: bold;
  color: black;
  min-width: 40px;
  text-align: center;
}
/* Style for checkbox container when checked */
.mobile_check_box .ant-checkbox-checked .ant-checkbox-inner,
.return-order-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: green !important; /* Green background */
  border-color: green !important; /* Green border */
}

/* Style for the checkmark icon inside the checkbox */
.mobile_check_box .ant-checkbox-checked .ant-checkbox-inner::after,
.return-order-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important; /* White border for the checkmark */
}

/* Remove default checkmark icon color and ensure it's white */
.mobile_check_box .ant-checkbox-checked .ant-checkbox-inner .ant-checkbox-icon,
.return-order-checkbox
  .ant-checkbox-checked
  .ant-checkbox-inner
  .ant-checkbox-icon {
  color: white !important; /* Ensure the checkmark icon is white */
}
.order_return_address_txt {
  margin-top: 8px;
  font-family: "Montserrat-semibold";
}
.decrement_icon {
  font-size: 15px;
}
.back_btn {
  background-color: #3f8635 !important;
  color: white;
  font-weight: 500;
  border: none;
}

.return_btn {
  background-color: rgb(230, 149, 0);
  color: white;
  font-weight: 500;
  border: none;
}
