@media only screen and (min-width: 0px) and (max-width: 767px) {
  .order-placed-illustration img {
    width: 80%;
  }
  .order-placed-illustration {
    width: auto;
  }
  .order-cancelled-illustration img {
    width: 88%;
  }
  .order-placed-text h1 {
    font-size: 16px;
    color: #2e7d32;
    font-family: "Montserrat-semibold";
  }

  .order-placed-text p {
    font-size: 11px;
    color: #555;
    font-family: "Montserrat-semibold";
  }

  .order-cancelled-text h1 {
    font-size: 16px;
    color: #f81128 !important;
    font-family: "Montserrat-semibold";
  }

  .order-cancelled-text p {
    font-size: 11px;
    color: #555;
    font-family: "Montserrat-semibold";
  }

  .order-placed-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 6px;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .order-placed-actions .view-order-button {
    background-color: #ffffff;
    color: #2e7d32;
    font-family: "Montserrat-semibold";
    border: #2e7d32 1px solid;
    font-size: 11px;
    width: 50%;
    height: 32px;
  }

  .order-placed-actions .continue-shopping-button {
    padding: 10px 20px;
    font-size: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-family: "Montserrat-semibold";
    width: 51%;
  }
  .order-placed-content {
    text-align: center;
    /*background-color: white;
        */
    border-radius: 10px;
    padding: 20px;
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .order-processing-content {
    width: 100% !important;
  }
}
