@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .category-list-mobile-container {
    display: none;
  }
  .product-list-body-container {
    grid-template-columns: repeat(auto-fill, minmax(13vw, 1fr)) !important;
  }
  .category-list-container-product {
    height: 84vh !important;
  }
  .testimonial-content {
    justify-content: space-between;
  }
  .testimonial-card{
    min-height: 270px !important;
  }
}
