.right-side-box-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.stock-in-delivery-date {
  width: 200px !important;
}
.stock-product {
  width: 300px !important;
}
.stock-in-hand {
  width: 150px !important;
}
.stock-index {
  width: 6% !important;
}
.stock-keeping-unit-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stock-keeping-unit-tab-list > .ant-tabs-nav > .ant-tabs-nav-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgba(128, 128, 128, 0.437) !important;
}
.stock-keeping-unit-tab-list
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  min-width: 150px !important;
  text-align: center !important;
  justify-content: center;
  font-weight: bold !important;
}
.stock-keeping-unit-tab-list
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active {
  font-weight: bold !important;
  background-color: #008000 !important;
}
.stock-keeping-unit-tab-list
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: white !important;
}
.next_delivery_date_move_footer_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
