.checkout_header {
  text-align: center;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 4px;
  color: #529d4b;
}
.your_order {
  width: auto;
  border-radius: 8px;
  background-color: #ffffff;
  /* padding: 10px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.your_order_heading {
  font-family: "Montserrat";
  padding: 10px;
  color: white;
  background-color: green;
  text-align: center;
  /* padding: 0 0 5px 0; */
  border-bottom: 1px solid rgb(223, 221, 221);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.your_order_details {
  padding: 10px;
}
.product {
  font-weight: bold;
  padding: 10px;
}
.product_item {
  font-family: "Montserrat";
  margin: 0;
  padding: 0;
}
.your_order_inner_div {
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}
.your_order_coupon_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  border-bottom: 1px solid rgb(223, 221, 221);
}
.your_order_coupon_container p {
  margin: 0;
  padding: 7px 0;
}
.coupon_applied_amount {
  color: red;
}
.order_summary_point {
  margin-top: 10px;
  margin-bottom: 10px;
}
.your_order_inner_div p {
  font-family: "Montserrat";
  margin: 0;
  padding: 7px 0;
}
.your_order_inner_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 0;
}
.subtotal {
  margin: 0;
}
.your_order_sub_total {
  display: flex;
  align-items: center;
  padding: 8px 0 !important;
  margin: 5px 0;
  justify-content: space-between;
  border-bottom: 1px solid rgb(223, 221, 221);
  border-top: 1px solid rgb(223, 221, 221);
}
.total_price {
  color: #529d4b;
}
/* .ant-collapse-arrow {
  display: none !important;
} */
.custom_collapse {
  width: 100% !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  margin-bottom: 20px !important;
}
.custom_collapse_product {
  width: 85% !important;
  margin-left: 35px;
  margin-bottom: 20px !important;
  font-size: "1.3rem";
  font-weight: 600;
}
.custom_collapse_product .ant-collapse-header .ant-collapse-item-active {
  background-color: green !important;
  color: white !important;
}
.custom_collapse .ant-collapse-item-active .ant-collapse-header {
  background-color: green !important;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 8px 8px 0 0 !important;
}
.custom_collapse_total_quantity .ant-collapse-item .ant-collapse-header {
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(13, 87, 23);
  color: rgb(0, 0, 0) !important;
  font-weight: 600 !important;
  border-radius: 8px 8px 0 0 !important;
}
.custom_collapse_total_quantity .ant-collapse-item .ant-collapse-header * {
  color: #000000;
}
.order_list_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .custom-collapse-header {
  background-color: green !important;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 8px 8px 0 0 !important;
} */
.ant-collapse-header {
  background-color: #f8f0f0 !important;
  color: black !important;
  font-weight: 600 !important;
}
.custom_collapse .ant-collapse-header .completed {
  background-color: #f8f0f0 !important;
}
.custom_collapse_product .ant-collapse-header {
  background-color: #f6f6f6 !important;
  color: black;
}

.checkout_detail_container {
  padding: 20px 80px;
  width: auto;
  background: url("../../assets/images/admin_login_background.png");
}
.checklist_col_one {
  order: 1 !important;
}
.checklist_col_two {
  order: 2 !important;
  margin-bottom: 20px !important;
}
.order_list_item {
  display: flex;
  flex-direction: column;
}

.order_list_item_content {
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.order_list_item_meta {
  flex: 1;
  margin-left: 16px;
}
.order_list_item_price {
  flex: 1;
  justify-content: center;
  font-weight: 400 !important;
}
.total_order_list_item_meta {
  flex: 1;
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
}
.item_total_price {
  text-align: center;
  /* font-weight: 700; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.order_list_item_footer {
  font-family: "Montserrat";
  width: 100%;
  padding-top: 0px;
  color: #888;
  font-size: 12px;
}
.checkout_confirm_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0 0 0;
}
.checkout_product_confirm_button {
  background-color: #1a9c0f;
  color: white;
  font-weight: 500;
}
.checkout_product_confirm_button:hover {
  background-color: #1a9c0f !important;
  color: white !important;
  box-shadow: none;
  border: 1px solid #1a9c0f !important;
}
.checkout_place_order_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0 0 0;
  margin-top: 10px;
}
.checkout_place_order_button {
  background-color: rgb(230, 149, 0);
  color: white;
  font-weight: 500;
}

.checkout_place_order_button:hover {
  background-color: rgb(230, 149, 0) !important;
  color: white !important;
  box-shadow: none;
  border: 1px solid rgb(230, 149, 0) !important;
}

/* Radio button color */
.checkout_payment .ant-radio-inner {
  border-color: #1a9c0f;
}

.checkout_payment .ant-radio-inner::after {
  background-color: #1a9c0f;
}

.checkout_payment .ant-radio-checked .ant-radio-inner {
  border-color: #1a9c0f;
  background-color: #ffffff;
}

.checkout_payment .ant-radio-checked .ant-radio-inner::after {
  background-color: #1a9c0f;
}

.checkout_payment .ant-radio:hover .ant-radio-inner {
  border-color: #1a9c0f;
}

.checkout_payment .ant-radio-wrapper:hover .ant-radio,
.checkout_payment .ant-radio:hover .ant-radio-inner,
.checkout_payment .ant-radio-input:focus + .ant-radio-inner {
  border-color: #1a9c0f;
}

.checkout_payment .ant-radio-checked:hover .ant-radio-inner {
  border-color: #1a9c0f;
}

.checkout_payment .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #1a9c0f;
}

/* Checkouut Address list */
.checkout_address_deliver_button {
  width: 100%;
  padding: 0;
  margin-top: 10px;
  background-color: #1a9c0f;
  color: #ffffff;
  font-weight: 500;
}
.checkout_address_deliver_button:hover {
  background-color: #1a9c0f !important;
  color: #ffffff !important;
  box-shadow: none;
  border: 1px solid #1a9c0f !important;
}
.checkout_address_add_more_button_div {
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
.checkout_address_list {
  height: 250px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.checkout_add_address_button {
  font-family: "Montserrat";
  width: 100%;
  height: 45px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: #1a9c0f !important;
}
.checkout_add_address_button:hover {
  color: #1a9c0f !important;
  box-shadow: none;
  border: 1px solid #1a9c0f !important;
}
.stock-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.available-quantity-content {
  width: 100%;
  text-align: end;
  color: red;
}
.available-quantity-content-heading {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: rgb(244, 182, 149);
  font-size: 18px;
}

/* CSS to style the green checkbox */
.green-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-color: #1a9c0f !important;
}

/* When the checkbox is checked, the inner box and border should be green */
.green-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1a9c0f !important;
  border-color: #1a9c0f !important;
}

/* Hover effect for the checkbox (checked and unchecked) */
.green-checkbox:hover .ant-checkbox-inner {
  border-color: #1a9c0f !important;
}

.green-checkbox:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1a9c0f !important;
  border-color: #1a9c0f !important;
}

/* Focus effect (when the checkbox is focused) */
.green-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #1a9c0f !important;
}

/* Checked state indicator (the tick mark) should also be green */
.green-checkbox .ant-checkbox-checked::after {
  border-color: #1a9c0f !important;
}

.return_options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #008000 !important;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.return_options > .ant-checkbox-wrapper {
  color: white !important;
  font-weight: 500;
}

.payment-type-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  margin-bottom: 5px;
}

.item_price {
  color: gray;
}
.total_item_price {
  color: rgb(0, 0, 0) !important;
  font-weight: normal;
}
.order_list_item_meta_title {
  font-weight: bold;
}
.has_perishable_item {
  display: none;
}
.perishable_item {
  display: block;
}
.custom-toast {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.payment-type-text {
  font-size: 14px !important;
}
