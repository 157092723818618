@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .share_desktop {
    display: block !important;
  }
  .share_mobile {
    display: none !important;
  }
  .social_icons {
    margin-top: 20px;
    position: absolute;
    display: flex;
    gap: 10px;
    top: 10px;
    right: 40px;
  }
  .heart_icon,
  .share_icon,
  .whatsapp_icon,
  .instagram_icon,
  .facebook_icon {
    font-size: 25px !important;
  }
  .wishlist_button,
  .share_desktop {
    width: 45px !important;
    height: 45px !important;
  }

  .category_text {
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
  }
  .title_text {
    font-size: clamp(2.1rem, 7vw, 2.4rem);
  }
  .stock_text {
    font-size: 1.1rem;
  }
  .product_details_related_product_heading {
    font-size: clamp(2.3rem, 7vw, 2.6rem);
  }
  .description_text {
    font-size: clamp(1rem, 2.5vw, 1.2rem);
  }
  .price_text {
    font-size: clamp(1.5rem, 2.5vw, 1.9rem);
  }
  .delivery_text {
    font-size: clamp(0.9rem, 2.5vw, 1.4rem);
  }

  .delivery_date_text {
    display: block;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }

  .quantity_label {
    font-size: clamp(0.9rem, 2.5vw, 1.4rem);
  }

  .ant-radio-button-wrapper-checked span {
    font-size: clamp(0.8rem, 2.5vw, 1.1rem) !important;
    padding: 0;
  }

  .ant-radio-button-wrapper span {
    font-size: clamp(0.8rem, 2.5vw, 1.1rem) !important;
    padding: 0;
  }
  .ant-radio-button-wrapper {
    width: clamp(150px, 100%, 200px);
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0 !important;
  }
  .add_to_cart_button {
    width: 250px !important;
    height: 40px !important;
    font-size: 1.3rem;
  }
  .cart_count {
    padding: 0;
    width: 30px !important;
    text-align: center !important;
    font-size: 1.4rem;
  }
  .product_detail_count_button {
    font-size: 1.2rem;
    text-align: center !important;
    width: 35px !important;
    height: 50px !important;
  }
  .quantity_input {
    width: clamp(55px, 100%, 110px) !important;
    height: 40px !important ;
    justify-content: center;
  }
  .radio_button_wrapper {
    width: 120px;
    height: 40px;
  }
  .ant-radio-button-wrapper {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .ant-radio-button-wrapper-checked span {
    font-size: 1.4rem;
  }
  .ant-radio-button-wrapper span {
    font-size: 1.4rem;
  }
  .product_detail_cart_icon {
    font-size: 1.8rem;
  }
  .radio_button_wrapper .value_button {
    display: flex;
    justify-content: center;
    width: 100px !important;
    margin: 5px 10px 0 0;
  }
  .custom_radio_group {
    height: 60px;
  }
  .radio_group {
    height: 60px;
  }
  .sticky_column {
    position: sticky;
    top: 120px;
    z-index: 1; /* Ensure it stays above other elements if needed */
    align-self: flex-start; /* Helps maintain its position in a flex container */
  }
  .product_quantity_control {
    display: flex;
    flex-direction: row;
    gap: 10px !important;
  }
  .hide_screen_desktop {
    display: none !important;
  }
  .category-list-container-product {
    height: 82vh !important;
  }
  .thumbnail_container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-left: -10px !important;
    margin-top: 10px;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .thumbnail_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .thumbnail_wrapper > .thumbnail_image {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 1px solid gray;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
}
