.order-card {
  margin-top: 10px;
}

.card-row {
  margin-top: 20px;
}

.card-col {
  margin: 5px 0 15px 0;
}

.card-text {
  text-align: center;
}

.card-button-group {
  display: flex;
  justify-content: flex-end;
}

.card-button-group .row {
  display: flex;
  gap: 15px;
}
.customer-order-customer-container-checkin {
  padding: 10px !important;
  margin: 12px 0 !important;
}
.update_packing_modal {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 10px;
}
.dispatch-button {
  background-color: #c0871e !important;
  color: white;
  border: 1px solid #c0871e !important;
  width: auto !important;
}
.delivered-button {
  background-color: #ff8800 !important;
  color: white;
  border: 1px solid #ff8800 !important;
  width: auto !important;
}

.delivered-button:hover,
.dispatch-button:hover {
  color: white !important;
}
.delivered-button:hover,
.dispatch-button:hover {
  color: rgba(255, 255, 255, 0.704) !important;
}
