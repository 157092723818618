.carousel-container {
  width: 100%;
  overflow: hidden;
  font-family: "Montserrat-semibold";
}

.carousel-item {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
}

.customer-home-farms-img {
  position: absolute;
  top: 12%;
  left: 9%;
  height: 10%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.delivery-to-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  gap: 2px;
  min-width: 180px;
  max-width: 250px;
}

.shop-now-button {
  background-color: #008000 !important;
  border-radius: 16px;
  color: white !important;
  font-size: 12px;
  border: none !important;
}
.shop-now-button-desktop {
  background-color: #008000 !important;
  border-radius: 10px;
  color: white !important;
  font-size: 14px;
  border: none !important;
  padding: 10px;
}
.delivery-to-text {
  font-size: 15px;
}

.add-delivery-address-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  margin-left: 70px;
}

.mobile-view-delivery-address-header {
  display: none;
}
.ant-select-arrow {
  color: rgba(4, 4, 4, 0.85) !important;
}

.carousel-subtext {
  position: absolute;
  top: 63%;
  left: 9%;
  width: 30%;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.carousel-button {
  position: absolute;
  top: 79%;
  left: 13.5%;
  transform: translate(-50%, -60%);
  margin-top: 1rem;
  padding: 0.62rem 1.2rem;
  background-color: #1b5e20;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-button:hover {
  background-color: #1b5e20;
}

.shop-now-icon {
  background-color: white;
  color: #2e7d32;
  border-radius: 50%;
  padding: 0.3rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.category-span-word {
  margin-left: 8px;
  color: #226c26;
}

.carousel-sub-field-header-container {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: rgb(228, 249, 228);
  padding-top: 20px;
  padding-left: 110px;
  padding-right: 40px;
  padding-bottom: 20px;
  font-family: "Montserrat-semibold";
  margin: 10px 0;
}

.carousel-sub-field-card {
  width: auto;
}

.carousel-sub-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 300px;
}

.sub-field-logo-container {
  margin-bottom: 10px;
}

.sub-field-logo {
  height: 45px;
  object-fit: contain;
  margin-top: 10px;
}

.sub-field-desc-container {
  padding: 0 10px;
  text-align: left;
}

.sub-field-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.sub-field-subtitle {
  font-size: 11px;
  color: #555;
}

.shop-now-options-container {
  background-color: white;
}

.shop-by-category-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.show-by-category-item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 10px;
  /* width: 50% !important; */
  font-family: "Montserrat-semibold";
}

.category-item {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 10px;
  /* width: 50% !important; */
  font-family: "Montserrat-semibold";
  cursor: pointer;
  /* background-color: pink; */
}

.category-subcategory-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  /* width: 140px; */
  width: 100%;
  height: 40px;
  outline: 1.2px solid #008000;
  padding-left: 3px;
  padding-right: 3px;
}

.category-subcategory-image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* border-radius: 50px; */
  height: 30px;
  object-fit: contain;
  padding: 5px;
}
.category-subcategory-name {
  font-size: 10px;
  font-weight: bold;
  color: #333;
  /* margin-top: 10px; */
  width: 65%;
  /* background-color: orange; */
  display: flex;
  justify-content: flex-start;
}

.category-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  text-align: center;
  width: 100px;
  height: 100px;
}

.category-image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50px;
  width: 85px;
  height: 70px;
  object-fit: contain;
  cursor: pointer;
}

.show-by-category-name {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  cursor: pointer;
}

.category-skeleton-avatar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  width: 95%;
}
.category-skeleton-item-loading {
  width: 15vh !important;
  height: 15vh !important;
}
.category-skeleton-item-loading > .ant-skeleton-avatar {
  width: 100% !important;
  height: 100% !important;
}
.show-by-category-category > .react-multiple-carousel__arrow {
  margin-bottom: 20px;
}

.custom-arrow {
  /* font-size: 20px;
  transition: all 0.3s ease;
  margin-bottom: 20px !important; */
}

.custom-arrow:hover {
  color: #555;
  transform: scale(1.1);
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
  z-index: 1 !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 10px);
}

.shop-now-card {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 15px 0;
  font-family: "Montserrat-semibold";
}

.shop-now-img {
  width: 420px !important;
  height: 200px !important;
  object-fit: cover !important;
  border-radius: 7px !important;
  transition: transform 0.5s ease-in-out;
  &:hover {
    transform: scale(1.04);
  }
}

/* .trending-products-container {
  padding: 60px;
} */

.trending-products-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  font-family: "Montserrat-semibold";
}

.trending-products-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  font-family: "Montserrat-semibold";
}

.testimonials-products-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  font-family: "Montserrat-semibold";
  padding-left: 20px;
  padding-right: 20px;
}
.category-filters {
  display: flex;
  align-items: center;
  /* z-index: 11111; */
}

.category-buttons {
  display: flex;
  gap: 10px;
}

.category-buttons .ant-btn {
  border-radius: 20px;
  padding: 0 20px;
}

.category-buttons .ant-btn-primary {
  background-color: #28a745;
  border-color: #28a745;
}

.category-buttons .ant-btn-default {
  border-color: #28a745;
  color: #28a745;
}

.category-dropdown {
  display: none;
}

.category-dropdown select {
  padding: 5px 10px;
  border-radius: 30px !important;
  border: 1px solid #28a745;
  background-color: white;
  color: #28a745;
}

.trending-products-list {
  padding-left: 10px !important;
  padding-right: 10px !important;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr)) !important;
}

.home-trending-products-list-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.why-myharvest-container {
  background-color: #e9e3d4;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 70px;
  justify-content: center;
  align-items: center;
}

.why-myharvest-desc-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25px;
  font-family: "Montserrat-semibold";
}

.why-myharvest-desc {
  color: #1b5e20;
  width: 370px;
  margin-top: 20px;
  font-size: 17px;
  font-family: "Montserrat";
}

.why-myharvest-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.why-myharvest-img {
  height: 600px;
  width: 900px;
  object-fit: contain;
}

.category-buttons .home-category-button:hover {
  background-color: #218838 !important;
  color: white !important;
  border: 1px solid #218838 !important;
}
.product-category-button {
  height: 50px !important;
}

.view-more-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  /* box-shadow: rgba(71, 199, 101, 0.45) 0px -25px 20px -20px; */
  padding: 10px 20px;
  font-size: 16px;
}

.view-more-button {
  color: white;
  background-color: #28a745;
}

.view-more-button:hover {
  color: white;
  background-color: #28a745;
}

.view-more-button-container .view-more-button:hover {
  background-color: #218838 !important;
  color: white !important;
  border: none;
}
.home-category-main-container {
  width: 100%;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
.category-subcategory-container {
  position: sticky !important;
  top: 85px !important;
  z-index: 100 !important;
  background-color: #f4fdf4 !important;
}

.icon-item-second {
  display: none;
}
.shop-now-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 0 10px 0;
  width: 100% !important;
}
.skeleton-loader {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  height: 120px !important;
  animation: skeleton-loading 3s infinite linear;
  border-radius: 8px;
}
.skeleton-loader-category {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 3s infinite linear;
  border-radius: 8px;
  width: 100px !important;
  height: 20px !important;
}

.skeleton-subcategory-loader-category {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 3s infinite linear;
  border-radius: 8px;
  width: 130px !important;
  height: 40px !important;
  border-radius: 50px !important;
}
.skeleton-loader-product-list-category-item {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 3s infinite linear;
  border-radius: 8px;
  width: 140px !important;
  height: 50px !important;
}
.react-multi-carousel-dot button {
  background-color: gray;
  border: none !important;
  width: 15px !important;
  height: 5px !important;
  border-radius: 10px !important;
}

.react-multi-carousel-dot--active button {
  width: 40px !important;
  height: 5px !important;
  border-radius: 20px !important;
  background-color: #d6d4d4 !important;
  position: relative !important;
  overflow: hidden !important;
}
.react-multi-carousel-dot--active button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px !important;
  width: 0%;
  background-color: gray;
  animation: fillEffect 3s linear forwards;
}
.testimonial-carousel .react-multi-carousel-dot--active button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px !important;
  width: 0%;
  background-color: gray;
  animation: fillEffect 5s linear forwards !important;
}

@keyframes fillEffect {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.create-better-container {
  background-color: #e9e3d4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-better-img-container {
  position: relative;
  display: flex;
  justify-content: start;
  width: 100%;
}

.create-better-bg-img-container {
  display: flex;
  justify-content: center;
}
.stalin-img-container {
  display: flex;
  justify-content: start;
  position: relative;
  right: 590px;
  object-fit: cover;
}
