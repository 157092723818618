@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .carousel-subtext {
    font-size: 12px;
  }

  .carousel-button {
    left: 13%;
    top: 80%;
    font-size: 0.7rem;
    padding: 7px 9px;
  }

  .shop-now-icon {
    font-size: 7px;
    padding: 3px;
  }

  .carousel-sub-field-header-container {
    width: auto;
    padding: 5px;
    flex-wrap: nowrap;
    padding-top: 20px;
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 20px;
  }

  .carousel-sub-field {
    width: 90%;
    justify-content: center;
    padding-top: 10px;
  }

  .sub-field-logo {
    height: 40px;
  }

  .sub-field-title {
    font-weight: 600;
    font-size: 12px;
  }

  .shop-now-card {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-around;
  }

  .shop-now-img {
    width: 320px !important;
    height: 135px !important;
  }

  .trending-products-header {
    padding: 0px 25px !important;
    margin-top: 20px;
    justify-content: space-between;
  }

  .trending-products-list {
    width: 95% !important;
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr)) !important;
  }

  .why-myharvest-img {
    height: 568px;
    width: 750px;
    object-fit: contain;
  }

  .why-myharvest-container {
    gap: 1px;
  }
  .shop-by-category-carousal .slick-active {
    /* width: 230px !important; */
    padding-left: 10px;
    /* width: 100% !important; */
    display: flex;
    flex-direction: row;
    /* height: 200px; */
  }
}
