@media only screen and (min-width: 2560px) {
  /* Global Styles */
  body,
  html,
  #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }

  .profile-layout-main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
  }

  .profile-layout-body {
    display: flex;
    flex: 1;
  }

  .profile_layout-content {
    flex: 1;
    padding: 20px;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Sidebar Styles */
  .sidebar {
    /* width: 250px; */
    /* height: 100%;
    background-color: white;
    color: #4caf50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 37px;
    display: flex;
    justify-content: center;
    gap: 20px; */
  }

  .sidebar-logo {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    /* margin-bottom: 0px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    font-family: "Montserrat";
  }

  .sidebar-logo .upload-icon-wrapper {
    width: 24px;
    height: 24px;
  }

  .sidebar-logo .upload-icon {
    color: white;
    font-size: 16px;
    font-family: "Montserrat";
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  .sidebar-item {
    padding: 15px 20px;
    cursor: pointer;
    text-align: left;
    transition: background 0.3s;
    display: flex;
    align-items: center;
  }

  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 16px; /* Add gap between menu items */
  }
  .ant-menu-vertical {
    border-inline-end: 0px solid rgba(0, 0, 0, 0) !important;
    /* Remove right border */
  }

  .sidebar-item:hover {
    background-color: #e0f7fa;
  }

  .sidebar-item.selected {
    color: #4caf50 !important;
  }

  .sidebar-item .anticon {
    margin-right: 10px;
  }

  .ant-menu-item-selected {
    color: #4caf50 !important;
  }

  .product-list-routes {
    color: #4caf50 !important;
  }

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Sidebar Styles */
  .sidebar {
    width: 250px;
    height: 100%;
    background-color: white;
    color: #4caf50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 37px;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .sidebar-logo {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    /* margin-bottom: 0px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    font-family: "Montserrat";
  }

  .sidebar-logo .upload-icon-wrapper {
    width: 24px;
    height: 24px;
  }

  .sidebar-logo .upload-icon {
    color: white;
    font-size: 16px;
    font-family: "Montserrat";
  }

  .sidebar-menu {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  .sidebar-item {
    padding: 15px 20px;
    cursor: pointer;
    text-align: left;
    transition: background 0.3s;
    display: flex;
    align-items: center;
  }

  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 16px; /* Add gap between menu items */
  }
  .ant-menu-vertical {
    border-inline-end: 0px solid rgba(0, 0, 0, 0) !important;
    /* Remove right border */
  }

  .sidebar-item:hover {
    background-color: #e0f7fa;
  }

  .sidebar-item.selected {
    color: #4caf50 !important;
  }

  .sidebar-item .anticon {
    margin-right: 10px;
  }

  .ant-menu-item-selected {
    color: #4caf50 !important;
  }

  .product-list-routes {
    color: #4caf50 !important;
  }

  .app-routes-heading {
    color: gray;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: 30px;
    /* font-size: 33px;  */
  }

  .custom-due-amount-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 35px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 15px;
    margin-right: 45px;
  }

  .custom-due-amount-box-text {
    color: red;
    font-size: 17px;
    font-weight: bolder;
    font-family: "Montserrat";
  }
  .custom-due-amount-box-text {
    color: red;
    font-size: 17px;
    font-weight: bolder;
  }

  .ant-menu-item {
    font-family: "Montserrat";
  }

  .custom-due-amount-box-container {
    display: flex;
    justify-content: end;
    width: auto;
    margin-bottom: 40px;
  }

  .custom-due-amount-box-sm {
    display: none;
  }
}
