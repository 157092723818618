/* From Uiverse.io by Praashoo7 */ 
.percentage_value_0 {
    color: white;
    opacity: 0;
    animation: pass 2s ease-in-out infinite;
    animation-delay: 0.2s;
    font-weight: bold;
    font-style: italic;
  }
  
  .precentage {
    color: white;
    opacity: 0;
    animation: pass 2s ease-in-out infinite;
    animation-delay: 0.4s;
    font-weight: bold;
    letter-spacing: 10px;
  }
  
  .O {
    color: white;
    opacity: 0;
    animation: pass 2s ease-in-out infinite;
    animation-delay: 0.6s;
    font-weight: bold;
  }
  
  .F {
    color: white;
    opacity: 0;
    animation: pass 2s ease-in-out infinite;
    animation-delay: 0.8s;
    font-weight: bold;
  }
  
  .F {
    color: white;
    opacity: 0;
    animation: pass 2s ease-in-out infinite;
    animation-delay: 1s;
    font-weight: bold;
  }
  
  @keyframes pass {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes pass1 {
    0% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }