@media (max-width: 767px) {
  .text-overlay {
    width: 100%;
    height: 50%;
    top: 50%;
    right: 0;
    padding: 20px;
  }
  
  .heading {
    font-size: 12px !important;
  }
  
  .description {
    font-size: 10px !important;
  }
  
  .read-button {
    font-size: 8px !important;
    padding: 8px 16px;
  }
}