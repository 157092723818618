.product_details_container {
  padding: 20px !important;
}
.product_detail_container {
  padding: 30px;
  font-family: "Montserrat", sans-serif;
  /* overflow: hidden; */
  flex-direction: column;
}

.image_card {
  text-align: center;
  border: none !important;
  background-color: transparent !important;
}

.share_icons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.product_image_main_container {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}
.product_image_container {
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-writing-mode: vertical-rl;
  position: relative;
  aspect-ratio: calc(16 / 16);
}

.product_image_mobile_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-writing-mode: vertical-rl;
}
.product_out_of_stock_image_container {
  background-color: rgb(240 248 255 / 31%);
}

.product_image {
  object-fit: cover;
  width: 100%;
  max-width: 450px; /* Set max width */
  aspect-ratio: 1 / 1; /* Maintain square shape */
  background-color: #ebfce9;
  border-radius: 25px;
  position: sticky;
  top: 10px;
  z-index: 1;
}
.product_image_out_stock {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: #ebfce9;
  border-radius: 25px;
  color: rgba(240, 248, 255, 0.307);
  opacity: 0.6;
}
.why_choose_myh_points {
  margin-left: 30px;
}
.about_product {
  margin-right: 20px !important;
}
.thumbnail_container {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-left: -10px !important;
  margin-top: 10px;
  flex-direction: column;
  flex-wrap: wrap;
}

.thumbnail_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.thumbnail_wrapper > .thumbnail_image {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 1px solid gray;
  object-fit: cover;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.selected_thumbnail > .thumbnail_image {
  opacity: 1;
  transform: scale(1.15);
  box-shadow: 0px 0px 10px rgba(76, 175, 80, 0.5);
  transition:
    transform 0.2s ease-in-out,
    box-shadow 0.3s ease-in-out;
}

.thumbnail_image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px; /* Slightly increased border radius */
  padding: 5px;
  transition:
    transform 0.2s ease-in-out,
    opacity 0.3s ease-in-out;
}

.thumbnail_image:hover {
  transform: scale(1.05); /* Slight hover effect */
  opacity: 0.9;
}

.cart_count {
  width: 30px !important;
  border: none !important ;
  background: transparent !important;
}

.details_card {
  border: none !important;
  background-color: transparent !important;
}
.skeleton_image {
  width: 100% !important;
  height: 350px !important;
}
.category_share_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.category_text {
  display: block;
  color: #529d4b;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}
.title_text {
  display: block;
  font-weight: 600;
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
}
.sub_title_text {
  display: block;
  /* font-weight: 600; */
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}
.stock_text {
  background-color: #bef5bb;
  color: #529d4b;
  font-size: 0.7rem;
  border-radius: 5px;
  padding: 3px 7px;
  font-family: "Montserrat", sans-serif;
}
.description_text {
  /* display: block;
  color: #a4a4a4;
  font-size: 0.9rem;
  font-family: "Montserrat", sans-serif;
  height: 80px;
  text-wrap: wrap;
  text-overflow: "ellipsis";
  white-space: "nowrap"; */
  /* width: 90%; */
}

.price_discount_percentage {
  margin-top: 14px;
  display: block;
  font-size: 1.1rem;
  color: #26a541;
  font-weight: 600;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 15px;
  background-color: yellow;
}
.delivery_text {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}

.delivery_date_text {
  display: block;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.quantity_label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
}

.quantity_select {
  width: 100px;
  font-family: "Montserrat", sans-serif;
}

.quantity_input {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  gap: 5px;
  /* margin: 20px 0; */
  /* border: 1px solid grey; */
  /* border-radius: 19px; */
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.add_to_cart_button {
  width: 180px !important;
  margin: 20px 0;
  background-color: #529d4b !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.is_disabled {
  background-color: #b5ceb3 !important;
  color: white;
}
/* .add_to_cart_button-disable {
  width: 180px !important;
  margin: 20px 0;
  background-color: #b5ceb3 !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
} */
.product_detail_cart_icon {
  font-size: 1.3rem;
}
.product_detail_cart_icon:hover {
  color: white !important;
}

.category_section {
  margin-top: 20px;
}

.social_icons {
  margin-top: 20px;
  position: absolute;
  display: flex;
  gap: 10px;
  top: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  /* z-index: 10000; */
}

.radio_group {
  width: 100%;
  justify-content: start;
  overflow: hidden;
  overflow-x: scroll;
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  margin-bottom: 20px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.radio_button_wrapper .value_button {
  display: flex;
  justify-content: center;
  /* width: 60px !important; */
  margin: 5px 10px 0 0;
  /* gap: 5px !important; */
}

.ant-radio-button-wrapper-checked {
  border-radius: 3px !important;
  border-color: #ffffff !important;
  background-color: #699467 !important;
  color: #ffffff !important;
  font-family: "Montserrat", sans-serif;
}
.ant-radio-button-wrapper {
  border-radius: 3px !important;
}

.ant-radio-button-wrapper-checked:hover {
  border-color: #ffffff !important;
}
.ant-radio-button-wrapper-checked:hover span:hover {
  color: #ffffff !important;
}

.ant-radio-button-wrapper-checked:hover span {
  color: #ffffff !important;
}
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper span:hover {
  color: #699467 !important;
}

.add_to_cart {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
  font-family: "Montserrat", sans-serif;
}
.product_details_related_products_two {
  color: #529d4b;
}
.product_details_related_product_heading {
  font-weight: 600;
  font-size: 26px !important;
  margin-bottom: 20px !important;
  font-family: "Montserrat", sans-serif;
}
.about_product {
  margin: 25px !important;
}
.product_details_why_myh {
  font-weight: 600;
  font-size: 26px !important;
  margin-bottom: 20px !important;
  font-family: "Montserrat", sans-serif;
}
.why_choose_myh_point {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Montserrat", sans-serif;
  font-size: 16px !important;
  margin-bottom: 20px !important;
}
.why_choose_myh_point_icon {
  margin-right: 10px;
}
.why_choose_myh {
  margin: 25px !important;
}
.related_product_container {
  margin: 25px !important;
}

.product_row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px !important;
}

.product_col {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-bottom: 10px;
}
.product_container {
  overflow-x: scroll;
  display: flex;
  width: 100% !important;
  margin-bottom: 20px !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.product_scroller {
  position: relative;
  display: flex;
  align-items: center;
}

.nav_button {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.slick-track {
  /* margin-bottom: 30px !important; */
}
.nav_button.left {
  left: 0px;
  border-radius: 50%;
  background-color: #529d4b !important;
  color: white !important;
}

.nav_button.right {
  right: 0px;
  border-radius: 50%;
  background-color: #529d4b !important;
  color: white !important;
}
.ant-carousel .slick-next {
  color: transparent !important;
  font-size: 24px !important;
  font-weight: bolder !important;
}

/* .anticon svg {
  
} */
.slick-prev {
  color: transparent !important;

  font-size: 14px !important;
  font-weight: bolder !important;
}

.product_detail-breadcrumb {
  /* margin-top: 30px; */
}
.product-detail {
  /* padding: 30px; */
  width: 100%;
  height: fit-content;
}
.slick-next > svg {
  display: inline-block;
  color: #f8fdff;
  background-color: #529d4b;
  width: 28px;
  height: 27px;
  border-radius: 50%;
  padding: 5px;
}
.slick-prev > svg {
  display: inline-block;
  color: #f8fdff;
  background-color: #529d4b;
  width: 28px;
  height: 27px;
  border-radius: 50%;
  padding: 5px;
}
.quantity-select-option {
  box-sizing: border-box !important;
  width: 100% !important;
}
.approve_status_container {
  display: flex;
  padding: 10px 0px;
}
.quantity-control {
  gap: 10px !important;
}
.quantity-select .ant-select-selector {
  border-radius: 20px !important;
  height: 30px !important;
  color: #45a049 !important;
  border-radius: 15px !important;
  border-color: #45a049 !important;
}
.variant-select .ant-select-selector {
  border-radius: 20px !important;
  height: 30px !important;
  color: #45a049 !important;
  border-radius: 15px !important;
  border-color: #45a049 !important;
}
.quantity-select {
  margin-right: 5px !important;
  /* width: 60px; */
}
.product_delivery_by_container {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
}
/* .product_item_quantity_container {
  margin-bottom: 10px;
  font-size: 15px;
} */
.product_item_quantity_main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.product_item_quantity_container > b {
  color: gray;
}
.product_item_quantity_container > span {
  cursor: pointer;
}

.product_item_quantity_container > span:hover {
  color: #45a049;
  text-decoration: underline;
}

.product_item_qty_related_product {
  font-size: 12px;
  display: flex;
  justify-content: start;
  /* margin-top: 5px; */
}
.product_item_qty_related_product > b {
  color: gray;
  margin-right: 5px;
  margin-left: 5px;
}

.product_item_qty_related_product > span {
  cursor: pointer;
  margin-left: 5px;
}
.add-to-cart > text {
  cursor: pointer !important;
  margin-left: 7px !important;
  font-size: 13px;
  margin-bottom: 5px;
}
.product_item_qty_related_product > span:hover {
  color: #45a049;
  text-decoration: underline;
}

.add_to_cart_icon {
  background-color: green;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_dynamic_card-container {
  padding: 20px;
}

.product_dynamic_card-skeleton-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.product_dynamic_card-skeleton {
  width: 100px;
  height: 100px;
}

.product_dynamic_card-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  justify-items: flex-start !important;
}

.product_dynamic_card-item {
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.product_dynamic_card-item:hover {
  transform: scale(1.05);
}

.product_dynamic_card-image-container {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}

.product_dynamic_card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_dynamic_card-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}

.ant-collapse-header-text {
  color: black;
}
.description_content {
  width: 100% !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
.product-quantity-control-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 32px;
  border: 1.5px solid var(--product-cart-button);
  border-radius: 12px;
}

.product-quantity-control-container > span {
  flex: 1;
  text-align: center;
  font-weight: 600;
}
.discount-price {
  margin-left: 20px;
}

.original-price {
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
  font-size: 16px;
  font-weight: 600 !important;
}

.discounted-decimal {
  font-size: 8px;
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
}
/* ...... */
.product_price_container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.price_section {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  text-align: end;
}

.price_section.discounted {
  /* background-color: #ffe5e5; */
}

.price_section.original {
  /* background-color: #e0e0e0; */
}

.price_section.discount_value {
  /* background-color: #ffe5e5; */
}

.price_section.discounted {
  /* background-color: #ffe5e5;  */
}

.price_section.discount_value {
  background-color: #ffe5e5;
}

.price_text {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  padding: 0px !important;
  margin: 0px !important;
}
.price-tag {
  font-weight: 400 !important;
  font-size: 20px;
  color: gray;
}

.price_text_mrp {
  text-decoration: line-through;
  text-decoration-thickness: 1.5px;
  font-size: 1.4rem;
  color: #757575;
  font-weight: 600;
}

.discount_percentage {
  font-size: 20px;
  font-weight: bold;
  color: #d32f2f;
}

.decimal {
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: #757575;
}
.product-detail-image-container {
  display: flex;
  gap: 10px;
  margin: 10px 0 20px 0;
  /* overflow: hidden; */
  /* overflow-x: scroll; */
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  flex-wrap: wrap;
}
/* .product-detail-image-container::-webkit-scrollbar {
  display: none;
} */

.rounded-product-detail-image {
  width: 70px !important;
  /* height: 70px !important; */
  /* border-radius: 50% !important; */
  object-fit: cover;
}
.product_description_title_container {
  width: 85%;
}
.related-products-carousel {
  position: relative !important;
}
.custom-arrows-related-products {
  position: absolute;
  top: -45px;
  right: 10px;
  display: flex;
  gap: 8px;
}

.arrow-btn-related-products {
  background: #d9dadb;
  border: 1px solid #ddd;
  padding: 8px 8px;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  height: 30px;
  width: 30px;
  overflow: hidden !important;
}

.arrow-btn-related-products:hover {
  background: #008000;
  color: #ffff;
}
.arrow-btn-related-products:active {
  background: #008000;
  color: #ffff;
}

.left-arrow,
.right-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}
