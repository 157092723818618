/* Container styling */
.customer-profile-container {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;
  font-family: "Montserrat-semibold";
}
.customer-profile-rating {
  display: flex !important;
  justify-content: end !important;
  gap: 10px !important;
  margin-bottom: 200px;
}
/* Image container styling */
.customer-profile-image {
  flex: 1;
  text-align: center;
  padding-right: 24px;
}

.profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 3px solid #4caf50;
}

/* Details section styling */
.customer-profile-details {
  flex: 2;
  font-family: "Roboto", sans-serif;
  color: #333333;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 8px 16px;
}

/* Each detail item */
.detail-item {
  display: contents;
  font-size: 16px;
  line-height: 1.5;
  font-family: "Montserrat-semibold";
}

.detail-label {
  color: #4caf50;
  font-weight: bold;
}

.detail-value {
  font-family: "Montserrat-semibold";
}

/* Button styling */
.customer-profile-details .ant-btn-link {
  padding: 0;
  font-size: 16px;
  color: #4caf50 !important;
}

.customer-profile-details .ant-btn-link:hover {
  color: #4caf50 !important;
}
/* .detail-item{
    display: flex;
    flex-direction: row;
    
    
} */

.detail-colon {
  font-weight: bolder;
  margin-right: 10px;
}

.conversation-tab-card {
  padding: 0px 10px !important;
}
.customer-order-customer-container {
  position: sticky !important;
  top: 10px;
  padding: 10px !important;
}
.customer-order-return-customer-container {
  padding: 10px !important;
}
.customer_order_summary_amount_container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
.customer_order_summary_container_label {
  width: 210px;
  min-width: 210px;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.packing-person-check-in {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.packing-person-check-in-title {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center !important;
}
