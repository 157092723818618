@media only screen and (min-width: 0px) and (max-width: 768px) {
  .profile-layout-main-container {
    background-color: transparent;
  }
  .profile-layout-body {
    display: flex;
    flex: 1 1;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    /* background-color: transparent; */
    align-items: center;
    padding: 0px;
    background-color: gray;
  }
  .app-routes-heading-container {
    /* display: none; */
  }

  .sidebar-logo-sm {
    width: 72px !important;
    height: 70px !important;
    font-size: 58px !important;
  }
  .sidebar {
    width: 90%;
    position: relative;
    /* height: 100%; */
    background-color: white;
    color: #4caf50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    margin-top: 10px;
    box-shadow: 0px 12px 49px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding-left: 10px;
    padding-right: 10px;
    top: 0px;
    z-index: 0;
  }
  .sidebar-logo {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 50px;
    width: 100%;
  }

  .sidebar-logo .upload-icon-wrapper {
    top: 68%;
    right: 45%;
    width: 24px;
    height: 22px;
  }

  .sidebar-user-name {
    font-size: 16px;
  }

  .order-list-container {
    padding: 0px;
    /* max-width: 1200px; */
    width: auto;
    margin: 0 15px;
    background-color: white;
  }

  .sidebar-menu-container {
    color: #d5d9d9 !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    width: 100%;
    font-weight: bold !important;
    gap: 0px;
    margin-bottom: 18px;
  }
  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 0px;
  }

  .sidebar-menu-icon {
    font-size: 15px !important;
    font-weight: bold !important;
    color: rgb(151, 149, 149) !important;
  }

  .order-list-heading {
    font-size: 14px;
  }

  .search-bar-container {
    /* display: none; */
  }
  .custom-search-bar {
    width: 150px;
    display: flex;
    justify-content: flex-end;
  }
  .custom-due-amount-box-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 5px;
    border: red 1px solid;
    padding: 6px;
    /* margin-right: 45px; */
  }
  .custom-due-amount-box-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 22px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 10px;
    /* margin-right: 45px; */
    display: none;
  }
  .sidebar-due-amount-container {
    display: flex;
    justify-content: flex-end;
    width: 80%;
  }

  .custom-due-amount-box-text {
    color: red;
    font-size: 8px;
    font-weight: bolder;
  }
  .custom-due-pay-amount-box-text {
    color: red;
    font-size: 8px;
    font-weight: bolder;
    min-width: 70px;
  }
  .custom-due-pay-amount-box,
  .custom-due-pay-amount-box::before,
  .custom-due-pay-amount-box::after {
    font-size: 8px;
    min-width: 70px;
  }

  .order-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
  }

  .ant-menu-vertical .ant-menu-item {
    /* background-color: #4caf50; */
    /* width: 70px !important; */
    height: 43px !important;
    display: flex;
    align-items: center;
  }
  .ant-menu-item {
    color: gray !important;
    font-size: 14px;
  }

  /* .customer-layout-main-container {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  } */

  .ant-menu-item-selected {
    color: #4caf50 !important;
  }

  .sidebar-item.selected {
    color: #4caf50 !important;
  }

  .profile_layout-content {
    flex: 1;
    padding: 10px;
    width: 100%;
  }

  /* .ant-collapse-expand-icon {
    display: none !important;
  } */
  .shipping-address {
    margin-bottom: 20px;
    font-size: 12px;
    width: 100% !important;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .order-listing-heading {
    color: #4caf50;
    font-size: 16px;
    /* font-weight: bold; */
  }
  .order-shipping-container {
    display: flex;
    flex-direction: column-reverse !important;
  }

  .order-paid-heading {
    font-size: 16px;
  }
  .order-unpaid-heading {
    font-size: 16px;
  }
  .order-list-item {
    /* font-size: 30px!important; */
    display: flex;
    flex-direction: row;
    gap: 20px !important;
  }
  .order-list-item-image-sm {
    width: 60px !important;
    height: 50px !important;
  }

  .product-details {
    display: flex;
    gap: 29px;
    flex-direction: column;
  }
  .order-list-item-meta-title {
    font-size: 11px !important;
  }
  .item-price {
    font-size: 11px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px;
    display: flex !important;
    flex-direction: column !important;
    gap: 0px !important;
  }
  .order-summary {
    margin-top: 20px;
    font-weight: bold;
    font-size: 11px;
  }
  .order-panel-header {
    display: grid;
    width: 100%;
    font-size: 11px;
    gap: 8px;
    grid-template-columns: 50% 50%;
  }

  .ant-collapse-item {
    border-bottom: 5px solid #d9d9d9 !important;
  }

  .sidebar-logo .upload-icon {
    color: white;
    font-size: 15px;
  }
  .sidebar-logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
  }

  .order-return-btn {
    background-color: #4caf50;
    /* font-weight: bold; */
    color: white;
    font-family: "Montserrat-semibold";
    font-size: 9px;
  }

  .order-cancel-btn {
    font-family: "Montserrat-semibold";
    font-size: 9px;
  }

  .order_list_loading_container {
    flex-direction: column;
    gap: 10px;
  }
  .order_date,
  .total_amount,
  .shipped_to {
    display: none;
  }
  .customer-order-status-container {
    text-align: end;
  }
  .customer_order_ordered_on_container {
    display: flex;
    visibility: visible !important;
  }
  .customer_order_date_container {
    flex-direction: column;
  }
  .customer_return_order_date_container {
    flex-direction: column;
  }
}
