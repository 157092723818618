.cart-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  padding-left: 40px;
}

.cart-title {
  color: #1b5e20;
}

.customer-cart-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width: 100%; */
  height: 100% !important;
  padding-bottom: 20px;
  font-family: "Montserrat-semibold";
}

.customer-cart-page-title-container {
  width: 100%;
}

.customer-cart-page-sub-section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 400px;
  width: 30%;
  position: sticky;
  top: 20px;
}

.customer-cart-page-table-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: "Montserrat-semibold";
  height: max-content;
}

.customer-cart-pagetable-inner-container {
  width: 65%;
  height: max-content;
}

.customer-cart-pagetable-inner-container::-webkit-scrollbar {
  display: none !important;
}

.cart-item-mobile-view-container {
  display: none;
}

.cart-table {
  width: 98%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
/* .ant-table-thead > tr > th {
  background-color: #1b5e20 !important;
  color: white;
  border-bottom: none;
} */

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}
.product-variant:hover {
  text-decoration: underline;
}
.product-info-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.product-info-img-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.product-info img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.product-info-description {
  font-weight: bold;
}

.delivery-date {
  color: #6dbd64;
  font-size: 12px;
  margin-top: 12px;
  font-family: "Montserrat-semibold";
}

.empty-cart-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  /* overflow: hidden; */
}

.empty-cart-img {
  height: 40vh;
  object-fit: contain;
  background-color: white;
  border-radius: 20px;
}

.empty-cart-btn {
  background-color: #008000 !important;
  color: white;
  border: none;
}

.empty-cart-btn:hover {
  background-color: #008000 !important;
  color: white !important;
  border: none;
}

.quantity-controls {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 20px;
  /* padding: 2px 10px; */
  width: 100%;
}

.quantity-controls span {
  margin: 0 10px;
  font-size: 16px;
  font-family: "Montserrat-semibold";
}

.quantity-controls button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  outline: none;
}

.quantity-controls button:disabled {
  cursor: not-allowed;
  color: #ccc;
}

.sub-total-heading {
  text-align: center;
}

.subtotal-section {
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* padding: 8px; */
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
  background-color: white;
  margin-bottom: 10px;
}

.subtotal-section .sub-total-heading {
  background-color: #008000 !important;
  color: white;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.subtotal-section .summary {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  font-family: "Montserrat-semibold";
}

.subtotal-section .summary p {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-family: "Montserrat-semibold";
}

.subtotal-section .summary hr {
  width: 100%;
  border: 1px solid #ddd;
  margin: 10px 0;
}

.cart-checkout-btn-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
}

/* .cart-checkout-btn-container */

.ant-btn.css-dev-only-do-not-override-1pg9a38.ant-btn-primary.ant-btn-block.cart-checkout-btn:hover {
  background-color: #cd600a;
  border-radius: 3px;
  color: white;
}
.cart-checkout-btn-disabled {
  width: 50% !important;
  background-color: #d2d0ce !important;
  border-radius: 3px;
  color: white;
  text-wrap: wrap;
  font-family: "Montserrat-semibold";
}
.cart-checkout-btn {
  width: 50% !important;
  background-color: #cd600a;
  border-radius: 3px;
  font-size: 14px;
  color: white;
  text-wrap: wrap;
  height: auto;
  font-family: "Montserrat-semibold";
}
.cart-checkout-btn:hover {
  background-color: #cd600a !important;
}

.ant-btn.css-dev-only-do-not-override-1pg9a38.ant-btn-primary.ant-btn-blockcart-checkout-btn-disabled:hover {
  background-color: #d2d0ce !important;
  border-radius: 3px;
  color: white;
}

.ant-btn-icon:hover {
  color: black;
}

.ant-btn.css-dev-only-do-not-override-1pg9a38.ant-btn-default.ant-btn-icon-only:hover {
  color: black;
  border: none;
}

.cart-total-price {
  font-weight: bold;
}

.coupon-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.coupon-section > .coupon-input {
  border-radius: 0px;
}

.coupon-section > .apply-btn {
  /* background-color: #1b5e20; */
  background-color: #008000 !important;
  color: white;
  border: none;
  text-align: center;
  border-radius: 0px;
  font-family: "Montserrat-semibold";
}

.coupon-section > .apply-btn:hover {
  /* background-color: #1b5e20 !important; */
  background-color: #008000 !important;
  color: white !important;
}

.coupon-section > .coupon-input {
  border-color: lightgray;
}

.coupon-section > .coupon-input:focus {
  border-color: lightgray !important;
}
.customer-cart-page-table
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-header
  > table
  > thead
  > tr
  > .ant-table-cell {
  background-color: #008000 !important;
  color: white;
}
.admin-table
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-header
  > table
  > thead
  > tr
  > .ant-table-cell {
  background-color: #008000 !important;
  color: white;
}

.admin-table
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table
  .ant-table-container
  .ant-table-content
  > table
  > thead
  > tr
  > .ant-table-cell {
  background-color: #008000 !important;
  color: white;
}
.tax-container {
  text-decoration: underline;
  cursor: pointer;
}

.continue_shopping_btn {
  background-color: #008000;
  font-weight: bold;
  font-family: "Montserrat-semibold";
}

.shopping_btn {
  display: flex;
  justify-content: center;
  gap: 10px !important;
  margin-top: 10px;
}
.back_to_cart_container {
  display: flex;
  justify-content: center;
}
.back_to_cart_button {
  background-color: #008000;
  font-weight: bold;
  font-family: "Montserrat-semibold";
  margin-top: 10px;
}
.back_to_cart_button:hover {
  background-color: #008000 !important;
}
.continue_shopping_btn:hover {
  background-color: #008000 !important;
}

/* coupon */
.coupon-applied-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
.coupon-remove-container {
  color: red;
  cursor: pointer;
}
.coupon-container {
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
  color: #295d8f;
  cursor: pointer;
}
.coupon-applied-card {
  position: relative;
  width: 320px;
  min-height: 150px;
  max-height: 300px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
}

/* Light blue background effect */
.coupon-applied-background {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  background: #e0f7fa;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(2deg);
}

/* Coupon main content */
.coupon-applied-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coupon-applied-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.coupon-applied-content p {
  color: #666;
  margin-bottom: 10px;
}

.coupon-applied-code {
  font-family: monospace;
  font-size: 1.2rem;
  padding: 8px 12px;
  background: #f0f0f0;
  border: 1px dashed #888;
  border-radius: 5px;
  display: inline-block;
}
.coupon-applied-top-highlight-line {
  border: 1px dashed #888;
}

/* Ticket cutout effect */
.coupon-applied-card::before,
.coupon-applied-card::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 20px;
  background: white;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 20px 20px;
}

.coupon-applied-card::before {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
}

.coupon-applied-card::after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  background-color: #ddd;
}

.coupon-change-icon {
  color: #003566;
  font-size: 20px;
  cursor: pointer;
}
.coupon-remove-icon {
  color: red;
  cursor: pointer;
  font-size: 20px;
}
.coupon-applied-card-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.coupon-applied-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.coupon-used-container {
  color: red;
}

.ratings-collapse-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Order-ratings {
  border: 1px solid #008000;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.order-remarks-container {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.order-ratings-text {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
}

.product_rating_label {
  border: 1px solid #008000;
  border-radius: 10px;
  padding: 10px;
}
.remarks-text {
  font-size: 13px;
  font-weight: bold;
}
.cart-tax-content {
  font-family: "Montserrat-semibold";
  font-size: 12px;
  margin-top: 10px;
}
