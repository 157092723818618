.search-bar {
  display: flex;
  align-items: center;
  width: 40%;
  height: 50px;
}
.user-search-box .ant-input-affix-wrapper .ant-input-suffix {
  margin-top: 14px;
}
.user-search-box
  > .ant-input-wrapper
  > .ant-input-group-addon
  > div
  > .ant-select
  > .ant-select-selector {
  background-color: #4f9f49 !important;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border: 1px solid #4f9f49 !important;
  height: 45px !important;
  margin-top: -6px !important;
  width: 250px !important;
}
.icons-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.icon-item {
  display: flex;
  align-items: normal;
  margin-right: 20px;
  /* width: 200px; */
  width: 120px;
  height: 37px;

  justify-content: space-between;
  /* flex-direction: row-reverse; */
  text-align: center;
}

.icon {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.track-orders::before {
  font-size: 24px;
  width: 100px;
}

.icon-wishlist::before {
  font-size: 24px;
}

.icon-wishlist {
  cursor: pointer;
}

.cart::before {
  font-size: 24px;
}

.user::before {
  font-size: 24px;
}

.text {
  margin-left: 8px;
  color: gray;
}

.divider {
  width: 1px;
  height: 24px;
  background-color: #ddd;
  margin-right: 20px;
}

.badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: green;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
}
.user-search-box {
  border-radius: 50px !important;
}
.user-search-box > span {
  height: 45px !important;
}
.user-search-box > .ant-input-group > .ant-input-affix-wrapper > input {
  height: 40px !important;
}

.user-search-box > span {
  border: transparent !important;
  border-radius: 50px !important;
}
.category-title {
  color: #ddd !important;
}

.user-search-box
  > .ant-input-wrapper
  > .ant-input-group-addon
  > div
  > .ant-select
  > .ant-select-selector
  > .ant-select-selection-item {
  color: white !important;
  font-size: 18px;
  background-color: transparent !important;
}
.user-search-box
  > .ant-input-wrapper
  > .ant-input-group-addon
  > .ant-input-search-button {
  background: transparent !important;
}

.user-search-box > .ant-input-wrapper > .ant-input-outlined:focus-within,
.user-search-box > .ant-input-wrapper > .ant-input-outlined:hover {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: #f1f1f1 !important;
}
.user-search-box > span > .ant-input-affix-wrapper {
  border: none !important;
  border-radius: 50px !important;
  background-color: transparent !important;
  /* width: 80px; */
}
.user-search-box > span > input {
  border: none !important;
  border-radius: 50px !important;
}
.user-search-box > span > input > span > button {
  border: none !important;
}
.user-search-box > span > input > span > button :focus {
  box-shadow: none !important;
  border-color: transparent !important;
}
.user-search-box > span {
  border: transparent !important;
  border-radius: 50px !important;
  background-color: #f1f1f1 !important;
}
.icon-item-first {
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* width: 200px; */
  width: 120px;

  justify-content: space-between;
  cursor: pointer;
}
.mobile-icon-item-first {
  display: flex;
  align-items: center;
  margin-right: 20px;
  /* width: 200px; */
  width: 120px;

  justify-content: space-between;
  cursor: pointer;
}
.user-search-box > .ant-input-wrapper > .ant-input-group-addon {
  border: none !important;
}
.header-top-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1000 !important;
}

.logo-container > img {
  width: 200px;
  cursor: pointer;
}

.customer-layout-main-container {
  /* padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px; */
  width: 100%;
}
/* .customer-layout-main-container {
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
} */
.customer-layout-body {
  height: 100%;
  min-height: 100vh;
  background-color: #e4f9e467;
  /* overflow: hidden; */
  /* background: url("../../assets/images/admin_login_background.png"); */
}

.search-menu-icon {
  display: none;
}

.common-drawer .ant-drawer-header {
  padding: 0px !important;
  background-color: var(--layout-header);
}

.common-drawer .ant-drawer-header .ant-drawer-header-title > button {
  width: 50px !important;
}

.common-drawer .ant-drawer-body {
  padding: 0px !important;
}
.mobile-menu-container {
  display: none;
}

.learn_more_container {
  padding: 20px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  color: white;
  flex: 1;
}

.learn_more_header {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  /* border-bottom: 3px solid #4caf50; */
  padding-bottom: 5px;
}
.learn_more_divider {
  width: 50%; /* Adjust width as needed */
  border-top: 3px solid #4caf50;
  min-width: 50% !important;
  margin: 0px !important;
}

.learn_more_list {
  list-style: none;
  padding: 10px;
}

.learn_more_item {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  text-wrap: wrap;
  margin-top: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.learn_more_item:hover {
  color: #fefefe !important;
}

.footer_description {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  text-wrap: wrap;
  margin-top: 40px;
  line-height: 25px;
}

.footer_container {
  /* background-color: #8bb388; */
  display: flex;
  flex-direction: row;
  /* background-image: url("../../assets/images/image.png"); */
  width: 100%;
  /* flex-wrap: wrap; */
}
.footer_main_container {
  background-image: url("../../assets/images/image.png");
  background-color: #8bb388;
}

.links_container {
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  color: white;
  flex: 1;
}

.links_header {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px !important;
  padding-bottom: 5px;
}

.learn_more_divider {
  display: inline-block;
  width: 30%; /* Adjust the width as needed */
  border-top: 3px solid #4caf50;
  min-width: 30% !important;
  margin: 0; /* Remove extra margin */
}

.links_list {
  list-style: none;
  padding: 10px;
  flex: 1;
}

.links_item {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  text-wrap: wrap;
  margin-top: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.links_item:hover {
  color: #fefefe !important;
}

.logo_with_description_container {
  width: 35%;
  padding: 30px;
}

.get_in_touch_container {
  padding: 20px;
  border-radius: 8px;
  width: 25%;
  font-family: "Montserrat", sans-serif;
  color: white;
}

.get_in_touch_header {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  /* border-bottom: 3px solid #4caf50; */
  padding-bottom: 5px;
}

.get_in_touch_content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.get_in_touch_item {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
  text-wrap: wrap;
  margin-top: 20px;
  display: flex;
  align-items: center;
  line-height: 25px;
}

.get_in_touch_item_mail {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-weight: 500;
  font-size: 14px;
  /* margin-bottom: 10px; */
  text-wrap: wrap;
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 25px;
  gap: 15px;
}

.get_in_touch_item svg {
  margin-right: 10px;
  font-size: 20px;
}

.sub_header_elements {
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.88) !important;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.sub_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 30px !important;
  background-color: green;
  width: 100%;
  height: 50px;
  align-items: center;
  margin-top: 2px;
}
.footer_logo {
}

.search-icon {
  display: none;
}
.search-icon,
.wishlist-icon,
.cart-icon,
.icon-user {
  font-size: 30px;
}

.avatar-header {
  background-color: #194e08;
  color: "white";
  cursor: pointer;
}
.mobile-menu-icon {
  cursor: pointer;
  display: none;
}

.mobile-view-search-box-container {
  display: none;
}

.mobile-profile-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.mobile-profile-container > img {
  width: 60px;
  border-radius: 50%;
}
.mobile-profile-container > h6 {
  font-weight: bold;
  font-size: Montserrat;
  font-size: 15px;
}
.menu-item-container {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.menu-item-container > li {
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  font-family: Montserrat;
}
.mobile-menu-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.social-media-image {
  width: 30px;
  cursor: pointer;
}
.mobile-profile-footer {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  gap: 20px;
  margin-top: 40px;
}
.mobile-profile-footer > h3 {
  font-size: 18px;
  font-weight: bold;
  font-family: Montserrat;
}
.mobile-profile-social-media-container {
  display: flex;
  gap: 10px;
}

.user-icon {
  background-color: 194e8;
  color: white;
  background-color: #194e08;
}

.quantity_input_cart_table > .ant-select-selector {
  border: 1px solid #529d4b !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
}
.cart-list-quantity-control > .ant-select-selector {
  border: 1px solid #529d4b !important;
}

.quantity_input_add_to_cart > .ant-select-selector {
  border: 1px solid #529d4b !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
  /* width: 65px !important; */
  width: 190px !important;

  height: 80% !important;
}

.quantity_input_add_to_cart > .ant-select-arrow {
  margin-top: -9px !important;
  font-weight: bold;
}
.whatsapp-logo-container {
  position: fixed;
  bottom: 10px;
  z-index: 1000;
  display: flex;
  /* justify-content: flex-end; */
  right: 40px;
  /* left: 10px; */
}

.whatsapp-logo {
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
  margin-right: 5px;
}
.home-search-box {
  width: 100% !important;
  /* background-color: #4f9f49 !important; */
  border-radius: 50px !important;
  border: 1px solid #4f9f49 !important;
  height: 45px !important;
  margin-top: -6px !important;
}
.home-search-box > .ant-select-selector {
  border-radius: 50px !important;
}
.home-search-box:focus {
  background-color: #4f9f49 !important;
}
.home-search-box > .ant-select-selector:focus {
  border: 1px solid #4f9f49 !important;
}
.search-box-container {
  width: 100% !important;
}
.search-box-containers {
  margin: 10px !important;
  width: 20% !important;
}
.link_and_learn_more_conatiner {
  display: flex;
  width: 33%;
  flex-direction: row;
  justify-content: space-between;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin-right: 20px;
  text-decoration: none;
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
}
.nav-menu li:hover {
  color: #529d4b;
}

.nav-menu li a {
  text-decoration: none;
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
}

.nav-menu li a:hover {
  color: #529d4b;
}

.contact {
  display: flex;
  align-items: center;
  color: #000000;
}

.phone-link {
  margin-left: 5px;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
}

.phone-link:hover {
  color: #529d4b;
}
.copy-right-container {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 5px;
}
.dropdown-container {
  position: relative;
  width: 150px;
  font-family: Arial, sans-serif;
}

.dropdown-header {
  padding: 10px;
  border: none !important;
  background-color: #008000;
  cursor: pointer;
  text-align: center;
  border-radius: 10px !important;
  color: #ffff !important;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.dropdown-header:hover {
  background-color: #008000;
  color: #ffff !important;
  border: 1px solid #fff;
}

.dropdown-list {
  list-style: none;
  margin: 3px;
  padding: 0;
  position: absolute;
  width: 220px;
  background-color: #fff;
  border: 1px solid #ccc;
  display: none;
  max-height: 500px !important;
  overflow-y: auto;
  border-radius: 10px;
}

.dropdown-container:hover .dropdown-list {
  display: block;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.dropdown-list li:hover {
  background-color: #e8f5e9; /* Light green background */
  color: #2e7d32; /* Dark green text */
}
