@media only screen and (min-width: 2560px) {
    .footer_description{
        font-size: 24px !important;
        line-height: 35px;

    }
    .learn_more_item{
        font-size: 24px !important;

    }
    .learn_more_header{
        font-size: 30px !important;
    }
    .links_item{
        font-size: 24px !important;
    }
    .links_header{
        font-size: 30px !important;
    }
    .get_in_touch_item{
        font-size: 24px !important;
    }
    .get_in_touch_header{
        font-size: 30px !important;
    }
}
