.my-profile {
  padding: 20px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.my-profile .ant-form-item-label > label {
  font-weight: bold;
  color: grey;
  font-family: "Montserrat-semibold";
}

.my-profile .ant-btn-primary {
  background-color: #4caf50 !important;
  border-color: #4caf50;
  width: 100px !important;
  font-family: "Montserrat-semibold";
}
.profile-breadcrumb {
  margin-bottom: 15px;
  padding-left: 15px;
}
.my-profile-edit-btn-container {
  display: flex;
  justify-content: flex-end;
}

.my-profile-form-fields {
  height: 200px !important;
}

.my-profile .ant-input {
  height: 45px;
  font-family: "Montserrat-semibold";
}

.my-profile-title {
  font-family: "Montserrat-semibold";
  font-size: 16px;
  margin-bottom: 30px !important;
}

.my-profile-form-container {
  margin-top: 20px;
}

.my-profile .ant-select-selector {
  height: 45px !important;
  font-family: "Montserrat-semibold";
}

.my-profile-edit-btn {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
  font-family: "Montserrat-semibold";
}

.my-profile-edit-btn .anticon {
  color: white;
}

.my-profile-edit-btn:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}

.my-profile-edit-btn:focus {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}

.location-info {
  color: #194e08;
  font-weight: bold;
}

.location-coordinates {
  color: #194e08;
}

.note-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.note-label {
  color: #194e08;
  font-weight: bold;
  width: 50px;
}

.note-text {
  color: #000000;
}

.map-loader {
  width: 30px !important;
  height: 30px !important;
  position: relative;
  transform: rotate(45deg);
}

.map-loader:before,
.map-loader:after {
  content: "";
  position: absolute !important;
  inset: 0;
  border-radius: 50% 60% 0 60%;
  background: #0000;
  background-image: radial-gradient(
    circle 5.2px at 50% 50%,
    #0000 94%,
    #ff0000
  );
}

.map-loader:after {
  animation: pulse-ytk0dhmd 2s infinite;
  transform: perspective(336px) translateZ(0px);
}
.cancel-tag {
  font-size: 14px !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
}

@keyframes pulse-ytk0dhmd {
  to {
    transform: perspective(336px) translateZ(168px);
    opacity: 0;
  }
}
