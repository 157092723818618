@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .profile-layout-main-container {
    background-color: transparent;
  }
  .profile-layout-body {
    display: flex;
    flex: 1 1;
    /* flex-wrap: wrap; */
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-items: center;
  }
  .warehouse-transfer-icon-container {
    font-size: 10px;
  }
  .warehouse-transfer-icon-container > p {
    min-width: auto !important;
    min-height: auto !important;
    width: 10px;
    height: 10px;
  }
  .warehouse-transfer-icon-main-container{
    gap:5px;
    font-size: 10px;
  }
  .sidebar-logo-sm {
    width: 95px !important;
    height: 95px !important;
    font-size: 81px !important;
  }

  .sidebar {
    width: auto;
    /* height: 100%; */
    background-color: white;
    color: #4caf50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    margin-top: 10px;
    box-shadow: 0px 12px 49px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    padding-left: 10px;
    padding-right: 10px;
    position: unset;
    top: 0px;
    z-index: 0;
  }
  .sidebar-logo {
    position: relative;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 50px;
  }

  .sidebar-logo .upload-icon-wrapper {
    width: 33px;
    height: 33px;
    top: 85px;
  }

  .sidebar-user-name {
    font-size: 15px;
  }

  .order-list-container {
    padding: 20px;
    /* max-width: 1200px; */
    width: auto;
    margin: auto;
    background-color: white;
  }

  .sidebar-menu-container {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .menu-item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 !important;
  }
  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 0px;
  }

  .sidebar-menu-icon {
    font-size: 19px !important;
    font-weight: bold !important;
    color: rgb(151, 149, 149) !important;
  }

  .order-list-heading {
    font-size: 17px;
  }

  .search-bar-container {
    /* display: none; */
  }
  .custom-search-bar {
    width: 200px;
    display: flex;
    justify-content: flex-end;
  }

  .custom-due-amount-box-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    border-radius: 5px;
    border: red 1px solid;
    /* padding-top: 12px; */
    /* margin-right: 45px; */
    padding: 11px;
    /* padding-top: 21px; */
    text-wrap: nowrap;
    align-items: center;
  }

  /* .custom-due-amount-box-sidebar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 30px;
            border-radius: 5px;
            border: red 1px solid;
            padding-top: 20px;
            position: absolute;
            top: 12px;
            left: 217px;
            text-wrap: nowrap;
            padding: 10px;
            padding-top: 20px;
        } */
  .custom-due-amount-box-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 22px;
    border-radius: 5px;
    border: red 1px solid;
    padding-top: 10px;
    /* margin-right: 45px; */
    display: none;
  }
  .custom-due-amount-box-text {
    color: red;
    font-size: 10px;
    font-weight: bolder;
  }
  .custom-due-pay-amount-box,
  .custom-due-pay-amount-box::before,
  .custom-due-pay-amount-box::after {
    font-size: 10px;
  }
  .order-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-menu-vertical .ant-menu-item {
    /* background-color: #4caf50; */
    /* width: 70px !important; */
    height: 43px !important;
    display: flex;
    align-items: center;
  }
  .ant-menu-item {
    color: gray !important;
    font-size: 17px;
  }

  /* .customer-layout-main-container {
        
            padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;} */

  .ant-menu-item-selected {
    color: #4caf50 !important;
  }

  .sidebar-item.selected {
    color: #4caf50 !important;
  }

  .profile_layout-content {
    flex: 1;
    padding: 20px;
    width: 100%;
  }

  .order-panel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 28px;
    flex-direction: column;
    align-items: baseline;
    gap: 25px;
  }
  .ant-collapse-expand-icon {
    display: none !important;
  }
  .shipping-address {
    margin-bottom: 20px;
    font-size: 14px;
    width: 80%;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }

  .order-listing-heading {
    color: #4caf50;
    font-size: 17px;
    /* font-weight: bold; */
  }

  .order-paid-heading {
    font-size: 17px;
  }
  .order-unpaid-heading {
    font-size: 17px;
  }
  .order-list-item {
    /* font-size: 30px!important; */
    display: flex;
    flex-direction: row;
    gap: 20px !important;
  }
  .order-list-item-image-sm {
    width: 60px !important;
    height: 50px !important;
  }
  .return-order-remark-container {
    flex-direction: column !important;
  }
  .return-order-image-container {
    display: flex;
    justify-content: space-between !important;
    width: 100%;
  }
  .return-order-container {
    flex-direction: column !important;
  }
  .product-details {
    display: flex;
    gap: 29px;
    flex-direction: column;
  }
  .order-list-item-meta-title {
    font-size: 13px !important;
  }
  .item-price {
    font-size: 13px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px;
    display: flex !important;
    flex-direction: column !important;
    gap: 0px !important;
  }
  .order-summary {
    margin-top: 20px;
    font-weight: bold;
    font-size: 14px;
  }
  .order-panel-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 13px;
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
  }

  .ant-collapse-item {
    border-bottom: 5px solid #d9d9d9 !important;
  }

  .sidebar-logo .upload-icon {
    color: white;
    font-size: 15px;
  }

  .sidebar-logo-container {
    display: flex;
    /* flex-direction: row; */
    gap: 10px;
  }

  .sidebar-due-amount-container {
    display: flex;
    justify-content: flex-end;
    width: 584px;
    margin-top: -15px;
  }

  .order_list_loading_container {
    flex-direction: column;
    gap: 10px;
  }
}
