.farmer-product-list-container {
  padding: 20px;
  background-color: #f9f9f9;
}

.farmer-product-category {
  margin-bottom: 40px;
}

.farmer-category-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.farmer-product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.farmer-product-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 230px;
  text-align: center;
  transition: transform 0.2s;
  min-height: 350px;
  max-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

/* .farmer-product-card:hover {
  transform: scale(1.05);
} */
.farmer-product-card .icon-col {
  display: flex;
  position: relative;
}

.farmer-product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 15px;
  position: relative;
}

.farmer-product-details {
  text-align: left;
}

.farmer-product-name {
  text-align: center;
  font-size: 14px;
}
.farmer-product-price,
.farmer-product-delivery {
  font-size: 1em;
  margin: 5px 0;
}
.farmer-category-icon {
  width: 80px;
}
.farmer-category-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}
.icon-col {
  display: none;
}
.icon-row {
  height: 20px !important;
}
.farmer-about-main-container {
  width: 500px;
}
.star_icon {
  color: #194e08;
  padding-left: 3px;
  padding-top: 4px;
}
.farmer_review_card_container {
  padding: 20px;
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  min-height: 280px;
}
.farmer_review_card {
  background-color: #fff;
  border: 1px solid;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: 100%;
  text-align: center;
}

.farmer_review_card_container:hover {
  transform: scale(1.05);
}
.farmer_review_card_container:hover .icon-col {
  display: flex;
}
.review_thumb_icon {
  border: 1px solid;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 46%;
  background: white;
  white-space: nowrap;
  transition: transform 0.3s ease;
}
.farmer-bulk-update-table {
  margin: 25px 0;
}
