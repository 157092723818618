@media only screen and (min-width: 0px) and (max-width: 767px) {
  .address_card {
    width: auto;
  }
  .form_container {
    width: 270px;
  }
  .address_title {
    font-family: "Montserrat-semibold";
    font-size: 15px;
    padding-left: 15px;
  }
  .add_address_button {
    width: 120px;
    padding: 10px;
    font-size: 12px;
  }
  .no-address-img {
    width: 200px;
  }
  .no-address-container,
  .no-address-found-text {
    margin-top: 0px;
  }
}
