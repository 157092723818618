.page-even-row td {
  background-color: #99B080 !important;
}

.page-odd-row td {
  background-color: #E1F0DA !important;
}

.page-access-even-row td {
  background-color: #FAEED1 !important;
}

.page-access-odd-row td {
  background-color: #D7E5CA !important;
}
