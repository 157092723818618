.toggle-container {
  /* margin-top: -150px; */
  display: flex;
  border: 2px solid #4caf50;
  border-radius: 25px;
  overflow: hidden;
  width: 300px;
  height: 40px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.toggle-container input[type="radio"] {
  display: none;
}

.toggle-button {
  font-family: "Montserrat";

  font-weight: 500;
  flex: 1;
  text-align: center;
  line-height: 43px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
  z-index: 1;
  color: #4caf50;
  background-color: white;
  position: relative;
}

.toggle-button.active {
  color: white;
  background-color: #4caf50;
}

.toggle-button:first-of-type {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.toggle-button:last-of-type {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.toggle-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: #4caf50;
  transition: left 0.3s;
  z-index: 0;
  border-radius: 25px;
}

#login:checked ~ .toggle-container::before {
  left: 0;
}

#register:checked ~ .toggle-container::before {
  left: 50%;
}

.centered-container {
  display: flex;
  padding: 30px;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/images/customer_login_background.jpg") no-repeat;
  background-size: cover;
  min-height: 100vh;
  /* height: 100% !important; */
}
.centered-container-admin {
  display: flex;
  padding: 30px;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/images/customer_login_background.jpg") no-repeat; */
  background: url("../../assets/images/admin_login_background.png");
  background-size: cover;
  height: 100vh;
  /* height: 100% !important; */
}
.component-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login form css */

.login-form-container {
  margin-top: 15px !important;
  max-width: 400px;
  min-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffffbd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.resetpassword {
  font-size: 20px;
  justify-content: center;
  display: flex;
  font-weight: 700;
}

.login-message {
  margin-bottom: 30px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: small;
  text-align: center;
  text-wrap: pretty;
  color: #666;
  margin-bottom: 20px;
}
.processing {
  margin-bottom: 30px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: large;
  text-align: center;
  text-wrap: pretty;
  color: black;
  margin-bottom: 20px;
}
.failed {
  margin-bottom: 30px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: large;
  text-align: center;
  text-wrap: pretty;
  color: black;
  margin-bottom: 10px;
}

.otp-message {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: small;
  text-align: center;
  text-wrap: pretty;
  color: #030303;
  margin-top: 10px;
}
.otp-message-resend {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: small;
  text-align: center;
  text-wrap: pretty;
  color: #030303;
  margin-top: 10px;
  cursor: pointer;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  color: #333;
}
.login-form-customer {
  width: 101%;
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}
.form-group .ant-input,
.form-group .ant-input-password {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.forgot-password {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-right: 20px;
  text-align: right;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #7e7e7e;
  text-decoration: none;
  font-size: 14px;
}

.login-button {
  font-family: "Montserrat";

  font-weight: 500 !important;
  width: 96%;
  height: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.login-button:hover {
  background-color: #45a049 !important;
}

/* Register form */

.register-form-container {
  margin-top: 15px !important;
  max-width: 400px;
  width: 385px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffffbd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.register-message {
  font-size: small;
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.register-form {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-right: 15px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group .ant-input-password {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.register-button {
  font-family: "Montserrat";
  font-weight: 500;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #45a049 !important;
}
.send-otp-button {
  font-family: "Montserrat";
  font-weight: 500;
  width: 100% !important;
  height: 100%;
  background-color: #4caf50;
  color: white;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-otp-button:hover {
  background-color: #45a049 !important;
}
.already-have-account {
  display: flex;
  justify-content: center;
  margin-top: 10px !important;
  font-family: "Montserrat";
  /* font-weight: 500; */
  text-align: right;
  /* margin-bottom: 20px; */
}
.login-option {
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: right;
  margin-bottom: 5px;
}
.forgot_pwd {
  margin-top: 10px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: right;
  margin-bottom: 5px;
}
.forgot_pwd a {
  color: #1f1d1d;
  text-decoration: underline;
  /* text-decoration: none; */
}

.login-option a {
  color: #1f1d1d;
  text-decoration: underline;
  /* text-decoration: none; */
}
.already-have-account a {
  color: #1f1d1d;
  /* text-decoration: underline; */
  /* font-weight: bold; */
}
.login-otp-input .ant-otp {
  width: 100% !important;
}
.register-form-item {
  height: 40px !important;
}
.input-centered-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  & .ant-input-number-wrapper {
    & .ant-input-number {
      height: 40px !important;
      & .ant-input-number-input {
        height: 40px !important;
      }
    }
  }
}
.input-centered-container {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  & .ant-input-wrapper {
    & .ant-input {
      height: 40px !important;
      & .ant-input-input {
        height: 40px !important;
      }
    }
  }
}

.ant-input-number-group-addon {
  padding: 0 !important;
  width: auto !important;
  height: 40px !important;
  background-color: white !important;
}
.ant-input-group-addon {
  padding: 0 !important;
  min-width: 40px !important;
  height: 40px !important;
  background-color: white !important;
}
.verified-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
}
.submit-otp {
  width: 50% !important;
  margin: 15px 0 10px 0 !important;
  align-self: center !important;
  padding: 3px !important;
}
