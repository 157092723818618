@media only screen and (min-width: 0px) and (max-width: 767px) {
  .product_detail_container {
    padding: 0 10px;
    width: 96%;
    height: 100%;
  }
  .image_card {
    width: 100%;
  }
  .product_image_container {
    width: 100% !important;
    overflow: hidden;
    display: flex;
    height: 50vh;
    justify-content: center;
    align-items: center;
  }

  .product_image_mobile_container {
    width: 90% !important;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-card-body {
    padding: 0 !important;
  }
  /* .thumbnail_image {
    width: 15vw;
    height: 15vh;
  } */
  .details_card {
    margin-top: 10px !important;
  }
  .social_icons {
    display: flex;
    position: absolute;
    flex-direction: row;
    margin-top: -15px;
    top: 0;
    z-index: auto !important;
  }
  /* .share_desktop { */
  /* display: none !important; */
  /* } */
  .category_text {
    font-size: clamp(1rem, 2.5vw, 1.4rem);
  }
  .title_text {
    font-size: clamp(1.5rem, 7vw, 2.4rem);
  }

  .product_details_related_product_heading {
    font-size: clamp(1.2rem, 7vw, 2rem);
    margin-bottom: 20px;
  }
  .description_text {
    font-size: clamp(0.7rem, 2.5vw, 1.1rem);
  }
  .price_text {
    font-size: 27px;
  }
  .delivery_text {
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
  }
  .price_section .original {
    padding: 0px 10px 0px 0px !important;
  }
  .delivery_date_text {
    display: block;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }

  .quantity_label {
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
  }
  .ant-radio-button-wrapper-checked span {
    font-size: clamp(0.8rem, 2.5vw, 1.2rem);
    padding: 0;
  }
  .radio_button_wrapper .value_button {
    width: clamp(50px, 10vw, 110px) !important;
    /* gap: 5px !important; */
  }
  /* 
  .ant-radio-button-wrapper span {
    font-size: clamp(0.7rem, 2.5vw, 1rem);
    padding: 0;
  } */

  .ant-radio-button-wrapper span {
    font-size: 12px !important;
    padding: 0;
  }
  .ant-radio-button-wrapper {
    width: clamp(55px, 100%, 100px);
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0 !important;
  }
  .add_to_cart_button {
    width: clamp(80px, 100%, 135px) !important;
  }
  .add_to_cart {
    gap: 10px;
  }
  .product_detail_count_button {
    width: clamp(15px, 100%, 30px);
    padding: 0;
    text-align: center;
  }
  .cart_count {
    padding: 0;
    width: clamp(15px, 100%, 15px) !important;
    text-align: center !important;
  }
  .quantity_input {
    width: clamp(45px, 100%, 130px);
  }
  .thumbnail_image {
    width: 50px;
    height: 50px;
  }
  .product_col {
    margin-bottom: 30px !important;
  }
  .product-detail {
    padding: 10px;
    width: auto !important;
  }

  .product_image_container > .product_image {
    /* margin-right: 18px; */
    object-fit: cover !important;
  }
  .product_quantity_control {
    flex-direction: row;
    gap: 10px !important;
    display: flex;
    flex-wrap: wrap;
  }
  .quantity-select {
    margin-right: 5px !important;
    width: 80px;
  }
  .product_dynamic_card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .product_dynamic_card-image-container {
    width: 60px;
    height: 60px;
  }
  .product_image_main_container {
    flex-direction: column !important;
    gap: 30px !important;
  }
  .thumbnail_container {
    width: 100% !important;
    flex-direction: row !important;
    height: 75px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-top: 20px !important;
  }
  .why_choose_myh_point {
    font-size: 12px !important;
  }
  .why_choose_myh_point_icon {
    font-size: 20px !important;
  }
  .description_content {
    font-size: 12px !important;
  }
  .why_choose_myh {
    margin: 10px !important;
  }
  .related_product_container {
    margin: 10px 0 !important;
  }
  .about_product {
    margin: 10px !important;
  }
  .left_arrow {
    left: 2%;
    color: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 2px;
    background-color: #ebebeb;
    opacity: 0.7;
  }

  .right_arrow {
    right: 2%;
    color: black;
    border-radius: 50%;
    padding: 2px;
    background-color: #ebebeb;
    opacity: 0.7;
  }
  .product_image_out_of_stock_container {
    top: 40% !important;
    left: 50% !important;
  }
  .hide_screen {
    display: none !important;
  }
  .rounded-product-detail-image {
    width: 60px !important;
    /* height: 60px !important; */
    /* border-radius: 50% !important; */
    object-fit: cover;
  }
  .product_image_out_of_stock_container {
    padding: 5px 10px;
    font-size: 10px !important;
  }
  .product_description_title_container {
    width: 100% !important;
  }
  .product_details_related_product_heading,
  .product_details_why_myh {
    font-size: 24px;
  }
}
