.product-card {
  background-color: #fff;
  /* background-color: orange; */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-card img {
  /* width: 250px;
  height: 200px; */
  object-fit: cover;
  aspect-ratio: calc(16 / 16);
}

.product-image-instack {
  max-width: 100%;
  border-radius: 8px;
  font-family: "Montserrat-semibold";
  -webkit-border-radius: 8px; 
}
.product-image-out-stack {
  max-width: 100%;
  border-radius: 8px;
  font-family: "Montserrat-semibold";
  -webkit-border-radius: 8px;
  color: rgba(240, 248, 255, 0.307);
  opacity: 0.6;
}

.out-of-stock-label {
  position: absolute;
  margin-top: -100%;
  background-color: rgba(183, 10, 10, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  /* z-index: 1; */
}

.product_variant_back {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.product-card .heart {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 1em;
  cursor: pointer;
  background: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.product_discount {
  top: 0px !important;
  left: 0px !important;
  position: absolute;
  display: flex;
  align-items: center;
}

.discount {
  /* font-size: 1em; */
  cursor: pointer;
  border: none;
  border-radius: 10px 0px 10px 0px;
  padding: 4px 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: #cb1212;
  color: white;
}
.discount_txt {
  font-weight: bold;
  font-style: italic;
  font-size: 1em;
}

.discount_off {
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
  margin-left: 6px;
}
.product-card .category {
  color: var(--product-cart-button);
  font-weight: bold;
  margin-top: 3px;
}

.product-card .title {
  font-size: 1.2em;
  font-weight: 600;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds '...' when text overflows */
  /* max-width: 200px; */
  /* margin: 8px 0; */
}
.product-card .sub_title {
  font-size: 0.6em;
  font-weight: 500;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; 
  text-overflow: ellipsis; /* Adds '...' when text overflows */
  /* max-width: 150px;  */
}

.product-card .price {
  font-size: 1em;
  color: #777;
}

.price-whole-part {
  font-size: 20px;
}

.discount-price {
  font-size: 15px;
  margin-left: 20px;
  color: #878787;
  font-weight: 500;
}

.product-card .delivery {
  margin-top: 8px;
  color: #777;
  font-size: 0.9em;
}

.product-card .quantity-control,
.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
  border-style: solid;
  border-color: var(--product-cart-button);
  border-radius: 13px;
  border-width: 1px;
  justify-content: space-between;
}

.product-card .quantity-control button,
.quantity-control button {
  background-color: var(--product-cart-button);
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 50%;
  width: 26px;
  /* margin: 0 5px; */
}

.product-card .add-to-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 14px;
}

.product-card .add-to-cart button {
  background-color: var(--product-cart-button);
  color: white;
  border: none;
  /* padding: 8px 16px; */
  cursor: pointer;
  border-radius: 4px;
}

.product-card .add-to-cart button:hover {
  background-color: var(--product-cart-button);
}
.quantity-control-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border: 1.5px solid var(--product-cart-button);
  border-radius: 12px;
  /* margin-top: 12px; */
}

.quantity-control-container > span {
  flex: 1;
  text-align: center;
  font-weight: 600;
}

.quantity-control-container-change {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border: 1.5px solid var(--product-cart-button);
  border-radius: 12px;
  /* margin-top: 12px; */
}

.quantity-control-container-change > span {
  flex: 1;
  text-align: center;
  font-weight: 500;
}

.product-card-action-button {
  width: 25px;
}
.product-card-cart-action-button {
  font-size: 0.7em;
  border: none;
  background-color: rgb(229 255 227);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-button {
  border: none !important;
  /* width: 100px !important; */
  /* border-radius: 20px !important; */
  font-size: 20px !important;
  color: #45a049 !important;
  font-family: Montserrat-semibold;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cart-button:disabled {
  cursor: not-allowed;
}
.cart-button:active {
  transform: scale(0.95);
  transition-duration: 0.5s;
  background-color: #4caf4fdf;
}
.product_variant_list {
  width: 100% !important;
  border: 1.5px solid var(--product-cart-button);
  border-radius: 6px;
}
.product_variant_list > .ant-select-selector {
  width: 100% !important;
  border: none !important;
}
.product-skeleton-product-card {
  width: 280px !important;
  height: 400px !important;
}
.product-cart-icon {
  cursor: pointer;
}
.quantity-control-button {
  width: 100px !important;
  height: 25px !important;
}
.product-amount-tag-format {
  font-size: 10px;
  color: gray;
}
.out-of-stock-label-conatiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
}
/* .product_image_out_of_stock {
  position: absolute;
  bottom: 50%;
} */

/* .product_image_out_of_stock_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;

} */

.out-of-stock-label-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
}

.arrow_button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.left_arrow {
  left: 4%;
  color: rgb(0, 0, 0);
  border-radius: 50%;
  padding: 5px;
  background-color: #ebebeb;
  opacity: 0.7;
  cursor: pointer;
}

.right_arrow {
  right: 4%;
  color: black;
  border-radius: 50%;
  padding: 5px;
  background-color: #ebebeb;
  opacity: 0.7;
  cursor: pointer;
}

.cart_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more_divider {
  width: 20%;
  border-top: 1.5px solid #c5c5c5;
  min-width: 20% !important;
  margin: 0px !important;
}
.divider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 50%; */
}

.CartBtn:disabled {
  background-color: #4caf4fce;
  pointer-events: none;
  cursor: not-allowed;
}
.CartBtn:disabled:hover {
  transform: none !important;
  transition: none !important;
  color: inherit !important;
  cursor: not-allowed;
}
.CartBtn {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.5s;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
  position: relative;
  border: 1.5px solid var(--product-cart-button);
  background-color: var(--product-cart-button);
}
.product_add_to_cart_loading {
  height: 40px !important;
  width: 100% !important;
}
.IconContainer {
  position: absolute;
  left: -50px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 10;
  transition-duration: 0.5s;
}

/* .icon {
  border-radius: 1px;
  color: var(--product-cart-button) !important;
} */

.text {
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition-duration: 0.5s;
  font-size: 1.04em;
  font-weight: 600;
}

.CartBtn:hover .IconContainer {
  transform: translateX(80px);
  border-radius: 40px;
  transition-duration: 0.5s;
}

.CartBtn:hover .text {
  transform: translate(10px, 0px);
  transition-duration: 0.5s;
}

.CartBtn:active {
  transform: scale(0.95);
  transition-duration: 0.5s;
}

/* Out-of-stock badge - Centered */
.product_image_out_of_stock {
  position: absolute;
  /* top: 34%; */
  top: 28%;
  left: 45%;
  transform: translate(-50%, -50%);
  background-color: rgba(183, 10, 10, 0.9);
  color: white;
  padding: 12px 18px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 10;
  text-transform: uppercase;
}

.product_image_out_of_stock_container {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(183, 10, 10, 0.8);
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  transform: rotate(-10deg);
  z-index: 10;
}
/* Out-of-stock overlay inside slider */
.product_image_out_of_stock_overlay {
  position: absolute;
  top: 30%;
  left: 35%;
  transform: translate(-50%, -50%);
  background-color: rgba(183, 10, 10, 0.8);
  color: white;
  padding: 12px 18px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 10;
  text-transform: uppercase;
  white-space: nowrap;
}

/* Ensure the image wrapper allows absolute positioning */
.image_wrapper {
  position: relative;
}
