@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .order-placed-text h1 {
    font-size: 19px;
    color: #2e7d32;
    font-family: "Montserrat-semibold";
  }
  .order-placed-text p {
    font-size: 15px;
    color: #555;
    font-family: "Montserrat-semibold";
  }

  .order-cancelled-text h1 {
    font-size: 19px;
    color: #f81128 !important;
    font-family: "Montserrat-semibold";
  }
  .order-cancelled-text p {
    font-size: 15px;
    color: #555;
    font-family: "Montserrat-semibold";
  }
  .order-placed-actions .continue-shopping-button {
    padding: 10px 20px;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-family: "Montserrat-semibold";
  }
  .vehicle-back {
    position: absolute;
    top: -315px;
    width: 90px;
    height: 200px;
    margin: 0 auto;
  }
  .truck-image {
    width: 500px;
  }
  .driver-name {
    top: -84px;
    left: 170px;
  }
  .driver-name > h4 {
    font-size: 12px;
  }
}
