@media only screen and (min-width: 0px) and (max-width: 767px) {
  .cart-table {
    display: none;
  }

  .customer-cart-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .customer-cart-page-sub-section-container {
    align-items: center;
    justify-content: center;
    position: static;
    flex-direction: column-reverse;
    margin-top: 50px;
    gap: 20px;
    width: 100%;
    margin-bottom: 50px;
  }

  .cart-checkout-btn {
    font-size: 14px;
  }

  .customer-cart-pagetable-inner-container {
    max-height: none;
    width: 100%;
  }
  

  .customer-cart-page-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .cart-item-mobile-view-container {
    display: block;
  }

  .mobile-cart-image {
    width: 70px;
    height: 80px;
    object-fit: contain;
  }

  .quantity_input_add_to_cart > .ant-select-selector {
    border: 1px solid #529d4b !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    height: 30px !important;
  }

  .quantity_input_add_mobile > .ant-select-selector {
    border: 1px solid #529d4b !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
    height: 30px !important;
    width: 70px !important;
  }
  .mobile-cart-item-card-wrapper {
    flex-direction: column !important;
  }
  .cart-item-footer {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-top: 10px !important;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .mobile-cart-item-qty-change {
    width: 100%;
  }

  .mobile-cart-main-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  }
  .cart-item-main-container {
    min-height: auto;
  }
}
