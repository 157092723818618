/* Global Styles */
body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: fit-content;
  /* minheight: 100%; */
  font-family: Arial, sans-serif;
  /* background-color: #e5ffe3; */
  /* #e5ffe336; */
}

.profile-layout-main-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  min-height: 100vh;
  background: url("../../assets/images/admin_login_background.png");
}

.profile-layout-body {
  display: flex;
  flex: 1;
  padding: 30px;
  /* background-color: var(--profile-layout-color); */
  background: url("../../assets/images/admin_login_background.png");
  height: 100vh;
}

.profile_layout-content {
  flex: 1;
  padding: 10px;
}

h1,
h2,
h3,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Sidebar Styles */
.header_bar {
  background-color: white;
  color: #4caf50;
  box-shadow: 1px 1px 13px 3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin: 0 18px 10px 18px;
}

.sidebar-logo {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  /* margin-bottom: 0px; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  font-family: "Montserrat-semibold";
}

.sidebar-logo .upload-icon-wrapper {
  position: absolute;
  top: 50px;
  right: 40%;
  background: rgb(8, 97, 8);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar-logo .upload-icon {
  color: white;
  font-size: 16px;
  font-family: "Montserrat-semibold";
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar-item {
  padding: 15px 20px;
  cursor: pointer;
  text-align: left;
  transition: background 0.3s;
  display: flex;
  align-items: center;
}

.ant-menu-vertical .ant-menu-item {
  margin-bottom: 16px; /* Add gap between menu items */
}
.ant-menu-vertical {
  border-inline-end: 0px solid rgba(0, 0, 0, 0) !important;
  /* Remove right border */
}

.sidebar-item:hover {
  background-color: #e0f7fa;
}

.sidebar-item.selected {
  color: #4caf50 !important;
}

.sidebar-item .anticon {
  margin-right: 10px;
}

.ant-menu-item-selected {
  color: #4caf50 !important;
}

.product-list-routes {
  color: #4caf50 !important;
}

.app-routes-heading-container {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  /* background-color: var(--profile-layout-color); */
}

.app-routes-heading {
  color: gray;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 30px;
  font-family: "Montserrat-semibold" !important;
}
.customer-wallet-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-due-amount-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  border-radius: 5px;
  min-width: 80px;
  border: red 1px solid;
  padding: 7px;
  margin-right: 25px;
  cursor: pointer;
}
.custom-due-pay-amount-box {
  opacity: 1;
  outline: 0;
  cursor: pointer;
  color: red;
  font-size: 12px;
  font-weight: bolder;
  font-family: "Montserrat-semibold";
  position: relative;
  text-align: center;
  display: inline-block;
  /* &:hover {
    &:after {
      opacity: 1;
      transform: translateY(0) rotateX(0);
    }

    &:before {
      opacity: 0;
      transform: translateY(50%) rotateX(90deg);
    }
  } */

  &:after {
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 12px;
    font-weight: bolder;
    font-family: "Montserrat-semibold";
    color: white;
    display: block;
    padding: 7px;
    min-width: 80px;
    /* margin-right: 25px; */
    border-radius: 5px;
    transition: 0.5s;
    position: absolute;
    background: #4caf50;
    border: green 1px solid;
    content: attr(data-back);
    transform: translateY(-50%) rotateX(90deg);
  }

  &:before {
    top: 0;
    left: 0;
    opacity: 1;
    color: red;
    font-size: 12px;
    display: block;
    min-width: 80px;
    /* margin-right: 25px; */
    border-radius: 5px;
    border: red 1px solid;
    padding: 7px;
    transition: 0.5s;
    position: relative;
    content: attr(data-front);
    transform: translateY(0) rotateX(0);
  }
}

.custom-due-amount-box-text {
  color: red;
  font-size: 12px;
  font-weight: bolder;
  font-family: "Montserrat-semibold";
}

.ant-menu-item {
  font-family: "Montserrat-semibold";
}

.custom-due-amount-box-container {
  display: flex;
  justify-content: end;
  width: auto;
  margin-bottom: 40px;
}

.custom-due-amount-box-sm {
  display: none;
}
.sidebar-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 50px;
}
.profile-label-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
}

.profile-icon-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.sidebar-wrapper {
  position: relative;
  height: 100vh;
}
.sidebar-menu-container {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.menu-item {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
}
.customer_delete_confirmation_modal_text {
  width: 100%;
}

.label-text {
  font-size: 14px;
  font-family: "Montserrat-semibold";
}

.input-number {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
}

.payment-method-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}

.radio-container {
  display: block;
  margin-right: 10px;
}

.profile_username {
  font-family: "Montserrat-semibold";
  color: #008000;
  font-weight: 700;
  font-size: larger;
}
.profile_username_heading {
  font-size: larger;
}

.center-align {
  justify-content: center;
  display: flex;
  align-items: center;
}
.center-align-buttons {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.center-align_grid {
  justify-content: center;
  display: grid;
  align-items: center;
}

.customer-order-list .ant-collapse-header {
  padding: 8px 16px;
  font-size: 14px;
}

.order-panel-header > div {
  font-size: 14px;
}

.customer-order-list .ant-collapse-content {
  font-size: 14px;
  padding: 8px 16px;
  max-height: 150px;
  overflow-y: auto;
}

.customer-order-list .order-panel {
  margin-bottom: 5px;
}

.customer-order-list .ant-collapse-item {
  border-bottom: none;
}

.profile-layout-footer-main-container {
  background-color: red;
  position: sticky;
  bottom: 0;
  padding: 10px;
  z-index: 3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.profile-layout-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile-layout-footer-logo {
  background-color: white;
  padding: 10px;
  margin-top: -9px;
  border-radius: 50%;
}
.profile-layout-footer-logo > .profile-layout-icon-active {
  background-color: #008000;
  padding: 4px;
  border-radius: 50%;
}

.customer-layout-profile-footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 98%;
  background-color: white;
  padding: 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: none;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.customer-layout-profile-footer {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  gap: 10px;
  width: 50px
}

.customer-layout-profile-footer-icon {
  color: hsl(120, 100%, 25%);
  font-size: 20px;
  transition:
    transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

.customer-layout-profile-footer:hover .customer-layout-profile-footer-icon {
  transform: scale(1.1);
}

.customer-layout-profile-footer-icon-active {
  background-color: #008000;
  padding: 12px;
  border-radius: 20px;
}
.customer-layout-profile-footer > span {
  display: block;
  font-size: 10px;
}

.customer-layout-profile-footer-icon-active
  .customer-layout-profile-footer-icon {
  color: white;
}
.customer-layout-profile-footer-icon-active > span {
  color: white;
  font-weight: bold;
}
