@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .product-list-body-container {
    /* justify-content: space-between; */
    grid-template-columns: repeat(auto-fill, minmax(16vw, 1fr)) !important;
    padding: 10px;
    justify-items: center;

  }
  .product-list-container {
    gap: 20px;
  }
  .category-list-mobile-container {
    display: none;
  }
  .product-card {
    width: 16vw !important;
    padding: 15px;
  }

  .product-card img {
    width: 100%;
    object-fit: cover;
    /* height: 170px; */
  }

  .product-card .category {
    font-size: 0.9em;
  }

  .product-card .title {
    font-size: 1.2em;
    margin: 8px 0;
    height: 50px;
  }

  .product-card .price {
    font-size: 0.9em;
    font-weight: bold;
  }

  .product-card .delivery {
    margin-top: 8px;
    font-size: 0.9em;
  }

  .product-card .quantity-control,
  .quantity-control {
    gap: 10px;
  }

  .product-card .quantity-control button,
  .quantity-control button {
    padding: 4px 8px;
  }

  .product-card .add-to-cart {
    margin-top: 3px;
    font-size: 14px;
  }

  .product-card .add-to-cart button {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .quantity-control-container {
    /* margin-top: 12px;
    min-height: 32px; */
  }

  .product-card-action-button {
    width: 25px;
  }
  .product-card-cart-action-button {
    font-size: 1em;
  }
  .add-to-cart > text {
    font-size: 13px;
  }

  .product-skeleton-card {
    height: 400px;
  }
  .testimonial-content {
    justify-content: space-between;
  }
  .testimonial-card{
    min-height: 270px !important;
  }
}
