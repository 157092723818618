.wishlist_product_row {
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: start;
}
.empty-wishlist-text {
  padding: 15px;
}

.wishlist_product_col {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 3px !important;
  margin-bottom: 30px !important;
}
.wishlist_header {
  margin-top: 15px;
}
.wishlist_heading {
  margin-bottom: 15px;
  font-family: "Montserrat";
  font-weight: 600;
  padding-left: 15px;
  font-size: 15px;
}
.wishlist_container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.wishlist-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.empty-wishlist-container {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-wishlist-img {
  height: 250px;
  width: 250px;
  object-fit: contain;
}
