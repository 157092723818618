/* Apply Montserrat Font Family */
@font-face {
  font-family: "Montserrat";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
}
@font-face {
  font-family: "Montserrat-semibold";
  src: url("../assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-semibold";
  src: url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",           
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --layout-header: #194e08;
  --profile-layout-color: rgb(248, 245, 245);
  font-family: Montserrat;
  --product-cart-button: #4caf50;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}
/* 
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav li {
    display: inline;
    margin-right: 10px;
  }
  
  nav a {
    text-decoration: none;
    color: #61dafb;
  }
  
  nav a:hover {
    text-decoration: underline;
  } */
@import url("layout/layout.css");
@import url("login/login.css");
@import url("form.css");
@import url("product_demand/product_demand.css");
@import url("farmer_order/farmer_order.css");
@import url("farmer/farmer.css");
@import url("layout/customer_layout.css");
@import url("layout/profile_layout.css");
@import url("common/product_card.css");
@import url("screen/access_rights.css");
@import url("screen/stock_keeping_unit.css");
@import url("screen/cluster.css");
@import url("screen/customer_list.css");
@import url("./common/placeholder.css");
@import url("./common/discount.css");
@import url("./common/alert_text.css");
@import url("./screen/admin.css");

/* former order */

@import url("screen_1024_1439/screen_1024_1439_farmer_order_list.css");

/* Customer modules */
@import url("screen_768_1023/screen_768_1023_product_list.css");
/* @import url("screen_767/screen_767_products.css"); */
@import url("screen_768_1023/screen_768_1023_products.css");
@import url("screen_1024_1439/screen_1024_1439_products.css");
/* @import url("screen_2000_2559/screen_2000_2559_products.css"); */
@import url("screen_2560_above/screen_2560_products.css");

@import url("screen_767/screen_767_login.css");
@import url("screen_768_1023/screen_768_1023_login.css");
@import url("screen_1024_1439/screen_1024-1439_login.css");
@import url("screen_1440_1999/screen_1440_1999_login.css");
@import url("screen_2000_2559/screen_2000_2559_login.css");
@import url("screen_2560_above/screen_2560_login.css");
@import url("screen/customer_login.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("screen/manage_address.css");
@import url("screen_767/screen_767_manage_address.css");
@import url("screen_768_1023/screen_768_1023_manage_address.css");
@import url("screen_1024_1439/screen_1024_1439_manage_address.css");
@import url("screen_1440_1999/screen_1440_1999_manage_address.css");
@import url("screen_2000_2559/screen_2000_2559_manage_address.css");
@import url("screen_2560_above/screen_2560_manage_address.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

@import url("screen/product_list.css");
@import url("screen_767/screen_767_product_list.css");
@import url("screen_768_1023/screen_768_1023_product_list.css");
@import url("screen_1024_1439/screen_1024_1439_product_list.css");
@import url("screen_1440_1999/screen_1440_1999_product_list.css");
@import url("screen_2000_2559/screen_2000_2559_product_list.css");
@import url("screen_2560_above/screen_2560_product_list.css");

/* Product Details */
@import url("screen/product_detail.css");
@import url("screen_767/screen_767_product_details.css");
@import url("screen_768_1023/screen_768_1023_product_details.css");
@import url("screen_1024_1439/screen_1024_1439_product_details.css");
@import url("screen_1440_1999/screen_1440_1999_product_details.css");
@import url("screen_2000_2559/screen_2000_2559_product_details.css");
@import url("screen_2560_above/screen_2560_product_details.css");

@import url("./screen/cart.css");
/* Wishlist */
@import url("screen/wishlist.css");

/* Checkout */
@import url("screen/checkout.css");
@import url("screen_767/screen_767_checkout.css");

@import url("screen/my_order_list.css");
@import url("screen_767/screen_767_my_order_list.css");
@import url("screen_768_1023/screen_768_1023_my_order_list.css");
@import url("screen_1024_1439/screen_1024_1439_my_order_list.css");
@import url("screen_1440_1999/screen_1440_1999_my_order_list.css");
@import url("screen_2000_2559/screen_2000_2559_my_order_list.css");

@import url("screen/order_placed.css");
@import url("screen_767/screen_767_order_placed.css");
@import url("screen_768_1023/screen_768_1023_order_placed.css");
@import url("screen_1024_1439/screen_1024_1439_order_placed.css");
@import url("screen_1440_1999/screen_1440_1999_order_placed.css");
@import url("screen_2000_2559/screen_2000_2559_order_placed.css");

@import url("screen_2000_2559/screen_2000_2559_my_order_list.css");
@import url("screen_2560_above//screen_2560_my_order_list.css");

@import url("screen/my_profile.css");
@import url("screen_767/screen_767_my_profile.css");
@import url("screen_768_1023/screen_768_1023_my_profile.css");
@import url("screen_1024_1439/screen_1024_1439_my_profile.css");
@import url("screen_2000_2559/screen_2000_2559_my_profile.css");
@import url("screen_2560_above/screen_2560_my_profile.css");

/* Customer Home */

@import url("common/customer_home.css");
@import url("screen_767/screen_767_customer_home.css");
@import url("screen_768_1023/screen_768_1023_customer_home.css");
@import url("screen_1024_1439/screen_1024_1439_customer_home.css");
@import url("screen_1440_1999/screen_1440_1999_customer_home.css");
@import url("screen_2000_2559/screen_2000_2559_customer_home.css");
@import url("screen_2560_above/screen_2560_customer_home.css");
@import url("./screen_767/screen_767_customer_layout.css");

@import url("./screen/cart.css");

/* Customer Cart Page */
@import url("common/customer_cart.css");
@import url("screen_767/screen_767_customer_cart.css");
@import url("screen_768_1023/screen_768_1023_customer_cart.css");
@import url("screen_1024_1439/screen_1024_1439_customer_cart.css");
@import url("screen_1440_1999/screen_1440_1999_customer_cart.css");
@import url("screen_2000_2559/screen_2000_2559_customer_cart.css");
@import url("screen_2560_above/screen_2560_customer_cart.css");

@import url("screen/order_refund.css");
@import url("screen_767/screen_767_order_refund.css");
@import url("screen_2560_above/screen_2560_customer_layout.css");
@import url("screen/order_tracking.css");
@import url("layout/layout.css");
@import url("login/login.css");
@import url("form.css");
@import url("product_demand/product_demand.css");
@import url("farmer_order/farmer_order.css");
@import url("farmer/farmer.css");
@import url("layout/customer_layout.css");
@import url("layout/profile_layout.css");
@import url("common/product_card.css");
@import url("screen/access_rights.css");
@import url("screen/stock_keeping_unit.css");
@import url("screen/cluster.css");
@import url("screen/loading.css");

/* Customer modules */
@import url("screen_768_1023/screen_768_1023_product_list.css");
/* @import url("screen_767/screen_767_products.css"); */
@import url("screen_768_1023/screen_768_1023_products.css");
@import url("screen_1024_1439/screen_1024_1439_products.css");
/* @import url("screen_2000_2559/screen_2000_2559_products.css"); */
@import url("screen_2560_above/screen_2560_products.css");

@import url("screen_767/screen_767_login.css");
@import url("screen_768_1023/screen_768_1023_login.css");
@import url("screen_1024_1439/screen_1024-1439_login.css");
@import url("screen_1440_1999/screen_1440_1999_login.css");
@import url("screen_2000_2559/screen_2000_2559_login.css");
@import url("screen_2560_above/screen_2560_login.css");
@import url("screen/customer_login.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import url("screen/manage_address.css");
@import url("screen_767/screen_767_manage_address.css");
@import url("screen_768_1023/screen_768_1023_manage_address.css");
@import url("screen_1024_1439/screen_1024_1439_manage_address.css");
@import url("screen_1440_1999/screen_1440_1999_manage_address.css");
@import url("screen_2000_2559/screen_2000_2559_manage_address.css");
@import url("screen_2560_above/screen_2560_manage_address.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

@import url("screen/product_list.css");
@import url("screen_767/screen_767_product_list.css");
@import url("screen_768_1023/screen_768_1023_product_list.css");
@import url("screen_1024_1439/screen_1024_1439_product_list.css");
@import url("screen_1440_1999/screen_1440_1999_product_list.css");
@import url("screen_2000_2559/screen_2000_2559_product_list.css");
@import url("screen_2560_above/screen_2560_product_list.css");

/* Product Details */
@import url("screen/product_detail.css");
@import url("screen_767/screen_767_product_details.css");
@import url("screen_768_1023/screen_768_1023_product_details.css");
@import url("screen_1024_1439/screen_1024_1439_product_details.css");
@import url("screen_1440_1999/screen_1440_1999_product_details.css");
@import url("screen_2000_2559/screen_2000_2559_product_details.css");
@import url("screen_2560_above/screen_2560_product_details.css");

@import url("./screen/cart.css");
/* Wishlist */
@import url("screen/wishlist.css");

/* Checkout */
@import url("screen/checkout.css");
@import url("screen_767/screen_767_checkout.css");

@import url("screen/my_order_list.css");
@import url("screen_767/screen_767_my_order_list.css");
@import url("screen_768_1023/screen_768_1023_my_order_list.css");
@import url("screen_1024_1439/screen_1024_1439_my_order_list.css");
@import url("screen_1440_1999/screen_1440_1999_my_order_list.css");
@import url("screen_2000_2559/screen_2000_2559_my_order_list.css");

@import url("screen/order_placed.css");
@import url("screen_767/screen_767_order_placed.css");
@import url("screen_768_1023/screen_768_1023_order_placed.css");
@import url("screen_1024_1439/screen_1024_1439_order_placed.css");
@import url("screen_1440_1999/screen_1440_1999_order_placed.css");
@import url("screen_2000_2559/screen_2000_2559_order_placed.css");

@import url("screen_2000_2559/screen_2000_2559_my_order_list.css");
@import url("screen_2560_above//screen_2560_my_order_list.css");

@import url("screen/my_profile.css");
@import url("screen_767/screen_767_my_profile.css");
@import url("screen_768_1023/screen_768_1023_my_profile.css");
@import url("screen_1024_1439/screen_1024_1439_my_profile.css");
@import url("screen_2000_2559/screen_2000_2559_my_profile.css");
@import url("screen_2560_above/screen_2560_my_profile.css");

/* Customer Home */

@import url("common/customer_home.css");
@import url("screen_767/screen_767_customer_home.css");
@import url("screen_768_1023/screen_768_1023_customer_home.css");
@import url("screen_1024_1439/screen_1024_1439_customer_home.css");
@import url("screen_1440_1999/screen_1440_1999_customer_home.css");
@import url("screen_2000_2559/screen_2000_2559_customer_home.css");
@import url("screen_2560_above/screen_2560_customer_home.css");
@import url("./screen_767/screen_767_customer_layout.css");

@import url("./screen/cart.css");

/* Customer Cart Page */
@import url("common/customer_cart.css");
@import url("screen_767/screen_767_customer_cart.css");
@import url("screen_768_1023/screen_768_1023_customer_cart.css");
@import url("screen_1024_1439/screen_1024_1439_customer_cart.css");
@import url("screen_1440_1999/screen_1440_1999_customer_cart.css");
@import url("screen_2000_2559/screen_2000_2559_customer_cart.css");
@import url("screen_2560_above/screen_2560_customer_cart.css");

@import url("screen/order_refund.css");
@import url("screen_767/screen_767_order_refund.css");
@import url("screen_1440_1999/screen_1440_1999_order_refund.css");
@import url("screen_1024_1439/screen_1024_1439_order_refund.css");
@import url("screen_2560_above/screen_2560_customer_layout.css");

/* admin drivers module */
/* Packing Suggestion */
@import url("packing_suggestion/packing_suggestion.css");

/* admin drivers module */
@import url("screen/drivers.css");
@import url("screen/vehicle.css");

/* admin packing team module */
@import url("screen/packing_team.css");
@import url("screen/checkin.css");
@import url("common/status.css");
@import url("screen/coupon.css");

/* Read our story */
@import url("screen_767/screen_767_read_our_story.css");
@import url("screen_768_1023/screen_768_1023_read_our_story.css");
@import url("screen_1024_1439/screen_1024_1439_read_our_story.css");
@import url("screen_1440_1999/screen_1440_1999_read_our_story.css");
@import url("screen_2000_2559/screen_2000_2559_read_our_story.css");
@import url("screen_2560_above/screen_2560_read_our_story.css");

/* Category */
@import url("screen/category.css");
@import url("screen_767/screen_767_category.css");
/* @import url("screen_768_1023/screen_768_1023_category.css"); */
@import url("screen_1024_1439/screen_1024-1439_category.css");
@import url("screen_1440_1999/screen_1440_1999_category.css");
@import url("screen_2000_2559/screen_2000_2559_category.css");
/* @import url("screen_2560_above/screen_2560_category.css"); */
@import url("screen/faq.css");
.bread-crumb > ol {
  align-items: end !important;
}
.bread-crumb > ol > li > a:hover {
  background-color: transparent;
  color: #75a665;
}
.bread-crumb > ol > li > a,
.bread-crumb > ol > li > span,
.ant-breadcrumb-separator {
  font-size: 18px;
  font-weight: bold;
}
.bread-crumb > ol > li > span {
  color: gray;
  font-family: "Montserrat-semibold";
}
.admin-bread-crumb > ol > li > span {
  color: white;
}
.admin-bread-crumb {
  margin-left: 20px;
}
.admin-bread-crumb > ol > li > a,
.admin-bread-crumb > ol > li > span,
.ant-breadcrumb-separator {
  font-size: 20px;
  font-weight: bold;
  color: rgb(192, 192, 192) !important;
}

.order-card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  padding: 5px !important;
}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.search-box-container > span {
  border: 2px solid #bdbdbd !important;
  border-radius: 20px;
}
.search-box-container > span > .ant-input-affix-wrapper {
  border: none !important;
  border-radius: 20px !important;
  margin-top: 3px;
}
.search-box-container > span > input {
  border: none !important;
  border-radius: 20px;
}
.search-box-container > span > input > span > button {
  border: none !important;
}
.search-box-container > span > input > span > button :focus {
  box-shadow: none !important;
  border-color: transparent !important;
}
.ant-input-search-button {
  border: none !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.save-button {
  background-color: #194e08 !important;
  color: white;
  border: 1px solid #194e08 !important;
}
.save-button:hover {
  color: white !important;
}

.close-button {
  background-color: #d42828 !important;
  color: white;
  border: 1px solid #d42828 !important;
}
.close-button:hover {
  color: white !important;
}

.delete-icon,
.edit-icon .approve-icon .reject-icon {
  cursor: pointer;
}
.approve-icon {
  color: #194e08 !important;
  cursor: pointer;
}
.reject-icon {
  color: #d42828 !important ;
  cursor: pointer;
}

.status-tag {
  font-weight: bold !important;
  color: white !important;
  border-radius: 5px !important;
  text-align: center !important;
}
.status-tag-bullet {
  font-weight: bold !important;
  color: black !important;
  border-radius: 5px !important;
  text-align: center !important;
}
.status-dot {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}
.status-dot-not-updated {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}
.status-demand {
  background-color: rgb(255, 145, 0) !important;
}

.status-active {
  background-color: rgb(90, 141, 234) !important;
}

.status-closed {
  background-color: red !important;
}

.bullet-red {
  margin-right: 5px;
  font-size: 35px;
  color: red;
}
.bullet-maturity {
  color: rgb(90, 141, 234) !important;
}

.status-revert {
  background-color: rgb(225, 60, 60) !important;
}

.status-maturity {
  background-color: Orange !important;
}

.status-pending {
  background-color: Purple !important;
}
.status-not-received {
  background-color: Red !important;
}
.status-not-updated {
  background-color: Gray !important;
}
.status-received {
  background-color: #1e5128 !important;
}
.status-po-generated {
  background-color: Teal !important;
}
.status-un-paid {
  background-color: Brown !important;
}
.status-paid {
  background-color: #194e08 !important;
}
.modal-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #194e08;
  color: white;
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.modal-body {
  padding: 20px 24px;
}
.ant-modal-content {
  padding: 0px !important;
}
.search-box-container > .ant-input-wrapper > .ant-input-outlined:focus-within,
.search-box-container > .ant-input-wrapper > .ant-input-outlined:hover {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.edit-icon {
  cursor: pointer;
}
.modal-body {
  padding: 22px !important;
}
.product_delete_container {
  margin-bottom: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.product_delete_content {
  font-size: 18px !important;
}
.review_rating {
  color: #194e08 !important;
}
.star_icon_grey {
  color: #bdbdbd;
  padding-left: 3px;
  padding-top: 4px;
}
.farmer_table_icons {
  display: flex;
  gap: 15px;
}
.ant-modal-footer {
  padding: 20px !important;
}
.profile-drawer > .ant-drawer-body {
  padding: 10px !important;
  background-color: #8bb38847 !important;
}
.ant-modal-confirm-body {
  padding: 10px;
}
.ant-modal-confirm-btns {
  padding: 10px;
}

.stock-next-date-move-action {
  background-color: #8e5307 !important;
  color: white;
  border: 1px solid #8e5307 !important;
  width: auto !important;
}

.stock-next-date-move-action:hover {
  color: white !important;
}
.stock-next-date-move-action:disabled {
  color: rgba(255, 255, 255, 0.704) !important;
}
.status-tag-column {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.forgot-password-button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(135deg, #819419 0%, #5a661c 100%);
  color: white;
  transition: all 0.3s ease;
}

.forgot-password-button:hover {
  background: linear-gradient(135deg, #fc7f25 0%, #6a11cb 100%) !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* .price-details-container {
  margin-bottom: 10px;
} */
.price-details-container.ant-descriptions-item {
  padding-bottom: 5px; /* Space between items */
}
.price-details-container.ant-descriptions-item-label {
  font-weight: normal; /* Remove default bold from labels */
}
.price-details-container.ant-descriptions-item-content {
  text-align: right; /* Align values to the right */
}
.price-details-container.ant-descriptions-item:last-of-type.ant-descriptions-item-label,
.price-details-container.ant-descriptions-item:last-of-type.ant-descriptions-item-content {
  font-weight: bold; /* Make total row bold */
}

.order_detail_price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
}
.packing-suggestion-tab {
  justify-content: "flex-end";
  display: "flex";
  gap: "10px";
}

.order_list_card {
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  background: #f0f0f0;
}
.order_list_card_space {
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
}

.order_list_card_space_div {
  text-align: center;
  margin-top: 16px;
}

.order_card_image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.order_details_card {
  /* margin-bottom: 10px; */
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.order_details_card_descriptions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border-top: 1px solid #eee; */
  padding-top: 10px;
}
.link_sent_color {
  color: #8bc86f;
  background-color: #f6ffed;
  padding: 5px;
  border-radius: 4px;
  border-color: #8bc86f;
  border: 2px solid;
  font-weight: bold;
}
.link_not_sent_color {
  color: #dd545f;
  background-color: #fff1f0;
  padding: 5px;
  border-radius: 4px;
  border-color: #dd545f;
  border: 2px solid;
  font-weight: bold;
}
/* .whats-app-input{
  color: #000000 !important;  
  width: 10px !important;
  height: 10px !important   ;
}  */
.html-content-render::-webkit-scrollbar {
  display: none !important;
}
.story-container {
  width: 100%;
  position: relative;
}

.image-container {
  width: 100%;
  position: relative;
  overflow: hidden; /* Ensures content stays within boundaries */
}

.story-image {
  width: 100%;
  display: block;
  height: auto;
}

.text-overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(
    255,
    248,
    225,
    0.041
  ); /* Semi-transparent beige background */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
}

.text-content {
  text-align: center;
  width: 100%;
}

.heading {
  color: #b45309; /* Amber/brown color */
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 10px;
}

.description {
  color: #92400e; /* Darker amber color */
  line-height: 1.4;
  /* margin-bottom: 20px; */
}

.read-button {
  background-color: #41860a; /* Amber/brown color */
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-button:hover {
  background-color: #2a6712; /* Darker amber color on hover */
}

:where(.css-dev-only-do-not-override-1x0dypw).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child {
  inset-inline-start: -1px;
  padding: 0;
  border: 0;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
