.product-list-container {
  display: flex;
  width: 100% !important;
  height: fit-content;
  display: flex !important;
  justify-content: space-between !important;
}

.category-list-container {
  width: 15%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  position: sticky;
  top: 100px !important;
  height: fit-content;
  background-color: white;
  overflow-y: auto;
  max-height: 110vh;
  height: 78vh;
  scrollbar-width: none !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
}
.category-list-container-product {
  width: 21%;
  /* padding: 5px; */
  border: 1px solid #ccc;
  border-radius: 10px;
  position: sticky;
  top: 100px !important;
  height: fit-content;
  background-color: white;
  overflow-y: auto;
  min-height: 470px;
  height: 78vh;
  scrollbar-width: none !important;
  display: flex;
  flex-direction: column;
}

.category-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 1rem;
  padding: 20px 0px 20px 5px;
}
.product-list-body-container {
  display: grid;
  gap: 20px !important;
  width: 100% !important;
  padding: 0px 5px;
}
.product-list-body-empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  /* padding: 20px; */
}
.product-list-category-main-container {
  width: 100% !important;
}

.category-item > img {
  width: 3vw;
  height: 3vw;
  object-fit: cover;
  border-radius: 50%;
  background-color: #e1f9e1;
}
.category-item.selected,
.mobile-category-item-selected {
  /* background-color: green; */
  background: linear-gradient(
    to top,
    rgba(0, 128, 0, 0.26),
    transparent
  ) !important;
  color: black;
  border-radius: 5px;
}
.subcategory-item-selected {
  /* background-color: green; */
  background: linear-gradient(
    to top,
    rgba(0, 128, 0, 0.26),
    transparent
  ) !important;
  color: black;
  border-radius: 5px;
}

.products-title-container {
  display: flex;
  width: 100%;
}
.products-title-container .all-category-title {
  width: 26%;
  margin-bottom: 20px;
  font-family: Montserrat;
}
.products-title > h2,
.all-category-title > h2 {
  font-family: Montserrat;
  font-weight: bold;
}
/* .category-select {
  width: 300px !important;
} */
.category-select .ant-select-selector {
  width: fit-content !important;
}
.category-label-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.home-category-label-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: center;
  align-items: center;
}
.product-list-category-item {
  height: 100%;
  background-color: #2d6a2d;
  color: white;
  width: 200px;
}
.product-list-category-item-seleted {
  background-color: #2d6a2d;
  color: white;
}
.product-list-category-item > .img {
  width: 35px;
}
.home-category-label-container > img {
  width: 30px;
  object-fit: contain;
}

.category-list-mobile-container {
  display: block;
}
.category-list-mobile-container .products-title {
  margin-bottom: 40px;
}
.product-list-bread-crumb-container {
  margin-bottom: 10px;
}
.category-skeleton {
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 15px;
}

.category-skeleton .ant-skeleton-element {
  margin-right: 20px;
}
.product-skeleton-card {
  width: 100% !important;
}
.product-skeleton-card-button {
  width: 100% !important;
  height: 32px !important;
}

.product-skeleton-card-image {
  width: 100% !important;
  height: 190px !important;
}
.product-list-main-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.category-placeholder {
  font-weight: bold !important;
  color: black !important;
}

.product-card .add-to-cart {
  color: gray;
  height: 7px;
}

.admin-product-list-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.category-title-txt {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
  text-transform: capitalize;
  padding: 10px;
}
.share_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}
.testimonial-carousel {
  width: 100%;
}

.testimonial-card {
  padding: 10px;
  display: flex;
  gap: 10px !important;
  margin-bottom: 10px !important;
}

.testimonial-content {
  box-shadow: 5px 5px 15px rgba(0, 101, 204, 0.15);
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  width: 98%;
  border-radius: 10px;
}

.testimonial-left {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.testimonial-image {
  width: 160px;
  height: 170px;
  border-radius: 10px;
  object-fit: cover !important;
}

.testimonial-name {
  font-weight: bold;
}

.testimonial-desc {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.testimonial-right {
  width: 64%;
  overflow: hidden;
  padding: 10px;
}
.testimonial-title {
  color: #4b9d49;
  margin-bottom: 10px !important;
  font-size: 18px;
}

.testimonial-text {
  font-size: 16px;
  line-height: 1.5;
}
.testimonial-simple {
  color: #b99669;
  margin-bottom: 10px !important;
}

.see-more-text {
  color: #2d6a2d !important;
  font-size: 14px;
  cursor: pointer;
}

/* Custom Arrow Styling */
.custom-arrow {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  /* transition: color 0.3s ease-in-out; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #4b9d49;
  margin-bottom: 20px !important;
}

.custom-prev {
  left: -30px;
}

.custom-next {
  right: -30px;
}

.custom-arrow:hover {
  color: #2d6a2d;
}
.share_social_media_icons {
  width: 25px !important;
}
.carousel-container-sub-category {
  position: relative;
}

.custom-arrows {
  position: absolute;
  top: -20px;
  right: 20px;
  display: flex;
  gap: 8px;
}

.arrow-btn {
  background: #d9dadb;
  border: 1px solid #ddd;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease;
  height: 25px;
  width: 25px;
}

.arrow-btn:hover {
  background: #008000;
  color: #ffff;
}

.left-arrow,
.right-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-category-item,
.subcategory-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  justify-content: space-between;
}
.subcategory-item {
  margin-left: 15px;
  justify-content: start;
}
.category-image-with-text-conatiner {
  display: flex;
  align-items: center;
  gap: 5px;
}
.category-image-with-text-conatiner > p {
  font-weight: 400;
  font-size: 14px;
}
.category-image-with-text-conatiner > img,
.subcategory-item > img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  object-fit: cover;
}
.product-list-content-container {
  display: flex;
}
.empty-product-list-container img {
  width: 350px;
  height: 350px;
}
.empty-product-list-container p {
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  color: #777676;
}
