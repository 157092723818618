@media only screen and (min-width: 2560px) {
  .category-list-mobile-container {
    display: none;
  }
  .product-list-body-container {
    grid-template-columns: repeat(auto-fill, minmax(10vw, 1fr)) !important;
  }

  .category-list-container-product {
    height: 89vh !important;
  }
  .testimonial-content {
    justify-content: space-between;
  }
  .testimonial-card {
    min-height: 270px !important;
  }
  .share_social_media_icons {
    width: 30px !important;
  }
}
