.list_pagination {
  display: flex;
  justify-content: flex-end;
}
.list_table {
  margin: 10px !important;
}
.page_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.main_content {
  flex-grow: 1;
  height: auto;
  /* min-height: 100vh; */
  padding: 10px;
  /* background: linear-gradient(
      -45deg,
      rgb(255 255 255) 10%,
      rgb(69 170 231 / 37%) 33%,
      rgb(3 175 242) 100%
    ); */
}
.animated_title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-left: 10px;
}
.header {
  background: linear-gradient(45deg, #194e08, #4f9f49);
  padding: 0px;
  position: sticky;
  top: 0px;
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
}
.sidebar_header_image {
  width: 150px;
  height: 60px;
  object-fit: contain;
}

.layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.sidebar_header_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  /* background-color: var(--layout-header); */
  background: linear-gradient(45deg, #194e08, #1b510a);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.header_and_content_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.ps-sidebar-container {
  background-color: #194e0896 !important;
}
.menu_item {
  color: #fff;
}
.menu_item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
}

.menu_item:hover {
  background-color: transparent !important;
  color: #fff;
}

.menu_item.active,
.menu_item.selected {
  background-color: #203f5c;

  color: #fff;
}
.ps-menu-button:hover {
  background-color: #203f5c !important;
}

.sidebar_content {
  height: calc(100vh - 80px) !important;
}

.total_items_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}
.total_items {
  margin-top: 5px;
  font-size: 16px;
  margin-right: 10px;
}

.ps-menu-button {
  margin: 0px !important;
}
.ps-menu-button > nav ul {
  display: inline !important;
}

.menu-expand-icon {
  color: #fff;
  cursor: pointer;
}
.header-user-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.header-user-title {
  color: white;
  font-weight: bold;
  font-size: 18px;
  height: 15px;
}
.header-user-role {
  color: white;
  font-weight: bold;
  font-size: 10px;
  text-align: end;
}
.profile_logo {
  width: 40px;
  border-radius: 50%;
}

.ps-active {
  background-color: white !important;
  color: black !important;
}
.ps-active > .menu_item {
  color: black;
}
.ps-menuitem-root > .ps-active:hover,
.ps-active > .menu_item:hover {
  background-color: white !important;
}
.ps-active > .ps-menu-label {
  color: var(--layout-header) !important;
  font-weight: bold;
}
.ps-menu-label {
  color: white !important;
  font-weight: bold;
}
.ps-menu-button:hover {
  background-color: #67a154 !important;
}
.ant-table-sticky-scroll {
  display: none !important;
}
.menu-icon {
  width: 40px;
}
.role-container {
  display: flex;
  flex-direction: column;
  height: 40px;
  width: auto;
}

.role-container > text {
  height: 30px;
}

.user-menu-login-button {
  background-color: green;
  font-family: "Montserrat-semibold";

  color: white;
}

.user-menu-logout-button {
  background-color: green;

  font-family: "Montserrat-semibold";
  color: white;
}
.admin-menu-list {
  z-index: 10000000000000;
}
.breadcrumb_content > .breadcrumb_link {
  color: #194e08 !important;
  font-family: "Montserrat-semibold";
  font-weight: bold;
}
.without_theme > .without_link {
  color: white !important;
}
.breadcrumb_link {
  color: gray !important;
}
.ps-open {
  background-color: transparent !important;
}
.ps-active > svg {
  color: #194e08 !important;
}

.without_link {
  color: white !important;
}
/* :where(.css-dev-only-do-not-override-ni1kz0).ant-input-affix-wrapper .ant-input-suffix {
  margin-top: 14px;
} */

.out_of_stock_for_grading {
  font-size: 10px;
  font-weight: bold;
  background-color: red;
  border-radius: 5px;
  color: white;
  padding: 2px;
  width: 40%;
  display: flex;
  justify-content: center;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.in_stock_for_grading {
  font-size: 10px;
  font-weight: bold;
  background-color: green;
  border-radius: 5px;
  color: white;
  padding: 2px;
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.header-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.header-user-name-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.stock-transfer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  flex-direction: column;
}

.stock-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
}

.stock-button.selected {
  color: #1890ff;
}
.stock_transfer_buttons {
  flex-direction: row !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.sticky_column_stock_transfer {
  position: sticky !important;
  top: 0;
  z-index: 1000;
  padding: 15px;
  background: white;
}
.image_header {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  object-fit: cover;
  border-radius: 4px;
}
.search-product-filter {
  border-radius: 25%;
  width: 20%;
}
/* 
.ant-form-item-control-input >  .ant-form-item-control-input-content {
      display: flex !important;
      justify-content: flex-end !important;
      width: 90% !important;
} */

.term_title {
  text-align: center;
  margin-bottom: 30px !important;
  font-family: "Montserrat-semibold";
}

.paragraph_terms {
  font-family: "Montserrat";
  text-align: justify;
  font-size: 16px;
}

.paragraph_terms_title {
  font-family: "Montserrat";
  text-align: justify;
  font-size: 18px;
  font-weight: bold;
}
