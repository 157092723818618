.packing-suggestion {
  padding: 5px;
  font-family: "Montserrat-semibold";
}
.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 5px;
}

.product-grid {
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); */
  gap: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust as needed */
}
.packing-suggestion-product-card {
  text-align: center;
  font-family: "Montserrat-semibold";
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.packing-suggestion-product-card-container {
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.packing-suggestion-category-box {
  border-color: grey;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.packing-suggestion-product-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-card img {
  max-width: 100%;
  border-radius: 8px;
  font-family: "Montserrat-semibold";
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.category-filter {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-semibold";
  gap: 10px;
  width: 156px;
}

.category-filter select {
  padding: 5px;
  font-size: 14px;
  font-family: "Montserrat";
}

.search-bar input {
  padding: 5px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.packing-product-description-two {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.packing-suggestion-product-card-title {
  /* color: #1c6903; */
  font-family: "Montserrat-semibold";
  font-size: 30px;
}
.packing-product-description {
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 10px;
  width: 100% !important;
}
.packing-suggestion-right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-direction: row;
  width: 100%;
}
.packing-suggestion-product-name {
  color: black;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.packing-suggestion-date-picker-title {
  margin-bottom: 10px !important;
}
.cancel-btn {
  background-color: #f5222d;
  color: white;
  border: none;
}

.cancel-btn:hover {
  background-color: #cf1322 !important;
  color: white !important;
  border: none !important;
}

.submit-btn {
  background-color: #1890ff;
  color: white;
  border: none;
}

.submit-btn:hover {
  background-color: #1890ff !important;
  color: white !important;
  border: none !important;
}

.variant-row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}

.variant-name {
  font-weight: 500;
  color: #333;
  width: 60px;
}

.variant-input {
  flex: 1;
  max-width: 150px;
}
.product_suggestion_footer_btn_container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}
.variant-text-style {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  /* text-decoration: underline; */
}
.checkbox-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.icon_move {
  font-size: 20px;
  cursor: pointer;
}
.packing-suggestion-action-container {
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 20px;
  padding: 10px 0px;
}
.alert-icon-reference-container {
  display: flex;
  /* justify-content: flex-end; */
  gap: 25px;
  padding: 10px 0px;
  color: #333;
}
.packing-suggestion-product-img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.alert-icon-reference-icon-container {
  display: flex;
  justify-content: center;
  align-items: center !important;
  gap: 5px;
}
.check-in-label-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin: 0 0 10px 0;
}
.label-value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
/* Packing Wastage Table Styles */
.packing-wastage-table {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.packing-wastage-table .ant-table-thead > tr > th {
  background-color: #008000;
  font-weight: bold;
  text-align: center;
  color: white;
}

.packing-wastage-table .ant-table-tbody > tr > td {
  text-align: center;
}

/* Secondary Packing Expandable Table */
.secondary-packing-expandable-table {
  padding: 10px;
  border-radius: 5px;
}

.secondary-packing-expandable-table .ant-table-thead > tr > th {
  background-color: #008000;
  font-weight: bold;
}

.secondary-packing-expandable-table .ant-table-tbody > tr > td {
  text-align: center;
}
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  padding: 16px;
}

.skeleton-card {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 16px;
  height: 140px;
}
.packing-suggestion-container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
}

.packing-suggestion-icon {
  font-size: 25px;
}

.packing-suggestion-demand-txt {
  font-size: 16px;
  font-weight: bold;
  color: #000 !important;
}
