/* .mobile-cart-container img {
  width: 100px;
  margin-top: -20px;
  padding-left: 10px;
} */
.mobile-cart-container {
  display: flex;
  gap: 10px;
  align-items: center;
  transition: box-shadow 0.3s ease;
  border-radius: 5px !important;
}

.mobile-cart-main-container {
  border: 1px solid rgba(128, 128, 128, 0.162);
  border-radius: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  background-color: #f3fff3;
}
.mobile-cart-discretion-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.mobile-cart-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: calc(85vh - 150px); */
  min-height: 450px;
  padding: 3px;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 10px;
}

.mobile-cart-footer {
  position: sticky;
  bottom: 0px;
  background-color: white;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0 0;
}

.cart-item-remove-icon-container {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  align-items: center;
  gap: 5px;
}
.cart-item-footer {
  display: flex;
  align-items: end;
  justify-content: end;
  padding-top: 10px;
  padding-right: 20px;
  gap: 10px;
}
.cart-item-footer h3 {
  color: green;
}
.cart-item-footer text {
  font-size: 17px;
  font-weight: bold;
}
.subtotal-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.bottom-cart-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.checkout-button {
  border: 1px solid #4f9f49 !important ;
  background-color: #4f9f49 !important;
}
.view-cart-button {
  border: 1px solid #4f9f49 !important ;
  color: #4f9f49 !important;
}
.cart-item-main-container {
  padding: 10px;
  /* min-height: 100vh; */
}
.remove-cart-item-icon,
.cart-icon {
  cursor: pointer;
}

.cart_main_container {
  background: url("../../assets/images/admin_login_background.png");
  /* background-repeat: repeat;
  /* height: 100vh; */
  /* object-fit: cover; */
  background-color: white;
  height: 100%;
  min-height: 100vh;
  background: url("../../assets/images/admin_login_background.png");
}
.mobile-cart-item-card-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.mobile-cart-item-qty-change {
  display: flex;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Styling for the login card */
.login-card {
  width: 350px;
  padding: 30px;
  /* background: linear-gradient(135deg, #1dbf73, #4feb5c, #73b74f);  */
  background: rgb(226, 226, 226);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  transition: transform 0.3s ease-in-out;
}

/* Add hover effect to the card */
.login-card:hover {
  transform: scale(1.05);
}

/* Header styles */
.login-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: rgb(37, 37, 37);
}

.login-header p {
  margin: 10px 0;
  font-size: 16px;
  color: rgb(37, 37, 37);
}

/* Button Styling */
.login-button {
  width: 100%;
  padding: 12px 0;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.3s,
    transform 0.2s;
}

/* Button Hover effect */
.login-button:hover {
  background-color: #feb47b; /* Lighter coral */
  transform: translateY(-2px);
}

/* Button focus effect */
.login-button:focus {
  outline: none;
  box-shadow: 0 0 5px #ff7e5f;
}

.mobile-cart-main-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.mobile-cart-item-qty-change .ant-btn-group .ant-btn {
  transition: background-color 0.3s ease;
}

.mobile-cart-item-qty-change .ant-btn-group .ant-btn:hover {
  background-color: #b2dfb2;
}
.remove-cart-button {
  border: 1px solid #c56b18 !important ;
  background-color: #c56b18 !important;
}

.price_section_cart {
  width: fit-content;
  padding: 2px 4px;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  text-align: end;
}

.price_section_cart.discount_value {
  background-color: #ffe5e5;
}

.discount_percentage_cart {
  font-size: 13px;
  font-weight: bold;
  color: #d32f2f;
}
.mobile-cart-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  padding-left: 5px;
  padding-right: 5px !important;
  border-radius: 5px !important;
}

.mobile-cart-image-container {
  height: 100px;
  width: 150px;
  border-radius: 5px;
}
