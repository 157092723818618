.order-placed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: linear-gradient(to bottom right, #d4ffd7, #ffffff);
}
.order-failed-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
  background: linear-gradient(to bottom right, #d4ffd7, #ffffff);
}
.order-placed-content {
  text-align: center;
  /* background-color: white; */
  border-radius: 10px;
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}
.order-failed-content {
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  width: 50% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.order-processing-content {
  text-align: center;
  /* background-color: white; */
  border-radius: 10px;
  padding: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  width: 50% !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order-placed-illustration {
  width: 43%;
  margin-bottom: 23px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.order-cancelled-illustration img {
  width: 30%;
}

.order-placed-text {
  display: flex;
  font-family: "Montserrat-semibold";

  flex-direction: column;

  gap: 10px;
}

.order-placed-text h1 {
  font-size: 24px;
  color: #2e7d32;
  font-family: "Montserrat-semibold";
}

.order-cancelled-text h1 {
  font-size: 30px !important;
  color: #f81128 !important;
  font-family: "Montserrat-semibold";
}

.order-cancelled-text p {
  font-size: 17px;
  color: #555;
  font-family: "Montserrat-semibold";
}

.order-placed-text p {
  font-size: 17px;
  color: #555;
  font-family: "Montserrat-semibold";
}

.order-placed-actions {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  gap: 13px;
}

.order-placed-actions .view-order-button,
.order-placed-actions .continue-shopping-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  font-family: "Montserrat-semibold";
}

.order-placed-actions .view-order-button {
  background-color: #ffffff;
  color: #2e7d32;
  font-family: "Montserrat-semibold";
  border: #2e7d32 1px solid;
}

.order-placed-actions .continue-shopping-button {
  background-color: #2e7d32;
  color: white;
}
.view-order-button,
.continue-shopping-button {
  z-index: 1000000;
}
.checout_order_container {
  display: flex;
  gap: 5px;
}
.checout_order_note_section {
  text-align: center;
  color: red;
  font-weight: bold;
}
