@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .category-list-mobile-container {
    display: none;
  }
  .product-list-body-container {
    grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr)) !important;
    justify-items: center;
  }

  .product-card {
    width: 16vw;
    padding: 10px;
    min-height: 320px;
  }

  .product-card img {
    width: 100%;
    /* height: 170px; */
    object-fit: cover;
  }

  .product-card .category {
    font-size: 0.8em;
  }

  .product-card .title {
    font-size: 1em;
    margin: 3px 0;
    height: 50px;
  }

  .product-card .price {
    font-size: 0.8em;
    font-weight: bold;
  }

  .product-card .delivery {
    margin-top: 8px;
    font-size: 0.9em;
  }

  .product-card .quantity-control,
  .quantity-control {
    gap: 10px;
  }

  .product-card .quantity-control button,
  .quantity-control button {
    padding: 4px 8px;
  }

  .product-card .add-to-cart {
    margin-top: 1px;
    font-size: 14px;
  }

  .product-card .add-to-cart button {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .product-card-action-button {
    width: 25px;
  }
  .product-card-cart-action-button {
    font-size: 15px;
  }

  .product-skeleton-card .ant-card-body {
    padding: 10px !important;
  }

  .add-to-cart > text {
    font-size: 10px;
  }
  .products-title > h2 {
    padding-top: 5px;
    font-size: 19px;
  }
  .products-title-container .all-category-title {
    width: 23%;
  }
  .products-title {
    width: 50%;
  }
  .card-action-button {
    font-size: 10px;
  }
  .product-card .quantity-control button,
  .quantity-control button {
    width: 20px;
  }
  .quantity-control > text {
    font-size: 10px;
  }
  .product-cart-icon {
    font-size: 15px;
  }

  .category-label-container > img {
    height: 25px;
  }
  .category-item {
    font-size: 0.9rem;
  }
  .category-item > img {
    width: 5vw;
    height: 5vw;
  }
  .testimonial-content {
    justify-content: space-between;
  }
  .testimonial-card {
    min-height: 270px !important;
  }
  .category-list-container {
    width: 20%;
  }
}
