@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .carousel-subtext {
    font-size: 23px;
  }

  .carousel-button {
    left: 13%;
    font-size: 23px;
  }

  .shop-now-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .shop-now-img {
    width: 550px !important;
    height: 250px !important;
  }

  .trending-products-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    gap: 700px;
  }
  .react-multiple-carousel__arrow--right {
    right: calc(0% +20px) !important;
  }
}
