.farmer-order-po-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}
.farmer-list-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
}

.status_items {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 10px;
}

.order_reference_table_field {
  font-weight: bold;
}

.order_day_time_table_field {
  margin-top: 3px;
  margin-bottom: -15px;
  font-size: 12px;
}

.order_id_table_field {
  text-align: center;
}
.order_id_table_container {
  text-align: left;
}
.status_items_individual {
  margin-right: 15px;
}
.farmer-list-add-farmer-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 10px;
}
.farmer-body-filter-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}
.view-icon {
  cursor: pointer;
}
.farmer-details-top-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 30px;
}

.add-button {
  background-color: #3f8635 !important;
  color: white;
  border: 1px solid #3f8635 !important;
  width: auto !important;
}
.bulk_update_button {
  background-color: #4096ff !important;
  color: white;
  border: 1px solid #4096ff !important;
  width: auto !important;
}

.export-button-style {
  background-color: #ffa500 !important;
  color: white;
  border: 1px solid #ffa500 !important;
  width: auto !important;
}

.add-button:hover {
  color: white !important;
}
.bulk_update_button {
  background-color: #4096ff !important;
  color: white;
  border: 1px solid #4096ff !important;
  width: auto !important;
}
.add-button:disabled {
  color: rgba(255, 255, 255, 0.704) !important;
}
.bulk_update_button:hover {
  color: white !important;
}

.export-button {
  background-color: #3f8635 !important;
  color: white;
  border: 1px solid #3f8635 !important;
  width: auto !important;
  margin-right: 15px !important;
}
.export-button:hover {
  color: white !important;
}
.export-button:disabled {
  color: rgba(255, 255, 255, 0.704) !important;
}

.farmer-list-header-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.add-order-button {
  border: 1px solid black !important;
  border-radius: 50%;
}
.product-remove-icon {
  color: red;
}
.number-input {
  width: 100% !important;
}
.farmer-details-btn-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.farmer-order-input .ant-input-number-input {
  text-align: end !important;
}
.farmer-order-confirmation-product {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 10px;
}

.farmer-order-confirmation-product > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: contain;
}
.farmer-order-confirmation-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.farmer-order-confirmation-label-container > b {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.farmer-order-confirmation-label-container > div {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.farmer-order-confirmation-label-container
  > .farmer-order-confirmation-product {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.billing-quantity-container,
.wastage-quantity-container,
.received-quantity-container {
  text-align: center;
}

.attachment-tag {
  color: cornflowerblue !important;
  cursor: pointer;
}
.attachment-tag:hover {
  text-decoration: underline;
}
.attachment-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.wastage_image_list_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.wastage_image_list_container > b {
  cursor: pointer;
  color: #6bb3f8;
}
.wastage_image_list_container > b:hover {
  text-decoration: underline;
}
.supply_demand_comparison_cell {
  padding: 0px !important;
}
.supply_demand_comparison_cell > div {
  height: 50px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  padding-right: 7px !important;
  align-items: center;
}
.supply_demand_comparison_cell > div > span {
  font-weight: 600;
}

.farmer-info-label-containers {
  margin-bottom: 10px;
  width: 200px;
}

.farmer-info-label-name {
  font-weight: bold;
  margin-right: 5px;
}
.card-info-extra-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delivery-info-button {
  cursor: pointer;
}
.delivery-info-container {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-content: center;
}
.delivery-info-card {
  width: 400px;
}
.farmer-order-attachment-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.farmer-order-details-main-container {
  padding: 24px;
  background: white;
  min-height: 360px;
}
.farmer-order-details-information-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.farmer-order-details-status {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 22px;
}
.farmer-order-details-rating {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 22px;
  margin-top: 10px;
}

.warehouse-transfer-icon-main-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  padding-left: 30px;
  padding-bottom: 4px;
  margin-top: 20px;
}
.warehouse-transfer-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-self: center;
  gap: 10px;
}

.warehouse-transfer-footer {
  width: 100%;
  background-color: whitesmoke;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  padding-right: 20px;
  gap: 10px;
}
.available-stock-icon {
  color: Brown;
  font-size: 23px;
}
.placed-stock-icon {
  color: green;
  font-size: 25px;
}
.transfer-icon {
  color: Brown;
  font-size: 25px;
}
.received-icon {
  color: #008080;
  font-size: 25px;
}
.farmer-order-paid-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.farmer_order_name_container {
  color: #4096ff;
  cursor: pointer;
}
.farmer-order-rating-container {
  padding: 20px;
}
.farmer-order-rating-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.farmer-order-rating,
.farmer-order-rating-remarks > h4 {
  margin-bottom: 8px;
}

.farmer-order-rating-remarks > ul > li {
  margin-top: 10px;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.65);
}
.farmer-order-rating-remarks > ul {
  border: 1px solid gray;
  border-radius: 20px;
  min-height: 300px;
}
.mark-as-read-button{
  background-color: #8e5307 !important;
  color: white;
  border: 1px solid #8e5307 !important;
  width: auto !important;
}
