.coupon_form_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.coupon_form_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
