@media (min-width: 2000px) and (max-width: 2559px) {
  .heading {
    font-size: 38px;
    margin-bottom: 20px;
  }
  
  .description {
    font-size: 24px;
    margin-bottom: 26px;
  }
  
  .read-button {
    font-size: 20px;
    padding: 16px 32px;
  }
}
