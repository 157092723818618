@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .share_desktop {
    display: block !important;
  }
  .share_mobile {
    display: none !important;
  }
  .social_icons {
    margin-top: 20px;
    position: absolute;
    display: flex;
    gap: 10px;
    top: 10px;
    right: 40px;
  }
  .heart_icon,
  .share_icon,
  .whatsapp_icon,
  .instagram_icon,
  .facebook_icon {
    font-size: 30px !important;
  }
  .wishlist_button,
  .share_desktop {
    width: 60px !important;
    height: 60px !important;
  }

  .category_text {
    font-size: clamp(1.4rem, 2.5vw, 1.8rem);
  }
  .title_text {
    font-size: clamp(2.6rem, 7vw, 3rem);
  }
  .product_details_related_product_heading {
    font-size: clamp(2.5rem, 7vw, 2.9rem);
  }
  .product_details_related_products_one {
    color: black;
  }
  .product_details_related_products_two {
    color: black;
  }
  .description_text {
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
  }
  .price_text {
    font-size: clamp(1.9rem, 2.5vw, 2.3rem);
  }
  .delivery_text {
    font-size: clamp(1.3rem, 2.5vw, 1.8rem);
  }

  .delivery_date_text {
    display: block;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
  }

  .quantity_label {
    font-size: clamp(1.3rem, 2.5vw, 1.8rem);
  }
  .stock_text {
    font-size: 1.3rem;
  }

  .ant-radio-button-wrapper-checked span {
    font-size: clamp(1.2rem, 2.5vw, 1.6rem);
    padding: 0;
  }

  .ant-radio-button-wrapper span {
    font-size: clamp(0.7rem, 2.5vw, 1rem);
    padding: 0;
  }
  .ant-radio-button-wrapper {
    width: clamp(200px, 100%, 250px);
    margin: 5px 0 0 0;
    text-align: center;
    padding: 0 !important;
  }
  .add_to_cart_button {
    width: 300px !important;
    height: 50px !important;
    font-size: 1.5rem;
  }
  .cart_count {
    padding: 0;
    width: 40px !important;
    text-align: center !important;
    font-size: 1.4rem;
  }
  .product_detail_count_button {
    font-size: 1.6rem;
    text-align: center !important;
    width: 35px !important;
    height: 50px !important;
  }
  .quantity_input {
    width: clamp(65px, 100%, 130px) !important;
    height: 50px !important ;
    justify-content: center;
  }
  .radio_button_wrapper {
    width: 150px;
    height: 50px;
  }
  .ant-radio-button-wrapper {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .ant-radio-button-wrapper-checked span {
    font-size: 1.4rem;
  }
  .ant-radio-button-wrapper span {
    font-size: 1.4rem;
  }
  .product_detail_cart_icon {
    font-size: 1.8rem;
  }
  .radio_button_wrapper .value_button {
    display: flex;
    justify-content: center;
    width: 130px !important;
    margin: 5px 10px 0 0;
  }
  .custom_radio_group {
    height: 100px;
  }
  .radio_group {
    height: 80px;
  }
  .sticky_column {
    position: sticky;
    top: 120px;
    z-index: 1; /* Ensure it stays above other elements if needed */
    align-self: flex-start; /* Helps maintain its position in a flex container */
  }
  .hide_screen_desktop {
    display: none !important;
  }
  .thumbnail_container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-left: -10px !important;
    margin-top: 10px;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .thumbnail_wrapper {
    display: flex;
    align-items: center;
    width: 70px;
    height: 70px;
    justify-content: center;
    cursor: pointer;
  }

  .thumbnail_wrapper > .thumbnail_image {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border: 1px solid gray;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    padding: 5px;
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
  .product_image {
    object-fit: contain;
    width: 30vw;
    min-height: 30vh;
    max-height: 30vh;
    background-color: #ebfce9;
    border-radius: 25px;
    position: sticky;
    top: 10px;
    z-index: 1;
  }
}
