.cluster-main-container {
  width: 100%;
  padding: 10px;
}

.main-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.driver-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 70%;
  height: 90vh;
  overflow-x: scroll;
}

.driver-item {
  position: relative;
}

.vehicle-container {
  position: relative;
  margin-top: 80px;
}

.truck-image {
  width: 700px;
}

.vehicle-number {
  position: absolute;
  left: 230px;
  top: 130px;
  font-size: 14px;
  font-weight: bold;
  color: black;
  background: white;
  padding: 2px 5px;
  border-radius: 3px;
  /* transform: rotate(30deg); */
}
.driver-name {
  /* position: relative;
  top: -30px;
  left: 20px; */
  position: relative;
  top: -110px;
  left: 225px;
  background: white;
  color: black;
  padding: 5px;
  width: max-content;
  border-radius: 5px;
}

.order-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}

/* .vehicle-back {
  position: absolute;
  top: -170px;
  left: 200px;
  width: 90px;
  height: 60px;
  position: relative;
}
.order-box {
  /* width: 40px; */
/* height: 80px;
} */

.order-box-behind {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 100000;
}
.vehicle-back {
  position: absolute;
  top: -315px;
  width: 90px;
  height: 200px;
  margin: 0 auto;
}

.order-box {
  width: 50px;
  height: 50px;
}

.order-box-behind {
  width: 40px;
  height: 40px;
  z-index: 1;
  position: absolute;
}

.zone-list {
  width: 30%;
}
.zone-text-container {
  width: auto;
  text-align: center;
  background-color: green;
  padding: 10px;
}

.driver-text-container {
  width: 100%;
  text-align: center;
  background-color: green;
  padding: 10px;
  position: sticky;
  top: 4px;
  z-index: 10;
}

.driver-text-container,
.zone-text-container > h3 {
  color: white;
}
.zone-order-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.order-assigned-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-letter {
  position: absolute;
  top: 15px;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  z-index: 1000000;
  cursor: pointer;
}
.zone-order-box {
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
}

.order-remove-icon {
  z-index: 10000000;
  width: 20px !important;
  height: 20px !important;
  color: green;
  margin-left: 20px;
}
.assigned-customer-order-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cluster-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;
  padding-top: 10px;
  margin-bottom: 30px;
}
