@media (min-width: 768px) and (max-width: 1023px) {
  .text-overlay {
    width: 50%;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .read-button {
    font-size: 15px;
    padding: 10px 20px;
  }
}